// @ts-nocheck
import WalletConnect from '../Header'
import classNames from 'classnames';
import { useReactive, useMount, useUpdateEffect } from 'ahooks'
import { useHistory } from 'react-router-dom'
import H5Menu from './menu'

export default (props) => {
  const history = useHistory()
  const state = useReactive({
    lang: localStorage._lang,
    showH5Menu: false,
  })

  useMount(() => { toggleFont() })
  useUpdateEffect(() => { toggleFont() }, [state.lang])
  const toggleFont = () => {
    if (!window.hasEnterHomepage) {
      document.body.className = 'fontPoppins'
    } else {
      document.body.className = state.lang === 'ja' ? 'fontMIncho' : 'fontPoppins'
    }
  }

  return (
    <div className="header">
      {state.showH5Menu && <H5Menu
        lang={localStorage._lang}
        onClose={() => state.showH5Menu = false}
        onUpdateLang={(lang) => props.onUpdate(localStorage._lang = state.lang = lang)}
      />}
      <div className="common-container common-container-full" style={{ maxWidth: '100%' }}>
        <div className="container d-flex align-items-center justify-content-between">
          <a href="#/">
            <img src="/images/logo.png" height="80px" className="logo" />
          </a>
          <div className="d-flex align-items-center justify-content-between">
            {props.from === '_loading' || <div className="_navLink">
              <span onClick={() => history.push('/')}>{window.t({ zh: '首頁', en: 'Toppage', ja: 'トップページ', pt: 'Toppage' })}</span>
              <span onClick={() => history.push('/claim')}>{window.t({ zh: 'Claim', en: 'Claim', ja: 'Claim', pt: 'Claim' })}</span>
              {/* <span onClick={() => alertify.success(window.t({ zh: '即將開放', en: 'Coming Soon', ja: '近日リリース予定' }))}>{window.t({ zh: '展覽館', en: 'Explore', ja: 'アイテム一覧', pt: 'Explorar' })}</span> */}
              <span onClick={() => history.push('/remint')}>{window.t({ zh: 'OG重鑄', en: 'OG-Round Remint', ja: 'OGラウンドレミント', pt: 'OG-Round Remint' })}</span>
              <span className="_menu _menu_x">
                {window.t({ zh: 'DAO', en: 'DAO', ja: 'DAO', pt: 'DAO' })}
                <div>
                  <div onClick={() => history.push('/daovault')}>{window.t({ zh: '金庫', en: 'Treasury', ja: 'ボルート', pt: 'Cofre' })}</div>
                  <div onClick={() => window.open('https://snapshot.org/#/bulldogtalkclub.eth')}>{window.t({ zh: '治理', en: 'Governance', ja: 'ガバナンス', pt: 'Governança' })}</div>
                </div>
              </span>
              <span onClick={() => history.push('/reward')} className="css-1h4sxr7">{window.t({ zh: '獎勵', en: 'Rewards', ja: 'リワード', pt: 'Prêmio' })}</span>
              <span onClick={() => window.open('https://discord.gg/M5HxBYqwjt')}>{window.t({ zh: '論壇', en: 'BBS', ja: '掲示板', pt: 'BBS' })}</span>
              <span className="_menu _menu_x">
                {window.t({ en: 'Bug Bounty', ja: 'バグバウンティ', pt: 'Recompensa pelos bugs' })}
                <div>
                  <div onClick={() => window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))}>{window.t({ en: 'Form', pt: 'Formulário' })}</div>
                  <div onClick={() => window.open('https://discord.com/channels/928915246226214933/965312120919040040')}>Discord</div>
                </div>
              </span>
            </div>}
            {props.from === '_loading' || <WalletConnect />}
            <button className="_menu _pc_menu">
              <img src="/images/menu.png" height="35px" />
              <div>
                <div className={classNames({ actived: state.lang === 'en' })} onClick={() => props.onUpdate(localStorage._lang = state.lang = 'en')}>English</div>
                <div className={classNames({ actived: state.lang === 'zh' })} onClick={() => props.onUpdate(localStorage._lang = state.lang = 'zh')}>繁體中文</div>
                <div className={classNames({ actived: state.lang === 'ja' })} onClick={() => props.onUpdate(localStorage._lang = state.lang = 'ja')}>日本語</div>
                <div className={classNames({ actived: state.lang === 'pt' })} onClick={() => props.onUpdate(localStorage._lang = state.lang = 'pt')}>Português</div>
              </div>
            </button>
            <button className="_menu _h5_menu">
              <img src="/images/menu.png" height="35px" onClick={() => state.showH5Menu = true} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};