// @ts-nocheck
import './index.scss'

export default (props) => {
  return (
    <div className="remintModal">
      <div className="remint-container">
        <div className="close" onClick={props.onClose}></div>
        <div className="title">{window.t({ zh: 'Bulldog OG輪 重铸', en: 'Bulldog OG-Round Remint', ja: 'Bulldog OGラウンド レミント', pt: 'Bulldog Rodada de OG Remint' })}</div>
        <div className="texts">
          <div className="l">
            <div>{window.t({ zh: '您的Bulldog OG:', en: 'Your Bulldog OG:', ja: 'ご保有のBulldog OG：', pt: 'Seu Bulldog OG:' })}</div>
          </div>
          <div className="r">
            <div>{window.t({ zh: '在Remint之後，您將得到', en: 'After Remint, you will get', ja: 'レミントした後、入手でき：', pt: 'Após Remint, você receberá' })}</div>
          </div>
        </div>
        <div className="image">
          <div className="l">
            {props.num > 0 && <span>{props.num}x</span>}
            <img width="140" src={require('../images/11.png').default} alt="" />
            <div>{window.t({ en: 'Old Contract', ja: '元コントラクト', pt: 'Contrato Antigo' })}</div>
          </div>
          <div className="c">
            <img src={require('../images/15.png').default} alt="" />
            <img src={require('../images/15.png').default} alt="" />
            <img src={require('../images/15.png').default} alt="" />
          </div>
          <div className="r">
            <div>
              {props.num > 0 && <span>{props.num}x</span>}
              <img width="80" src={require('../images/12.png').default} alt="" />
              <div style={{ fontSize: 16 }}>
                <div>Bulldog OG</div>
                <div>{window.t({ zh: 'OG輪紀念', en: 'OG-Round Commemorate', ja: 'OGラウンド記念', pt: 'Comemorar da Rodada de OG' })}</div>
              </div>
            </div>
            <div>
              {props.num > 0 && <span>{props.num}x</span>}
              <img width="80" src={require('../images/13.png').default} alt="" />
              <div style={{ fontSize: 16 }}>
                <div>BulldogTalkClub</div>
                <div>{window.t({ zh: 'BulldogTalkClub治理權益證明', en: 'Credential Proof of Bulldog Talk Club Governance Right', ja: 'ブルドッグトーククラブのガバナンス権益証明', pt: 'Prova de credencial do direito de governança no BulldogTalkClub ' })}</div>
              </div>
            </div>
            <div>
              {props.num > 0 && <span>{props.num}x</span>}
              <img width="80" src={require('../images/14.png').default} alt="" />
              <div style={{ fontSize: 16 }}>
                <div>MysteryBulldogHair</div>
                <div>{window.t({ zh: 'Remint獎勵', en: 'Remint rewards.', ja: 'レミントリワード', pt: 'Recompensas de Remint' })}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="butto">
          {(() => {
            if (props.hasIdsLoading) {
              return (
                <button style={{ opacity: .5 }}>Loading...</button>
              )
            }
            if (props.remintLoading) {
              return (
                <button style={{ opacity: .5 }}>Pending...</button>
              )
            }
            if (!props.hasIds && props.bulldogDataSource.length >= 1) {
              return (
                <button style={{ opacity: .5 }}>{window.t({ en: 'Reminted', ja: 'レミント済み' })}</button>
              )
            }
            if (!props.hasIds && props.bulldogDataSource.length == 0) {
              return (
                <button style={{ opacity: .5 }}>{window.t({ en: 'Remint', ja: 'レミント' })}</button>
              )
            }
            if (props.hasIds) {
              return (
                <button style={{ cursor: 'pointer' }} onClick={props.onRemint}>{window.t({ en: 'Remint', ja: 'レミント' })}</button>
              )
            }
          })()}
        </div>
      </div>
    </div>
  )
}