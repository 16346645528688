// @ts-nocheck
import pageConfig from "pages/page.config"

export const n2s = (n = 0, pos = 1) => {
    if (!n) return 0
    return (n < 10 ? '0' + n : '' + n)[pos - 1]
}

export const fnm = (n) => {
    var s = n + ''
    if (s.length === 1) return '000' + s
    if (s.length === 2) return '00' + s
    if (s.length === 3) return '0' + s
    if (s.length === 4) return '' + s
}

export const tts = (num) => {
    var num = (num || 0).toString(), temp = num.length % 3;
    switch (temp) {
        case 1:
            num = '00' + num;
            break;
        case 2:
            num = '0' + num;
            break;
    }
    return num.match(/\d{3}/g).join(',').replace(/^0+/, '') || 0;
}

export const png = (n) => {
    var list = [50, 500, 1010, 2278]
    if (list.includes(n)) return 'gif'
    return 'png'
}

export const getPubIpfsApi = (n) => {
    var sectionMap1 = [2000, 2299]
    var sectionMap2 = [2300, 2999]
    if (n >= sectionMap1[0] && n <= sectionMap1[1]) return pageConfig().IPFS_API_PUB
    if (n >= sectionMap2[0] && n <= sectionMap2[1]) return pageConfig().IPFS_API_PUB
    return pageConfig().IPFS_API_V2
}

export const getPubS3Url = (n) => {
    var sectionMap1 = [2000, 2299]
    var sectionMap2 = [2300, 2999]
    var sectionMap3 = [1900, 1999]
    if (n >= sectionMap1[0] && n <= sectionMap1[1]) return pageConfig().S3_URL_PUB
    if (n >= sectionMap2[0] && n <= sectionMap2[1]) return pageConfig().S3_URL_PUB
    if (n >= sectionMap3[0] && n <= sectionMap3[1]) return pageConfig().S3_URL_V3
    return pageConfig().S3_URL_V2
}