// @ts-nocheck
import './index.scss'
import classNames from 'classnames';
import { useHistory } from 'react-router-dom'

export default (props) => {
  const history = useHistory()
  return (
    <div className="_xs_menu_box">
      <div>
        <img src="/images/logo.png" alt="" />
        <img src="/images/close.png" alt="" onClick={() => props.onClose()} />
      </div>
      <div>
        <div onClick={() => (props.onClose(), history.push('/'))}>
          <span>{window.t({ zh: '首頁', en: 'Toppage', ja: 'トップページ', pt: 'Toppage' })}</span>
        </div>
        {/* <div onClick={() => alertify.success(window.t({ zh: '即將開放', en: 'Coming Soon', ja: '近日リリース予定', pt: 'Explorar' }))}>
          <span>{window.t({ zh: '展覽館', en: 'Explore', ja: 'アイテム一覧' })}</span>
        </div> */}
        <div onClick={() => (props.onClose(), history.push('/claim'))}>
          <span>{window.t({ zh: 'Claim', en: 'Claim', ja: 'Claim', pt: 'Claim' })}</span>
        </div>
        <div onClick={() => (props.onClose(), history.push('/remint'))}>
          <span>{window.t({ zh: 'OG重鑄', en: 'OG-Round Remint', ja: 'OGラウンドレミント', pt: 'OG-Round Remint' })}</span>
        </div>
        <div onClick={() => (props.onClose(), history.push('/daovault'))}>
          <span>{window.t({ zh: '金庫', en: 'Treasury', ja: 'ボルート', pt: 'Cofre' })}</span>
        </div>
        <div onClick={() => (props.onClose(), window.open('https://snapshot.org/#/bulldogtalkclub.eth'))}>
          <span>{window.t({ zh: '治理', en: 'Governance', ja: 'ガバナンス', pt: 'Governança' })}</span>
        </div>
        <div onClick={() => (props.onClose(), history.push('/reward'))}>
          <span>{window.t({ zh: '獎勵', en: 'Rewards', ja: 'リワード', pt: 'Prêmio' })}</span>
        </div>
        <div onClick={() => (props.onClose(), window.open('https://discord.gg/M5HxBYqwjt'))}>
          <span>{window.t({ zh: '論壇', en: 'BBS', ja: '掲示板', pt: 'BBS' })}</span>
        </div>
      </div>
      <div>
        <span className={classNames({ _actived: props.lang === 'en' })} onClick={() => (props.onClose(), props.onUpdateLang('en'))}>English</span>
        <span className={classNames({ _actived: props.lang === 'zh' })} onClick={() => (props.onClose(), props.onUpdateLang('zh'))}>繁体中文</span>
        <span className={classNames({ _actived: props.lang === 'ja' })} onClick={() => (props.onClose(), props.onUpdateLang('ja'))}>日本语</span>
        <span className={classNames({ _actived: props.lang === 'pt' })} onClick={() => (props.onClose(), props.onUpdateLang('pt'))}>Português</span>
      </div>
    </div>
  )
}