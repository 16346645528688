// @ts-nocheck
import './App.css';
import Web3ReactManager from 'components/Web3ReactManager'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import TestPage from './pages/testpage/index';
import Homepage from './pages/home/index';
import HomepageV2 from './pages/homeV2/index';
import Daopage from './pages/dao/index';
import RewardPage from './pages/reward/index';
import RemintPage from './pages/remint/index'
import DaovaultPage from './pages/daovault/index';
import RefundingClaimPage from './pages/claim/index';
import { useRequest } from 'ahooks'

export default function App() {
  useRequest(() => (
    fetch('/dog/getVersion')
      .then(resp => resp.json())
      .then(resp => {
        if (localStorage.version == null) localStorage.version = resp.data.version
        if (localStorage.version != resp.data.version) {
          localStorage.version = resp.data.version
          window.location.reload(true)
        }
      })
  ), {
    manual: false,
    pollingInterval: 3000,
    refreshOnWindowFocus: true,
  })

  return (
    <Web3ReactManager>
      <Router>
        <Switch>
          <Route exact path="/">
            <HomepageV2 />
          </Route>
          <Route exact path="/homeV2">
            <HomepageV2 />
          </Route>
          <Route exact path="/claim">
            <RefundingClaimPage />
          </Route>
          <Route exact path="/daovault">
            <DaovaultPage />
          </Route>
          <Route exact path="/reward">
            <RewardPage />
          </Route>
          <Route exact path="/remint">
            <RemintPage />
          </Route>
          <Route exact path="/testpage">
            <TestPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Router>
    </Web3ReactManager>
  )
}