// @ts-nocheck
import { useMount, useReactive } from 'ahooks'
import dayjs from 'dayjs'
export default () => {
  const state = useReactive({
    nftId: '',
    startTime: '',
    new_nftId: '',
    new_startTime: '',
    isBuy: '',
  })

  useMount(() => {
    fetch('/dog/getConfig').then(resp => resp.json()).then(resp => {
      Object.assign(state, resp.data)
      fetch('/dog/getOffers').then(resp => resp.json()).then(resp => {
        var bulldog = resp.data[state.nftId]
        if (bulldog) {
          state.step = bulldog.step
          state.isBuy = !!bulldog.isBuy
        } else {
          state.step = state.nftId + ' 没有数据'
          state.isBuy = state.nftId + ' 没有数据'
        }
      })
    })
  })

  const handleAutoRefresh = () => {
    var bool = window.confirm('是否要刷新页面')
    if (bool) {
      let params = {
        version: Date.now(),
      }
      fetch('/dog/setVersion', { method: 'POST', body: JSON.stringify(params) })
    }
  }

  const handleAutoFull = () => {
    document.querySelector('input[type="text"]').value = state.new_nftId = Number(state.nftId) + 1
    document.querySelector('input[type="date"]').value = state.new_startTime = dayjs().format('YYYY-MM-DD')
  }

  const handleSubmit = () => {
    if (state.new_nftId == '') return alert('new_nftId 为空')
    if (state.new_startTime == '') return alert('new_startTime 为空')

    var bool = window.confirm(`
      nftId: ${+state.new_nftId}
      startTime: ${state.new_startTime} 12:00:00
    `)

    if (bool) {
      let params = {
        nftId: +state.new_nftId,
        startTime: state.new_startTime + ' 12:00:00',
      }
      console.info(params)
      fetch('/dog/saveConfig', {method: 'POST', body: JSON.stringify(params)})
        .then(resp => resp.json())
        .then(resp => {
          window.location.reload()
        })
    } else {
      alert('提交拒绝')
    }
  }

  return (
    <div>
      <div>当前的nftId: {state.nftId}</div>
      <div>当前的startTime: {state.startTime.slice(5)}</div>
      <div>当前的nftId阶段: {state.step}</div>
      <div>当前的nftId是否已卖出: {state.isBuy.toString()}</div>
      <hr />
      <div>
        修改后的狗头: <input type="text" style={{width: 155, color: '#000'}} onChange={e => state.new_nftId = e.target.value} />
      </div>
      <div>
        修改后的日期: <input type="date" style={{width: 155, color: '#000'}} onChange={e => state.new_startTime = e.target.value} /> 12:00:00
      </div>
      <hr />
      <div>
        <button style={{color: '#000'}} onClick={handleAutoFull}>自动填充</button>&nbsp;&nbsp;
        <button style={{color: '#000'}} onClick={handleSubmit}>提交</button>&nbsp;&nbsp;
        <br />
        <br />
        <button style={{color: '#000'}} onClick={handleAutoRefresh}>刷新页面</button>&nbsp;&nbsp;
      </div>
    </div>
  )
}