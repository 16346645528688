// @ts-nocheck
import './index.scss';
import React, { useRef } from 'react'
import Header from '../HeadComp'
import { useReactive, useInterval } from 'ahooks'
import 'antd/es/carousel/style/index.css'
import Carousel from 'antd/es/carousel/index.js';
import { default as useCountDown } from 'pages/home/useCountDown'
import { n2s, fnm, tts, png, getPubIpfsApi, getPubS3Url } from 'pages/home/utils'
const TARGET_DATE = '2022-06-22 12:00:00'

export default React.memo((props) => {
  // const [countdown, setTargetDate, { days: d, hours: h, minutes: m, seconds: s }] = useCountDown({ targetDate: TARGET_DATE })
  // const n2sss = [null, n2s(d, 1), n2s(d, 2), n2s(h, 1), n2s(h, 2), n2s(m, 1), n2s(m, 2), n2s(s, 1), n2s(s, 2)]
  const ref = useRef()
  useInterval(() => ref?.current?.next(), 15000)
  const state = useReactive({
    now: 1,
  })

  return (
    <>
      <Header from="_loading" onUpdate={() => state.now++} />
      <Carousel effect="fade" ref={ref}>
        <div className="_loading _loading-5">
          <div className="_loading-text" style={{color: '#FFF', textShadow: '0px 10px 20px rgba(0, 0, 0, 0.5)'}}>
            Life is like a journey,<br />
            and I am a pedestrian
          </div>
          <div className="_loading-down">
            <div><div>{window.t({ zh: '已结束', en: 'Completed', ja: '終了', pt: 'Finalizado' })}</div></div>
            <div style={{ opacity: 0 }}>Bulldog</div>
          </div>
          <div className="_loading-btns" onClick={() => props.onEnterHomepage()}>
            <img src={require('./images/1.png').default} alt="" />{window.t({ zh: '進入 Bulldog Club', en: 'Enter the Bulldog Club', ja: 'ブルドッグクラブへ', pt: 'Entrar Bulldog Club' })}
          </div>
        </div>
        <div className="_loading _loading-1">
          <div className="_loading-text">
            {window.t({ zh: <>Road <span /> to <span /> APE DAO</>, en: <>Road <span /> to <span /> APE DAO</>, ja: <>Road <span /> to <span /> APE DAO</>, pt: <>Caminho <span /> para <span /> APE DAO</> })}
          </div>
          <div className="_loading-down">
            <div>
              <div>{window.t({ zh: 'Bulldog公售第1輪成功結束!', en: 'Bulldog Public Launch Round 1 is successfully complete!', ja: 'ブルドッグ公開発売ラウンド1が無事に完了しました!', pt: 'A venda pública Rodada 1 de Bulldog termina com sucesso!' })}</div>
              <div className="d-flex-100 align-items-center" style={{ display: 'none' }}>
                <span>{props.n2sss[1]}</span>
                <span>{props.n2sss[2]}</span>
                <span>{window.t({ zh: '天', en: ':', ja: ':' })}</span>
                <span>{props.n2sss[3]}</span>
                <span>{props.n2sss[4]}</span>
                <span>:</span>
                <span>{props.n2sss[5]}</span>
                <span>{props.n2sss[6]}</span>
                <span>:</span>
                <span>{props.n2sss[7]}</span>
                <span>{props.n2sss[8]}</span>
              </div>
            </div>
            <div style={{ opacity: 0 }}>Bulldog</div>
          </div>
          <div className="_loading-btns" onClick={() => props.onEnterHomepage()}>
            <img src={require('./images/1.png').default} alt="" />{window.t({ zh: '進入 Bulldog Club', en: 'Enter the Bulldog Club', ja: 'ブルドッグクラブへ', pt: 'Entrar Bulldog Club' })}
          </div>
        </div>
        <div className="_loading _loading-2">
          <div className="_loading-text">
            {window.t({ zh: <>Otherdeed <span /> #69959 <span /> 已買入</>, en: <>Otherdeed <span /> #69959 <span /> Purchased</>, ja: <>Otherdeed <span /> #69959 <span /> 購入済み</>, pt: <>Otherdeed <span /> #69959 <span /> Comprou</> })}
          </div>
          <div className="_loading-down" style={{ cursor: 'pointer' }} onClick={() => window.open('https://opensea.io/assets/ethereum/0x34d85c9cdeb23fa97cb08333b511ac86e1c4e258/69959')}>
            <div><div>{window.t({ zh: '在 Opensea 上查看→', en: 'Check it on Opensea', ja: 'Openseaで照会', pt: 'Veja em Opensea→' })}</div></div>
            <div style={{ opacity: 0 }}>Bulldog</div>
          </div>
          <div className="_loading-btns" onClick={() => props.onEnterHomepage()}>
            <img src={require('./images/1.png').default} alt="" />{window.t({ zh: '進入 Bulldog Club', en: 'Enter the Bulldog Club', ja: 'ブルドッグクラブへ', pt: 'Entrar Bulldog Club' })}
          </div>
        </div>
        <div className="_loading _loading-3">
          <div className="_loading-text">
            {window.t({ zh: <>BAYC <span /> #6091 <span /> 已買入</>, en: <>BAYC <span /> #6091 <span /> Purchased</>, ja: <>BAYC <span /> #6091 <span /> 購入済み</>, pt: <>BAYC <span /> #6091 <span /> Comprou</> })}
          </div>
          <div className="_loading-down" style={{ cursor: 'pointer' }} onClick={() => window.open('https://opensea.io/assets/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/6091')}>
            <div><div>{window.t({ zh: '在 Opensea 上查看→', en: 'Check it on Opensea', ja: 'Openseaで照会', pt: 'Veja em Opensea→' })}</div></div>
            <div style={{ opacity: 0 }}>Bulldog</div>
          </div>
          <div className="_loading-btns" onClick={() => props.onEnterHomepage()}>
            <img src={require('./images/1.png').default} alt="" />{window.t({ zh: '進入 Bulldog Club', en: 'Enter the Bulldog Club', ja: 'ブルドッグクラブへ', pt: 'Entrar Bulldog Club' })}
          </div>
        </div>
        {/*
        <div className="_loading _loading-4">
          <div className="_loading-text">
            {window.t({ zh: <>The Return <span /> of the <span /> Bulldog</>, en: <>The Return <span /> of the <span /> Bulldog</>, ja: <>The Return <span /> of the <span /> Bulldog</>, pt: <>The Return <span /> of the <span /> Bulldog</> })}
          </div>
          <div className="_loading-down">
            <div>
              <div>{window.t({ zh: '即将到来', en: 'Coming Soon', ja: 'Coming Soon', pt: 'Coming Soon' })}</div>
              <div className="d-flex-100 align-items-center" style={{ display: 'none' }}>
                <span>{props.n2sss[1]}</span>
                <span>{props.n2sss[2]}</span>
                <span>{window.t({ zh: '天', en: ':', ja: ':' })}</span>
                <span>{props.n2sss[3]}</span>
                <span>{props.n2sss[4]}</span>
                <span>:</span>
                <span>{props.n2sss[5]}</span>
                <span>{props.n2sss[6]}</span>
                <span>:</span>
                <span>{props.n2sss[7]}</span>
                <span>{props.n2sss[8]}</span>
              </div>
            </div>
            <div style={{ opacity: 0 }}>Bulldog</div>
          </div>
          <div className="_loading-btns" onClick={() => props.onEnterHomepage()}>
            <img src={require('./images/1.png').default} alt="" />{window.t({ zh: '進入 Bulldog Club', en: 'Enter the Bulldog Club', ja: 'ブルドッグクラブへ', pt: 'Entrar Bulldog Club' })}
          </div>
        </div>
        */}
      </Carousel>
    </>
  );
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
})