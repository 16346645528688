// @ts-nocheck
import React from 'react';
import { Text } from 'rebass'
// import { useMaticBalance } from 'hooks/useMaticBalance'
import { useWalletBalance } from 'hooks/useWalletBalance'
import { SupportedChainId } from 'constants/chains'
import styled from 'styled-components/macro'
import { useActiveWeb3React } from '../../hooks/web3'
import Web3Status from '../Web3Status'
import BigNumber from 'bignumber.js'
import NetworkCard from './NetworkCard'
import { isMobile } from '../../utils/userAgent'
import { useUpdateEffect } from 'ahooks'
import { useDarkModeManager } from 'state/user/hooks'
import useTheme from 'hooks/useTheme'

const HeaderFrame = styled.div`
  color: #000;
  cursor: pointer;
  padding: 2px 20px;
  margin-right: 10px;
  background: #96FAFA;
  background: #03f3f3;
  border-radius: 4px;
`

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
`

const HeaderElement = styled.div`
// border: 1px solid red;
  // display: flex;
  // align-items: center;
  // /* addresses safari's lack of support for "gap" */
  // & > *:not(:first-child) {
  //   margin-left: 10px;
  // }
`

const AccountElement = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export default (function Header() {
  const { account, chainId } = useActiveWeb3React()
  const userBalance = useWalletBalance(account, chainId)
  // const [darkMode] = useDarkModeManager()
  // const { white, black } = useTheme()

  // useUpdateEffect(() => {
  //   if (account) {
  //     return window.location.reload(true);
  //     if (window.__account) {
  //       window.__account = account
  //       window.location.reload(true)
  //     } else {
  //       window.__account = account
  //     }
  //   }
  // }, [account])

  return (
    <HeaderFrame>
      <HeaderControls onClick={() => {
        account ? document.getElementById('web3-status-connected')?.click() : document.getElementById('connect-wallet')?.click();
      }}>
        {/* {isMobile || !account || <NetworkCard />} */}
        <HeaderElement>
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {/* {account && userBalance ? (
              <span>
                {(() => window.__balance = new BigNumber(userBalance).dividedBy(1e18).toFixed(3))()}
                {chainId === SupportedChainId.POLYGON ? 'Matic' : 'ETH'}
              </span>
            ) : <span></span>} */}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
})
