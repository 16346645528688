import { Contract } from '@ethersproject/contracts';
import MembershipCardABI from '../abi/MembershipCard.json'

import ERC20_ABI from '../abi/erc20.json';

import { useMemo } from 'react';
import { getContract } from '../utils';
import { Erc20 } from '../abi/types/Erc20';
import { useActiveWeb3React } from './web3';

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
): T {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined,
      );
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    library,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

// export function useClaimTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
//   return useContract(tokenAddress, ClaimTokenABI, true)
// }

export function useMintMembershipCardContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
) {
  return useContract(tokenAddress, MembershipCardABI, true);
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean,
) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible);
}
