import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components/macro'
import { network } from '../../connectors'
import { useEagerConnect, useInactiveListener } from '../../hooks/web3'
import { NetworkContextName } from '../../constants/index'
import Loader from '../Loader'
import { SignatureLike } from '@ethersproject/bytes'
import { providers, BigNumber } from 'ethers'
import {
  JsonRpcProvider,
  TransactionResponse,
  // Web3Provider,
} from '@ethersproject/providers'



const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`

const Message = styled.h2`
  color: ${({ theme }) => theme.secondary1};
`

export type Web3Data = {
  disconnectWallet: () => void
  currentAccount: string
  connected: boolean
  loading: boolean
  provider: JsonRpcProvider | undefined
  chainId: number
  switchNetwork: (chainId: number) => Promise<void>
  signPersonalData: (unsignedData: string) => Promise<SignatureLike>
  getEncryptionPublicKey: () => Promise<string>
  decryptMessage: (message: string) => Promise<string>
  error: Error | undefined
  switchNetworkError: Error | undefined
  setSwitchNetworkError: (err: Error | undefined) => void
  showSignModal: boolean
  setShowSignModal: (s: boolean) => void
  showTopupModal: boolean
  setShowTopupModal: (s: boolean) => void
  setWalletAddress: (w: string) => void
  showWarning: boolean
  setShowWarning: (_a: boolean) => void
}

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
  // const { active, account } = useWeb3React()
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName)
  const { account, chainId, library: provider, activate, active, error, deactivate, setError } = useWeb3React<providers.Web3Provider>()

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network)
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // handle delayed loader state
  // const [showLoader, setShowLoader] = useState(false)
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowLoader(true)
  //   }, 600)

  //   return () => {
  //     clearTimeout(timeout)
  //   }
  // }, [])

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null
  }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  // if (!active && networkError) {
  //   return (
  //     <MessageWrapper>
  //       <Message>
  //         Oops! An unknown error occurred. Please refresh the page, or visit from another browser or device.
  //       </Message>
  //     </MessageWrapper>
  //   )
  // }

  // // if neither context is active, spin
  // if (!active && !networkActive) {
  //   return showLoader ? (
  //     <MessageWrapper>
  //       <Loader />
  //     </MessageWrapper>
  //   ) : null
  // }

  // const signPersonalData = async (unsignedData: string): Promise<SignatureLike> => {
  //   if (account) {
  //     const signature: SignatureLike = await provider.send('personal_sign', [unsignedData, account])

  //     return signature
  //   }
  //   throw new Error('Error initializing permit signature')
  // }

  return children
}
