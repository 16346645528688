// @ts-nocheck
import dayjs from 'dayjs'
export default (time) => {

  // 竞价时间
  var bidding = {}
  bidding.a = dayjs.utc(time || '1994-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss')
  bidding.b = dayjs.utc(bidding.a).add(24, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 无人竞价，进入publicMint时间
  var notBiddingPublicMint = {}
  notBiddingPublicMint.a = dayjs.utc(bidding.a).add(24, 'hour').format('YYYY-MM-DD HH:mm:ss')
  notBiddingPublicMint.b = dayjs.utc(bidding.a).add(72, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 有人竞价，找出竞价第一高的人选
  var hasBiddingFindPeople1 = {}
  hasBiddingFindPeople1.a = dayjs.utc(bidding.a).add(24, 'hour').format('YYYY-MM-DD HH:mm:ss')
  hasBiddingFindPeople1.b = dayjs.utc(bidding.a).add(36, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 竞价第一高的人选mint时间
  var hasBiddingFindPeople1Mint = {}
  hasBiddingFindPeople1Mint.a = dayjs.utc(bidding.a).add(24, 'hour').format('YYYY-MM-DD HH:mm:ss')
  hasBiddingFindPeople1Mint.b = dayjs.utc(bidding.a).add(36, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 有人竞价，找出竞价第二高的人选
  var hasBiddingFindPeople2 = {}
  hasBiddingFindPeople2.a = dayjs.utc(bidding.a).add(24, 'hour').format('YYYY-MM-DD HH:mm:ss')
  hasBiddingFindPeople2.b = dayjs.utc(bidding.a).add(36, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 找不出竞价第二高的人选，进入publicMint时间
  var notBiddingFindPeople2PublicMint = {}
  notBiddingFindPeople2PublicMint.a = dayjs.utc(bidding.a).add(36, 'hour').format('YYYY-MM-DD HH:mm:ss')
  notBiddingFindPeople2PublicMint.b = dayjs.utc(bidding.a).add(72, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 竞价第二高的人选mint时间
  var hasBiddingFindPeople2Mint = {}
  hasBiddingFindPeople2Mint.a = dayjs.utc(bidding.a).add(36, 'hour').format('YYYY-MM-DD HH:mm:ss')
  hasBiddingFindPeople2Mint.b = dayjs.utc(bidding.a).add(44, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 有人竞价，找出竞价第三高的人选
  var hasBiddingFindPeople3 = {}
  hasBiddingFindPeople3.a = dayjs.utc(bidding.a).add(36, 'hour').format('YYYY-MM-DD HH:mm:ss')
  hasBiddingFindPeople3.b = dayjs.utc(bidding.a).add(44, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 找不出竞价第三高的人选，进入publicMint时间
  var notBiddingFindPeople3PublicMint = {}
  notBiddingFindPeople3PublicMint.a = dayjs.utc(bidding.a).add(44, 'hour').format('YYYY-MM-DD HH:mm:ss')
  notBiddingFindPeople3PublicMint.b = dayjs.utc(bidding.a).add(72, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 竞价第三高的人选mint时间
  var hasBiddingFindPeople3Mint = {}
  hasBiddingFindPeople3Mint.a = dayjs.utc(bidding.a).add(44, 'hour').format('YYYY-MM-DD HH:mm:ss')
  hasBiddingFindPeople3Mint.b = dayjs.utc(bidding.a).add(48, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  // 找不出竞价第四高的人选，进入publicMint时间
  var notBiddingFindPeople4PublicMint = {}
  notBiddingFindPeople4PublicMint.a = dayjs.utc(bidding.a).add(48, 'hour').format('YYYY-MM-DD HH:mm:ss')
  notBiddingFindPeople4PublicMint.b = dayjs.utc(bidding.a).add(72, 'hour').subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss')


  return {
    bidding,
    notBiddingPublicMint,
    hasBiddingFindPeople1,
    hasBiddingFindPeople2,
    hasBiddingFindPeople3,
    hasBiddingFindPeople1Mint,
    hasBiddingFindPeople2Mint,
    hasBiddingFindPeople3Mint,
    notBiddingFindPeople2PublicMint,
    notBiddingFindPeople3PublicMint,
    notBiddingFindPeople4PublicMint,
  }
}





