export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  POLYGON = 137,
}

export const ChainName = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
};

export const EtherscanAPI = {
  [SupportedChainId.MAINNET]: 'api',
  [SupportedChainId.ROPSTEN]: 'api-ropsten',
  [SupportedChainId.RINKEBY]: 'api-rinkeby',
  [SupportedChainId.GOERLI]: 'api-goerli',
  [SupportedChainId.KOVAN]: 'api-kovan',
}

export type chainIds = 1 | 3 | 4 | 5 | 42;

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.POLYGON,
];

interface L1ChainInfo {
  readonly docs: string;
  readonly explorer: string;
  readonly infoLink: string;
  readonly label: string;
}

//L2 chaininfo should have bridge and logoUrl
export interface L2ChainInfo extends L1ChainInfo {
  readonly bridge: string;
  readonly logoUrl?: string;
}

export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
] as const;

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number];

//L2 chain, add new available POLYGON
export const L2_CHAIN_IDS = [SupportedChainId.POLYGON] as const;

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number];

type ChainInfo = { readonly [chainId: number]: L1ChainInfo | L2ChainInfo } & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo;
} & { readonly [chainId in SupportedL1ChainId]: L1ChainInfo };

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.MAINNET]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Mainnet',
  },
  [SupportedChainId.RINKEBY]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://rinkeby.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Rinkeby',
  },
  [SupportedChainId.ROPSTEN]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://ropsten.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Ropsten',
  },
  [SupportedChainId.KOVAN]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://kovan.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Kovan',
  },
  [SupportedChainId.GOERLI]: {
    docs: 'https://docs.uniswap.org/',
    explorer: 'https://goerli.etherscan.io/',
    infoLink: 'https://info.uniswap.org/#/',
    label: 'Görli',
  },
  [SupportedChainId.POLYGON]: {
    bridge: 'https://wallet.polygon.technology/',
    docs: 'https://polygon.technology/',
    explorer: 'https://polygonscan.com/',
    infoLink: 'https://polygon.technology/',
    label: 'Polygon',
  },
};
