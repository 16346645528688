// @ts-nocheck
export default () => {
  return (
    <div className="footer">
      <div className="common-container">
        <div className="container d-flex align-items-center justify-content-between">
          <span style={{ color: '#96FAFA' }}>© 2023 Bulldog Talk Club</span>
          <div className="d-flex align-items-center justify-content-between">
            <a href="https://snapshot.org/#/bulldogtalkclub.eth" target="_blank">
              <img src="/images/snapshot.png" alt="" width={24} />
            </a>
            &nbsp; &nbsp;
            <a href="https://medium.com/@BulldogClub" target="_blank">
              <img src="/images/m.png" alt="" width={24} />
            </a>
            &nbsp; &nbsp;
            <a href="https://twitter.com/BULLDOGTALKCLUB" target="_blank">
              <img src="/images/twitter.png" alt="" width={24} />
            </a>
            &nbsp; &nbsp;
            <a href="https://discord.gg/M5HxBYqwjt" target="_blank">
              <img src="/images/discord.png" alt="" width={24} />
            </a>
            &nbsp; &nbsp;
            <a href="mailto:bulldogtalk@gmail.com" target="_blank">
              <img src="/images/email.png" alt="" width={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
