import React, { useMemo } from 'react'
import { Text, TextProps as TextPropsOriginal } from 'rebass'
import { useAppSelector } from 'state/hooks'
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro'
// import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

type TextProps = Omit<TextPropsOriginal, 'css'>

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

function colors(darkMode: boolean, sellStatus: boolean): Colors {
  const green1 = '#0CC99C'
  const red3 = '#FF3B30'
  const lightGreen = darkMode ? '#142A27' : '#E2F9F3'
  const lightRed = darkMode ? '#2C1E1D' : '#FEE4E6'
  return {
    // base
    white,
    black,
    red3,
    green1,
    sellColor: sellStatus ? red3 : green1,

    // text
    text1: darkMode ? '#FFFFFF' : '#FFFFFF',
    text2: darkMode ? '#FFFFFF' : '#080A19',
    text3: darkMode ? '#151417' : '#FFFFFF', // header router
    text4: darkMode ? '#565656' : '#b9b9b9',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',
    text6: darkMode ? 'rgba(218,218,218,0.71)' : '#565656',
    text7: darkMode ? '#080A19' : '#FFFFFF',
    text8: darkMode ? '#8F96AC' : '#FFFFFF',
    text9: darkMode ? '#C3C5CB' : '#565A69',
    text10: darkMode ? '#6C6F78' : '#777E90',
    text11: darkMode ? '#2F2F2F' : '#CDCDCD', // operation text
    text12: darkMode ? '#4A4A51' : '#B9B9B9',

    lightGreen,
    lightRed,
    lightColor: sellStatus ? lightRed : lightGreen,

    // backgrounds / greys
    bg0: darkMode ? '#151417' : '#1D1C1F', // app bg
    bg1: darkMode ? '#2D2C2F' : '#F8F8F8',
    bg2: darkMode ? '#FFFFFF' : '#080A19', // Action Button
    bg2Disable: darkMode ? '#2F2F2F' : '#CDCDCD', // Action Button disabled
    bg3: darkMode ? '#40444F' : 'rgba(255, 255, 255, 0.18)', // for border
    bg4: darkMode ? '#565A69' : '#888D9B',
    bg5: darkMode ? '#1D1C1F' : '#EBEBEB', // balance
    bg6: darkMode ? '#1D1C1F' : '#F8F8F8', //tr hover  SelectBG

    bguni1: darkMode ? '#212429' : '#F7F8FA',
    bguni2: darkMode ? '#2C2F36' : '#1D1C1F',
    bguni3: darkMode ? '#40444F' : '#1D1C1F',

    bg7: darkMode ? '#FFFFFF' : '#080A19', // position order
    bg8: darkMode ? '#28272B' : '#F2F2F2', // select hover
    bg9: darkMode ? '#1D1C1F' : '#F8F8F8', // position order
    bgCard: darkMode ? '#1D1C1F' : '#FBFBFB', // position & order card
    bg10: darkMode ? '#1D1C1F' : 'linear-gradient(182.76deg, #FBFBFB 2.29%, #FFFFFF 97.7%)',
    bg11: darkMode ? '#2D2C2F' : '#999FAC',
    boder: darkMode ? '#2D2C2F' : '#EBEBEB', // search boder

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',
    yellowBG: darkMode ? '#322E25' : '#FFF6E3',
    //primary colors
    primary1: darkMode ? '#2172E5' : '#E8006F',
    primary2: darkMode ? '#3680E7' : '#FD4040',
    primary3: darkMode ? '#4D8FEA' : '#FD4040',
    primary4: darkMode ? '#376bad70' : '#F6DDE8',
    primary5: darkMode ? '#153d6f70' : '#FDEAF1',

    // color text
    primaryText1: darkMode ? '#438BF0' : '#D50066',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#E8006F',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: darkMode ? '#FF4343' : '#DA2D2B',
    red2: darkMode ? '#FF453A' : '#FF3B30', //orderbook

    yellow1: '#E3A507',
    yellow2: '#FF8F00',
    yellow3: darkMode ? '#BF8600' : '#EBBB4B',
    blue1: darkMode ? '#1B91FF' : '#0080FF', // input small button
    blue2: darkMode ? '#5199FF' : '#007dfa',
    error: darkMode ? '#FD4040' : '#DF1F38',
    success: darkMode ? '#27AE60' : '#007D35',
    warning: '#FF8F00',

    // dont wanna forget these blue yet
    blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

function theme(darkMode: boolean, sellStatus: boolean): DefaultTheme {
  return {
    ...colors(darkMode, sellStatus),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000000' : '#2F80ED',
    shadow2: darkMode ? '0px 36px 60px -15px #000000' : '0px 20px 64px -24px rgba(31, 47, 70, 0.15)',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
    flexCenterBtn: css`
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  // const darkMode = useIsDarkMode()
  // const { sellStatus } = useAppSelector((state) => state.market)

  // const themeObject = useMemo(() => theme(darkMode, sellStatus), [darkMode, sellStatus])
  const themeObject = theme(false, false)

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  btn(props: TextProps) {
    return <TextWrapper fontWeight={700} {...props} />
  },
  primary(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text9'} {...props} />
  },
  label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow3'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg0} !important;
}

a {
 color: ${({ theme }) => theme.blue1}; 
}
`
