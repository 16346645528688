// @ts-nocheck
import pageConfig from 'pages/page.config'
export const nodes = ['https://polygon-rpc.com/']
// const { REACT_APP_PUBLIC } = process.env
// const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

/**
 * Test: Prompt the user to add ROPSTEN as a network on Metamask, or switch to ROPSTEN if the wallet is on a different network 3
 * Test2: Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network 
 * Prod: Prompt the user to add Polygon as a network on Metamask, or switch to Polygon if the wallet is on a different network 137
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    const chainId = parseInt('80001', 10)
    try {
      await (provider as any).request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: 'Polygon',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'matic',
              decimals: 18,
            },
            rpcUrls: nodes,
            blockExplorerUrls: [`https://polygonscan.com/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined")
    return false
  }
}

export async function switchNetwork() {
  const provider = window.ethereum
  if (provider) {
    const chainId = parseInt(pageConfig().REACT_APP_DEFAULT_CHAINID as string, 10) //137 polygon, 4 rinkeby
    try {
      await (provider as any).request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
    }
  } else {
    console.error("Can't setup the Polygon network on metamask because window.ethereum is undefined")
  }
}
