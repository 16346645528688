// @ts-nocheck
import styled from 'styled-components/macro'
import { CheckCircle, AlertTriangle } from 'react-feather'
import { useActiveWeb3React } from '../../hooks/web3'
import { ExternalLink } from '../../theme'
import { useAllTransactions } from '../../state/transactions/hooks'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { RowFixed } from '../Row'
import Loader from '../Loader'

const TransactionWrapper = styled.div``

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink) <{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

export default function Transaction({ hash }: { hash: string }) {
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()
  // console.log('allTransactions', allTransactions)

  const tx = allTransactions?.[hash]
  let summary = tx?.summary
  if (localStorage._lang === 'zh' && summary) summary = '購買會員卡'
  if (localStorage._lang === 'en' && summary) summary = 'Buy BTC Card'
  if (localStorage._lang === 'ja' && summary) summary = ''

  const formatHash = (str: any) => {
    if (!str) return str
    return str.slice(0, 4) + '...' + str.slice(-4)
  }

  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  if (!chainId) return null

  return (
    <TransactionWrapper>
      <TransactionState
        href={getExplorerLink(chainId, hash, ExplorerDataType.TRANSACTION)}
        pending={pending}
        success={success}
      >
        <RowFixed>
          <TransactionStatusText>{summary ?? formatHash(hash)} ↗</TransactionStatusText>
        </RowFixed>
        <IconWrapper pending={pending} success={success}>
          {pending ? <Loader stroke="#007bff" /> : success ? <CheckCircle size="16" /> : <AlertTriangle size="16" />}
        </IconWrapper>
      </TransactionState>
    </TransactionWrapper>
  )
}
