// @ts-nocheck
import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import { useActiveWeb3React } from '../../hooks/web3'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { injected } from '../../connectors'
import Identicon from '../Identicon'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink, LinkStyledButton } from '../../theme'
import { useAppDispatch } from 'state/hooks'
// import { updateConnectWallet } from 'state/user/actions'
import { ChainName, chainIds, EtherscanAPI } from '../../constants/chains';

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  display: flex;
  justify-content: center;
  padding: 30px 0 25px;
  font-weight: 500;
  font-size: 36px;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem 0.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 10px;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  padding: 0rem 40px;
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 18px 40px;
  flex-grow: 1;
  overflow: auto;
  background: #28272B;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    color: #FFFFFF;
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;
  >div {
    display: flex;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text8};
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text8};
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const WalletAction = styled.div`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 14px;
  border: 1px solid #ffffff;
  padding: 4px 12px;
  border-radius: 4px;
  user-select: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  openOptions,
}: AccountDetailsProps) {
  const { chainId, account, connector, deactivate } = useActiveWeb3React()
  const dispatch = useAppDispatch()

  function formatConnectorName() {
    const { ethereum } = window
    // const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const walletName = !!(ethereum && window.isBitKeep) ? 'BitKeep' : 'Metamask'
    // const name = Object.keys(SUPPORTED_WALLETS)
    //   .filter(
    //     (k) =>
    //       SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
    //   )
    //   .map((k) => SUPPORTED_WALLETS[k].name)[0]
    return (
      <WalletName>
        <span>{window.t({ zh: `已連接${walletName}`, en: `Connecting to ${walletName}`, ja: `${walletName}に接続中` })}</span>
      </WalletName>
    )
  }

  function getStatusIcon() {
    if (connector === injected) {
      return (
        <IconWrapper size={16}>
          <Identicon />
        </IconWrapper>
      )
    }
    return null
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }, [dispatch, chainId])

  const getAccountAllTransactions = async () => {
    // when account is not connected, account undefined and chainId undefined
    if (!account || !chainId) return
    const host = `https://${
      EtherscanAPI[chainId as chainIds]
    }.etherscan.io`
    const url = `/api?module=account&action=txlist&address=${account}&page=1&offset=10&sort=desc&apikey=7FPM791NDC9BFFCR3QSVPC5JG3T2BMZ391`
    const response = await fetch(host + url)
    const data = await response.json()
    if (data) {
        console.log('getAccountAllTransactions', data)
        // state.dataSource = data.result || []
    }
  }
  // getAccountAllTransactions()

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <span>{window.t({ zh: '帳戶', en: 'Account', ja: 'アカウント' })}</span>
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                {/* <WalletAction
                  style={{ fontSize: '.825rem', fontWeight: 400 }}
                  onClick={() => {
                    dispatch(updateConnectWallet({ isConnectWallet: false }))
                    toggleWalletModal()
                    deactivate()
                  }}
                >
                  <span>Log Out</span>
                </WalletAction> */}
              </AccountGroupingRow>
              <AccountGroupingRow id="web3-account-identifier-row">
                <AccountControl>
                  <div>
                    {getStatusIcon()}
                    <p> {account && shortenAddress(account)}</p>
                  </div>
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                <AccountControl>
                  <div style={{ marginLeft: -8 }}>
                    {account && (
                      <Copy toCopy={account}>
                        <span style={{ marginLeft: '4px' }}>
                          <span style={{ color: '#96FAFA', fontWeight: 'normal' }}>{window.t({ zh: '復製地址', en: 'Copy Address', ja: 'アドレスをコピー' })}</span>
                        </span>
                      </Copy>
                    )}
                    {chainId && account && (
                      <AddressLink
                        href={getExplorerLink(chainId, account, ExplorerDataType.ADDRESS)}
                      >
                        <LinkIcon size={16} color="#fff" />
                        <span style={{ marginLeft: '4px' }}>
                          <span style={{ color: '#96FAFA', fontWeight: 'normal' }}>{window.t({ zh: '在Etherscan查看', en: 'View on Explorer', ja: 'イーサスキャンで確認' })}</span>
                        </span>
                      </AddressLink>
                    )}
                  </div>
                </AccountControl>
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
      {!!pendingTransactions.length || !!confirmedTransactions.length ? (
        <LowerSection>
          <AutoRow style={{ justifyContent: 'space-between' }}>
            <span>{window.t({ zh: '最近交易', en: 'Recent Transactions', ja: '最近の取引' })}</span>
            <LinkStyledButton onClick={clearAllTransactionsCallback}>
              <span style={{ color: '#f40' }}>{window.t({ zh: '清除全部', en: '(Clear all)', ja: '全部クリア' })}</span>
            </LinkStyledButton>
          </AutoRow>
          <div style={{ maxHeight: 150, paddingRight: 5, overflow: 'auto' }} className="hiddenScroll">
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </div>
        </LowerSection>
      ) : (
        <LowerSection>
          <span>{window.t({ zh: '您的交易將顯示在這裡', en: 'Your transactions will appear here', ja: 'アドレスをコピー' })}...</span>
        </LowerSection>
      )}
    </>
  )
}
