// @ts-nocheck
import 'antd/dist/antd.css';
import "swiper/swiper-bundle.css"
import 'react-loading-skeleton/dist/skeleton.css'
import './index.scss';
import '../home/index.scss';
import { default as useCountDown } from '../home/useCountDown'
import { useMount, useReactive, useUpdateEffect, useInterval  } from 'ahooks'
import React from 'react'
import Header from 'components/HeadComp';
import Footer from 'components/Footer';
import Loading from 'components/Loading'
import LoaderIcon from 'components/Loader';
import OpenBoxAnimate from '../home/components/openBoxAnimate'
import OpenBoxPreview from '../home/components/openBoxPreview'
import { Popover, InputNumber, Calendar, Button, Modal, Tabs } from 'antd';
import { n2s, png, getPubS3Url } from '../home/utils'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useWalletBalance } from 'hooks/useWalletBalance'
import { useWeb3React } from '@web3-react/core';
import { useContract } from 'hooks/useContract';
import Swiper from 'swiper';
import SwiperCore, { Autoplay,Navigation } from 'swiper';
import qs from 'qs'
import dayjs from 'dayjs'
import classNames from 'classnames';
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers';
import { getChainGasprice } from 'utils/genGasOption'
import ABI from 'abi/dog.json'
import ABI_V2 from 'abi/dog2.json'
import PROXY_ABI from 'abi/proxy.json'
import DOGHAIR_ABI from 'abi/dogHair.json'
import getConfig from './getConfig'
import pageConfig from 'pages/page.config';
import swiperConfig from './swiper.config'
import relativeTime from 'dayjs/plugin/relativeTime'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import CountDownComp from './countDown'
import { fnm } from '../home/utils'
const { TabPane } = Tabs;
SwiperCore.use([Autoplay, Navigation]);
dayjs.extend(relativeTime)
let CONFIG = getConfig()
const CONTRACT_ADDRESS_V2 = pageConfig().CONTRACT_ADDRESS_V2.toLowerCase()
const CONTRACT_ADDRESS = pageConfig().CONTRACT_ADDRESS.toLowerCase()
const OPEN_SEA_URL_V2 = pageConfig().OPEN_SEA_URL + CONTRACT_ADDRESS_V2 + '/'
const OPEN_SEA_URL = pageConfig().OPEN_SEA_URL + CONTRACT_ADDRESS + '/'
const S3_URL_V3 = pageConfig().S3_URL_V3
const S3_URL_V2 = pageConfig().S3_URL_V2
const S3_URL = pageConfig().S3_URL
const DOGHAIR_CONTRACT_ADDRESS = pageConfig().DOGHAIR_CONTRACT_ADDRESS.toLowerCase()
const PROXY_CONTRACT_ADDRESS = pageConfig().PROXY_CONTRACT_ADDRESS.toLowerCase()


var TIME          // 临时变量：阶段计时
var STEP          // 临时变量：阶段步骤
var COUNT=1       // 临时变量：轮询计数
var POLLING=true  // 临时变量：轮询开启
export default () => {
  React.useEffect(() => {
    return () => {
      TIME    = null
      STEP    = null
      COUNT   = 1
      POLLING = true
    }
  }, [])
  const [countdown, setTargetDate, { days: d, hours: h, minutes: m, seconds: s }] = useCountDown({ targetDate: '1994-01-01 00:00:00' })
  const n2sss = [null, n2s(d, 1), n2s(d, 2), n2s(h, 1), n2s(h, 2), n2s(m, 1), n2s(m, 2), n2s(s, 1), n2s(s, 2)]
  const admin = window.location.href.includes('admin')
  const state = useReactive({
    now: 1,
    hasEnterHomepage: false,
    hasOpenBoxAnimate: false,
    hasOpenBoxPreview: false,

    buyCount: 1,
    buyCountLimit: 10000,
    buyLoading: false,
    bulldogPrice: pageConfig().PRICE,
    dogHairCount: 0,
    bulldogOGCount: 0,
    bulldogPubCount: 0,
    bulldogDataSource: [],
    bulldogDataSourceTab: 'Pub',
    bulldogDataSourceLoading: false,

    nftId: null,
    startTime: null,
    offers: [],
    banners: [],
    guideStep: 1,
    guideShow: false,
    isOk: false,
    isApprove: false,
    currModalTabKey: '1'
  })


  // 合约实例
  sessionStorage.state = JSON.stringify(state)
  const { account, chainId } = useWeb3React()
  const userContract = useContract(CONTRACT_ADDRESS, ABI)
  const userContractV2 = useContract(CONTRACT_ADDRESS_V2, ABI_V2)
  const proxyContract = useContract(PROXY_CONTRACT_ADDRESS, PROXY_ABI)
  const doghairContract = useContract(DOGHAIR_CONTRACT_ADDRESS, DOGHAIR_ABI)
  const addTransaction = useTransactionAdder()


  // 加载数据
  useMount(() => { loadInitDataSource('load') })
  useUpdateEffect(() => { loadInitDataSource('reload') }, [userContract, userContractV2, doghairContract, account])
  useUpdateEffect(() => { loadInitDataSource('toggle') }, [state.bulldogDataSourceTab])
  const loadInitDataSource = (type) => {
    if (type === 'load' || type === 'reload') state.isApprove = false
    if (type === 'load' || type === 'reload') state.dogHairCount = 0
    if (type === 'load' || type === 'reload') state.bulldogOGCount = 0
    if (type === 'load' || type === 'reload') state.bulldogPubCount = 0
    state.bulldogDataSource = []
    state.bulldogDataSourceLoading = !!account

    if (doghairContract && account) {
      doghairContract.balanceOf(account, 0).then(resp => {
        if (resp.toNumber()) {
          state.dogHairCount = resp.toNumber()
        }
      })
      doghairContract.isApprovedForAll(account, PROXY_CONTRACT_ADDRESS).then(bool => {
        console.log(account, 'isApprove = ' + bool)
        state.isApprove = bool
      })
    }

    if (userContract && userContractV2 && account) {
      fetch('/dog/getRecord')
        .then(resp => resp.json())
        .then(resp => {
          var s3_url
          var open_sea_url
          var asset_contract_address
          var openBoxList = resp.nftIds || []
          var fetchMyBulldog = function (cursor = '') {
            let url = `${pageConfig().OPEN_SEA_API}/assets?`
            let queryString = qs.stringify({
              asset_contract_address: asset_contract_address,
              owner: account.toLowerCase(),
              limit: 50,
              cursor,
            })

            fetch(url + queryString, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'X-API-KEY': pageConfig().OPEN_SEA_API_KEY,
              },
            })
              .then(resp => resp.json())
              .then(resp => {
                resp.assets?.forEach(item => {
                  s3_url = s3_url
                  if (state.bulldogDataSourceTab === 'Pub') s3_url = getPubS3Url(item.token_id)
                  state.bulldogDataSource.push({ nftId: +item.token_id, isTemp: null, image: `${s3_url + item.token_id}.${png(+item.token_id)}?${asset_contract_address}`, openSeaLink: open_sea_url + item.token_id })
                })
                if (resp.next) {
                  fetchMyBulldog(resp.next)
                } else {
                  state.bulldogDataSourceLoading = false
                }
              })
              .catch(() => {
                //
              })
          }

          userContract.balanceOf(account).then(resp => {
            if (resp.toNumber()) {
              state.bulldogOGCount = resp.toNumber()
              if (state.bulldogDataSourceTab === 'OG') {
                s3_url = S3_URL
                open_sea_url = OPEN_SEA_URL
                asset_contract_address = CONTRACT_ADDRESS
                fetchMyBulldog()
              }
            } else {
              if (state.bulldogDataSourceTab === 'OG') {
                state.bulldogDataSourceLoading = false
              }
            }
          })

          userContractV2.balanceOf(account).then(resp => {
            if (resp.toNumber()) {
              state.bulldogPubCount = resp.toNumber()
              if (state.bulldogDataSourceTab === 'Pub') {
                s3_url = S3_URL_V2
                open_sea_url = OPEN_SEA_URL_V2
                asset_contract_address = CONTRACT_ADDRESS_V2
                fetchMyBulldog()
              }
            } else {
              if (state.bulldogDataSourceTab === 'Pub') {
                state.bulldogDataSourceLoading = false
              }
            }
          })
        })
        .catch(() => {
          alertify.error('Failed: /dog/getRecord')
        })
    }
  }


  // 事件监听: swiper
  useMount(() => {
    window.swiper2 = new Swiper('.my-storage-swiper-container', swiperConfig.swiper2)
    window.swiper1 = new Swiper('.top-img-swiper-container', {
      ...swiperConfig.swiper1,
      slidesOffsetBefore: 0,
      on: {
        slideChangeTransitionStart() {
          console.log('当前展示卡片 == ' + this.activeIndex)
          console.log('当前展示卡片真实索引 == ' + this.realIndex)
          var item = {...state.banners[this.realIndex]}
          TIME    = null
          STEP    = item.step
          COUNT   = 1
          POLLING = item.nftId == state.nftId
          state.offers = item.offers || []
        },
      }
    })
  })


  // 事件监听: guide
  useMount(() => { guideFunc() })
  useUpdateEffect(() => { guideFunc() }, [state.hasEnterHomepage])
  const guideFunc = () => {
    if (state.hasEnterHomepage) {
      if (!localStorage.guideShow) {
        setTimeout(() => {
          // state.guideShow = true
          // localStorage.guideShow = true
        }, 1500)
      }
    }
  }


  // 事件节流: request opensea
  const FIX_CLICK = (func) => () => {
    if (state.bulldogDataSourceLoading) return
    return func()
  }


  // 跳转页面：IPFS (该功能暂时未开放，等产品需求)
  const goToIpfs = () => {
    alertify.success(window.t({ zh: '即將開放', en: 'Coming Soon', ja: '近日リリース予定' }))
  }


  // 轮询查询：所有拍卖流浪狗的轮播图 | 当前拍卖流浪狗的竞价表
  useMount(() => { getOffers('load') })
  useInterval(() => { getOffers('loop') }, 3000)
  const getOffers = (type) => {
    if (!POLLING) return
    fetch('/dog/getConfig?' + type + '=' + COUNT)
      .then(resp => resp.json())
      .then(resp => {
        if (!POLLING) return
        if (type === 'load') Object.assign(localStorage, resp.data)
        if (type === 'load') autoShowBulldog()
        CONFIG = getConfig(resp.data.startTime)
        Object.assign(state, resp.data)

        if (type === 'loop') {
          if (localStorage.nftId != state.nftId) {
            Object.assign(localStorage, resp.data)
            autoShowBulldog()
          }
        }

        fetch('/dog/getOffers?' + type + '=' + COUNT)
          .then(resp => resp.json())
          .then(resp => {
            if (!POLLING) return
            COUNT++
            STEP = resp.data[state.nftId]?.step
            state.isOk = true
            state.offers = resp.data[state.nftId]?.offers || []
            state.banners = Object.values(resp.data || {}).sort((a, b) => a.nftId - b.nftId)
          })
      })
  }


  // 轮询查询: 当前处理非轮询的情况下 | 判断是否到了下一只拍卖的时间
  useInterval(() => {
    if (!POLLING) {
      fetch('/dog/getConfig?next')
        .then(resp => resp.json())
        .then(resp => {
          if (resp.data.nftId != state.nftId) {
            POLLING = true
            getOffers('loop')
          }
        })
    }
  }, 2500)


  // 狗毛竞拍
  const maskOffer = () => {
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
    } else {
      if (state.buyCount > state.dogHairCount) return alertify.error(window.t({ zh: 'Invalid Mystery Bulldog Hairs balance.', en: 'Invalid Mystery Bulldog Hairs balance.', ja: 'Invalid Mystery Bulldog Hairs balance.', pt: 'Mystery Bulldog Hairs Insuficientes' }))
      state.buyLoading = true
      fetch('/dog/getOffers?maskOffer')
        .then(resp => resp.json())
        .then(resp => {
          if (resp.result) {
            state.offers = resp.data[state.nftId]?.offers || []
            var obj = {}
            var arr = [...state.offers, {
              address: account.toLowerCase(),
              time: dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
              hair: state.buyCount,
            }]

            arr.forEach(item => {
              if (!obj[item.address]) {
                obj[item.address] = item
              } else {
                if (obj[item.address]?.hair < item?.hair) {
                  obj[item.address] = item
                }
              }
            })

            arr = Object.values(obj) || []
            arr = arr.sort((a, b) => b?.hair - a?.hair)
            var publicStartTime
            var publicEndTime
            for (let i = 0; i < arr.length; i++) {
              if (i == 0) {
                arr[i].startTime = CONFIG.hasBiddingFindPeople1Mint.a
                arr[i].endTime = CONFIG.hasBiddingFindPeople1Mint.b
                publicStartTime = CONFIG.hasBiddingFindPeople1Mint.b
                publicEndTime = CONFIG.notBiddingPublicMint.b
              }
              if (i == 1) {
                arr[i].startTime = CONFIG.hasBiddingFindPeople2Mint.a
                arr[i].endTime = CONFIG.hasBiddingFindPeople2Mint.b
                publicStartTime = CONFIG.hasBiddingFindPeople2Mint.b
                publicEndTime = CONFIG.notBiddingPublicMint.b
              }
              if (i == 2) {
                arr[i].startTime = CONFIG.hasBiddingFindPeople3Mint.a
                arr[i].endTime = CONFIG.hasBiddingFindPeople3Mint.b
                publicStartTime = CONFIG.hasBiddingFindPeople3Mint.b
                publicEndTime = CONFIG.notBiddingPublicMint.b
              }
              if (i >= 3) {
                arr[i].startTime = '2000-01-01 00:00:00'
                arr[i].endTime = '2000-01-01 00:00:00'
                publicStartTime = CONFIG.hasBiddingFindPeople3Mint.b
                publicEndTime = CONFIG.notBiddingPublicMint.b
              }
            }

            fetch('/dog/saveOffers?maskOffer', {
              method: 'POST',
              body: JSON.stringify({
                nftId: state.nftId,
                nftImage: S3_URL_V3 + state.nftId + '-2.png',
                step: 1,
                stepStartTime: publicStartTime,
                stepEndTime: publicEndTime,
                offers: arr,
              }),
            })
              .then(resp => resp.json())
              .then(resp => {
                getOffers()
                state.buyLoading = false
                alertify.success(window.t({ zh: 'Offer completed', en: 'Offer completed', ja: 'Offer completed', pt: 'Oferta completa' }))
              })
          }
        })
    }
  }


  // 狗毛Mint
  const mysteryMint = () => {
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
    } else {
      // 验证参与狗毛竞价
      if (!state.offers.length) return alertify.error('Have not participate in Bidding Stage')
      if (!state.offers.filter(item => item.address === account.toLowerCase()).length) return alertify.error('Have not participate in Bidding Stage')
      // 验证可以购买时间
      if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[0].startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(state.offers[0].endTime).valueOf()) {
        if (account.toLowerCase() !== state.offers[0].address) return alertify.error(window.t({ zh: 'Not yet available for Mint.', en: 'Not yet available for Mint.', ja: 'Not yet available for Mint.', pt: 'Não disponível para Mint.' }))
        return next()
      }
      if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[1].startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(state.offers[1].endTime).valueOf()) {
        if (account.toLowerCase() !== state.offers[1].address) return alertify.error(window.t({ zh: 'Not yet available for Mint.', en: 'Not yet available for Mint.', ja: 'Not yet available for Mint.', pt: 'Não disponível para Mint.' }))
        return next()
      }
      if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[2].startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(state.offers[2].endTime).valueOf()) {
        if (account.toLowerCase() !== state.offers[2].address) return alertify.error(window.t({ zh: 'Not yet available for Mint.', en: 'Not yet available for Mint.', ja: 'Not yet available for Mint.', pt: 'Não disponível para Mint.' }))
        return next()
      }

      alertify.error(window.t({ zh: 'Not yet available for Mint.', en: 'Not yet available for Mint.', ja: 'Not yet available for Mint.', pt: 'Não disponível para Mint.' }))
      function next() {
        state.buyLoading = true
        doghairContract.isApprovedForAll(account, PROXY_CONTRACT_ADDRESS).then(isApprove => {
          if (!isApprove) {
            getChainGasprice().then(async gasPrice => {
              const gasLimit = await doghairContract.estimateGas.setApprovalForAll(PROXY_CONTRACT_ADDRESS, true)
              doghairContract.setApprovalForAll(PROXY_CONTRACT_ADDRESS, true, { ...gasPrice, gasLimit }).then(async tx => {
                addTransaction(tx.hash)
                let result = await tx.wait()
                if (result) {
                  state.buyLoading = false
                  if (result.logs.length === 0) return alertify.error('Failed transaction.')
                  alertify.success('Approve Success')
                  state.isApprove = true
                }
              })
            })
          } else {
            fetch(`/dog/getProxy1Sign?adr=${account.toLowerCase()}`)
              .then(resp => resp.json())
              .then(resp => {
                if (!resp.data || !resp.data.message || !resp.data.sign) {
                  state.buyLoading = false
                  alert('Invalid signature, please contact us.')
                  window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))
                  return
                }
                getChainGasprice().then(async gasPrice => {
                  const gasLimit = await proxyContract.estimateGas.proxyMint(resp.data.message, resp.data.sign, { value: ethers.utils.parseUnits(BigNumber(state.bulldogPrice).multipliedBy(1).toString(), 'ether') }).catch(errrCallback)
                  proxyContract.proxyMint(resp.data.message, resp.data.sign, { ...gasPrice, gasLimit, value: ethers.utils.parseUnits(BigNumber(state.bulldogPrice).multipliedBy(1).toString(), 'ether') }).then(doneCallback).catch(failCallback)
                })
              })

            async function doneCallback(tx) {
              addTransaction(tx.hash)
              let result = await tx.wait()
              if (result) {
                state.buyLoading = false
                if (result.logs.length === 0) return alertify.error('Failed transaction.')
                alertify.success('Mint Success')
                if (result.logs) {
                  fetch(`/dog/saveOffers?mint`, {
                    method: 'POST',
                    body: JSON.stringify({
                      nftId: state.nftId,
                      nftImage: S3_URL_V3 + state.nftId + '.png',
                      buyFromIP: window.returnCitySN?.cip,
                      buyFromIPName: window.returnCitySN?.cname,
                      buyFromAccount: account,
                      buyFromTime: dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
                      buyType: 'mystery',
                      isBuy: true,
                      step: 4,
                    }),
                  })
                    .then(resp => resp.json())
                    .then(resp => {
                      state.bulldogPubCount += 1
                      state.dogHairCount -= (state.offers.find(item => item.address == account.toLowerCase())?.hair || 0)
                      getOffers()
                      var s3_url = getPubS3Url(state.nftId)
                      var open_sea_url = OPEN_SEA_URL_V2
                      var asset_contract_address = CONTRACT_ADDRESS_V2
                      state.bulldogDataSourceTab === 'Pub' &&
                      state.bulldogDataSource.unshift({ nftId: state.nftId, isTemp: null, image: `${s3_url + state.nftId}.${png(+state.nftId)}?${asset_contract_address}`, openSeaLink: open_sea_url + state.nftId })
                      localStorage.version = `${localStorage.version}.${state.nftId}-${account}`
                      fetch('/dog/setVersion', { method: 'POST', body: JSON.stringify({ version: localStorage.version }) })
                    })
                }
              }
            }

            async function failCallback() {
              state.buyLoading = false
              alertify.error(window.t({ zh: '用戶拒絕了請求', en: 'User rejected the request', ja: 'リクエストがユーザに拒否されました' }))
              alert('Mint failed, please contact us.')
              window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))
            }

            async function errrCallback() {
              state.buyLoading = false
              alertify.error(window.t({ zh: '用戶拒絕了請求', en: 'User rejected the request', ja: 'リクエストがユーザに拒否されました' }))
              alert('We were not able to estimate gas, please contact us.')
              window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))
            }
          }
        })
      }
    }
  }


  // 公共Mint
  const publicMint = () => {
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
    } else {
      state.buyLoading = true
      doghairContract.isApprovedForAll(account, PROXY_CONTRACT_ADDRESS).then(isApprove => {
        if (!isApprove, !true) {
          getChainGasprice().then(async gasPrice => {
            const gasLimit = await doghairContract.estimateGas.setApprovalForAll(PROXY_CONTRACT_ADDRESS, true)
            doghairContract.setApprovalForAll(PROXY_CONTRACT_ADDRESS, true, { ...gasPrice, gasLimit }).then(async tx => {
              addTransaction(tx.hash)
              let result = await tx.wait()
              if (result) {
                state.buyLoading = false
                if (result.logs.length === 0) return alertify.error('Failed transaction.')
                alertify.success('Approve Success')
              }
            })
          })
        } else {
          fetch(`/dog/getProxy1Sign?adr=0x0000000000000000000000000000000000000000`)
            .then(resp => resp.json())
            .then(resp => {
              if (!resp.data || !resp.data.message || !resp.data.sign) {
                state.buyLoading = false
                alert('Invalid signature, please contact us.')
                window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))
                return
              }
              getChainGasprice().then(async gasPrice => {
                const gasLimit = await proxyContract.estimateGas.proxyMint(resp.data.message, resp.data.sign, { value: ethers.utils.parseUnits(BigNumber(state.bulldogPrice).multipliedBy(1).toString(), 'ether') }).catch(errrCallback)
                proxyContract.proxyMint(resp.data.message, resp.data.sign, { ...gasPrice, gasLimit, value: ethers.utils.parseUnits(BigNumber(state.bulldogPrice).multipliedBy(1).toString(), 'ether') }).then(doneCallback).catch(failCallback)
              })
            })

          async function doneCallback(tx) {
            addTransaction(tx.hash)
            let result = await tx.wait()
            if (result) {
              state.buyLoading = false
              if (result.logs.length === 0) return alertify.error('Failed transaction.')
              alertify.success('Mint Success')
              if (result.logs) {
                fetch(`/dog/saveOffers?mint`, {
                  method: 'POST',
                  body: JSON.stringify({
                    nftId: state.nftId,
                    nftImage: S3_URL_V3 + state.nftId + '.png',
                    buyFromIP: window.returnCitySN?.cip,
                    buyFromIPName: window.returnCitySN?.cname,
                    buyFromAccount: account,
                    buyFromTime: dayjs.utc().format('YYYY-MM-DD HH:mm:ss'),
                    buyType: 'public',
                    isBuy: true,
                    step: 4,
                  }),
                })
                  .then(resp => resp.json())
                  .then(resp => {
                    state.bulldogPubCount += 1
                    state.dogHairCount -= (0)
                    getOffers()
                    var s3_url = getPubS3Url(state.nftId)
                    var open_sea_url = OPEN_SEA_URL_V2
                    var asset_contract_address = CONTRACT_ADDRESS_V2
                    state.bulldogDataSourceTab === 'Pub' &&
                    state.bulldogDataSource.unshift({ nftId: state.nftId, isTemp: null, image: `${s3_url + state.nftId}.${png(+state.nftId)}?${asset_contract_address}`, openSeaLink: open_sea_url + state.nftId })
                    localStorage.version = `${localStorage.version}.${state.nftId}-${account}`
                    fetch('/dog/setVersion', { method: 'POST', body: JSON.stringify({ version: localStorage.version }) })
                  })
              }
            }
          }

          async function failCallback() {
            state.buyLoading = false
            alertify.error(window.t({ zh: '用戶拒絕了請求', en: 'User rejected the request', ja: 'リクエストがユーザに拒否されました' }))
            alert('Mint failed, please contact us.')
            window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))
          }

          async function errrCallback() {
            state.buyLoading = false
            alertify.error(window.t({ zh: '用戶拒絕了請求', en: 'User rejected the request', ja: 'リクエストがユーザに拒否されました' }))
            alert('We were not able to estimate gas, please contact us.')
            window.open(window.t({ en: 'https://forms.gle/5BwMCJ8nvMu9VgHGA', pt: 'https://forms.gle/1hkdFDWX8TXbpEGY9' }))
          }
        }
      })
    }
  }


  // 新手引导：上翻
  const handleGoPrev = () => {
    if (state.guideStep>1) {
      state.currModalTabKey--
    }
  }


  // 新手引导：下翻
  const handleGoNext = () => {
    if (state.currModalTabKey<8) {
      state.currModalTabKey++
    }
  }


  // 新手引导：关闭
  const handleCancel = () => {
    state.guideShow = false;
    state.currModalTabKey = '1';
  }


  // 我的仓库：预览大图
  const previewConfig = {
    bulldogDataSource: state.bulldogDataSource.filter(item => !!item.openSeaLink),
    bulldogInfo: Object.assign({}, state, state.hasOpenBoxPreview),
    onClose: () => state.hasOpenBoxPreview = false,
  }


  // 执行阶段：1开始竞价  2狗毛Mint  3公共Mint  4已经结束
  const stepConfig = {}
  {
    if (state.nftId && state.startTime) {
      if (localStorage.nftId == state.nftId && dayjs.utc().valueOf() >= dayjs.utc(state.startTime).valueOf()) {
        if (!POLLING) {
          var bool = TIME != '1994-01-01 00:00:00'
          if (bool) {
            TIME = '1994-01-01 00:00:00'
            setTimeout(() => document.getElementById('TIME').click(), 1500)
          }
        } else {
          // 判断是否为阶段1
          if (dayjs.utc().valueOf() >= dayjs.utc(CONFIG.bidding.a).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(CONFIG.bidding.b).valueOf()) {
            stepConfig['1'] = true
            var bool = TIME != CONFIG.bidding.b
            if (bool) {
              TIME = CONFIG.bidding.b
              setTimeout(() => document.getElementById('TIME').click(), 1500)
            }
      
            if (bool && admin && state.nftId && !window.lock1) {
              window.lock1 = true
              fetch('/dog/getOffers?step1')
                .then(resp => resp.json())
                .then(resp => {
                  delete window.lock1
                  if (!resp.data[state.nftId]?.step) {
                    fetch('/dog/saveOffers?step1', {
                      method: 'POST',
                      body: JSON.stringify({
                        nftId: state.nftId,
                        nftImage: S3_URL_V3 + state.nftId + '-2.png',
                        step: 1,
                        stepStartTime: CONFIG.bidding.b,
                        stepEndTime: CONFIG.notBiddingPublicMint.b,
                      }),
                    })
                  }
                })
            }
          }
          // 判断是否为阶段3, 4
          else if (state.offers.length == 0) {
            if (dayjs.utc().valueOf() >= dayjs.utc(CONFIG.notBiddingPublicMint.a).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
              stepConfig['3'] = true
              var bool = TIME != CONFIG.notBiddingPublicMint.b
              if (bool) {
                TIME = CONFIG.notBiddingPublicMint.b
                setTimeout(() => document.getElementById('TIME').click(), 1500)
              }
            }
            else if (dayjs.utc().valueOf() > dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
              stepConfig['4'] = true
              var bool = TIME != CONFIG.notBiddingPublicMint.b
              if (bool) {
                TIME = CONFIG.notBiddingPublicMint.b
                setTimeout(() => document.getElementById('TIME').click(), 1500)
              }
      
              if (bool && admin && state.nftId && !window.lock2) {
                window.lock2 = true
                fetch('/dog/getOffers?step34')
                  .then(resp => resp.json())
                  .then(resp => {
                    delete window.lock2
                    if (resp.data[state.nftId]?.step != 4) {
                      fetch('/dog/saveOffers?step34', {
                        method: 'POST',
                        body: JSON.stringify({
                          nftId: state.nftId,
                          nftImage: S3_URL_V3 + state.nftId + '-1.png',
                          isLoss: true,
                          step: 4,
                        }),
                      })
                        .then(resp => resp.json())
                        .then(resp => {
                          autoNextBulldog()
                        })
                    } else {
                      autoNextBulldog()
                    }
                  })
              }
            }
          }
          // 判断是否为阶段2, 3, 4
          else if (state.offers.length >= 1) {
            if (state.offers.length == 1) {
              if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[0].startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(state.offers[0].endTime).valueOf()) {
                stepConfig['2'] = true
                var bool = TIME != state.offers[0].endTime
                if (bool) {
                  TIME = state.offers[0].endTime
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
              }
              else if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[0].endTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
                stepConfig['3'] = true
                var bool = TIME != CONFIG.notBiddingPublicMint.b
                if (bool) {
                  TIME = CONFIG.notBiddingPublicMint.b
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
              }
              else if (dayjs.utc().valueOf() > dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
                stepConfig['4'] = true
                var bool = TIME != CONFIG.notBiddingPublicMint.b
                if (bool) {
                  TIME = CONFIG.notBiddingPublicMint.b
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
      
                if (bool && admin && state.nftId && !window.lock2) {
                  window.lock2 = true
                  fetch('/dog/getOffers?step234')
                    .then(resp => resp.json())
                    .then(resp => {
                      delete window.lock2
                      if (resp.data[state.nftId]?.step != 4) {
                        fetch('/dog/saveOffers?step234', {
                          method: 'POST',
                          body: JSON.stringify({
                            nftId: state.nftId,
                            nftImage: S3_URL_V3 + state.nftId + '-1.png',
                            isLoss: true,
                            step: 4,
                          }),
                        })
                          .then(resp => resp.json())
                          .then(resp => {
                            autoNextBulldog()
                          })
                      } else {
                        autoNextBulldog()
                      }
                    })
                }
              }
            }
            if (state.offers.length == 2) {
              if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[0].startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(state.offers[1].endTime).valueOf()) {
                stepConfig['2'] = true
                var bool = TIME != state.offers[1].endTime
                if (bool) {
                  TIME = state.offers[1].endTime
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
              }
              else if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[1].endTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
                stepConfig['3'] = true
                var bool = TIME != CONFIG.notBiddingPublicMint.b
                if (bool) {
                  TIME = CONFIG.notBiddingPublicMint.b
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
              }
              else if (dayjs.utc().valueOf() > dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
                stepConfig['4'] = true
                var bool = TIME != CONFIG.notBiddingPublicMint.b
                if (bool) {
                  TIME = CONFIG.notBiddingPublicMint.b
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
      
                if (bool && admin && state.nftId && !window.lock2) {
                  window.lock2 = true
                  fetch('/dog/getOffers?step234')
                    .then(resp => resp.json())
                    .then(resp => {
                      delete window.lock2
                      if (resp.data[state.nftId]?.step != 4) {
                        fetch('/dog/saveOffers?step234', {
                          method: 'POST',
                          body: JSON.stringify({
                            nftId: state.nftId,
                            nftImage: S3_URL_V3 + state.nftId + '-1.png',
                            isLoss: true,
                            step: 4,
                          }),
                        })
                          .then(resp => resp.json())
                          .then(resp => {
                            autoNextBulldog()
                          })
                      } else {
                        autoNextBulldog()
                      }
                    })
                }
              }
            }
            if (state.offers.length >= 3) {
              if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[0].startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(state.offers[2].endTime).valueOf()) {
                stepConfig['2'] = true
                var bool = TIME != state.offers[2].endTime
                if (bool) {
                  TIME = state.offers[2].endTime
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
              }
              else if (dayjs.utc().valueOf() >= dayjs.utc(state.offers[2].endTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
                stepConfig['3'] = true
                var bool = TIME != CONFIG.notBiddingPublicMint.b
                if (bool) {
                  TIME = CONFIG.notBiddingPublicMint.b
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
              }
              else if (dayjs.utc().valueOf() > dayjs.utc(CONFIG.notBiddingPublicMint.b).valueOf()) {
                stepConfig['4'] = true
                var bool = TIME != CONFIG.notBiddingPublicMint.b
                if (bool) {
                  TIME = CONFIG.notBiddingPublicMint.b
                  setTimeout(() => document.getElementById('TIME').click(), 1500)
                }
      
                if (bool && admin && state.nftId && !window.lock2) {
                  window.lock2 = true
                  fetch('/dog/getOffers?step234')
                    .then(resp => resp.json())
                    .then(resp => {
                      delete window.lock2
                      if (resp.data[state.nftId]?.step != 4) {
                        fetch('/dog/saveOffers?step234', {
                          method: 'POST',
                          body: JSON.stringify({
                            nftId: state.nftId,
                            nftImage: S3_URL_V3 + state.nftId + '-1.png',
                            isLoss: true,
                            step: 4,
                          }),
                        })
                          .then(resp => resp.json())
                          .then(resp => {
                            autoNextBulldog()
                          })
                      } else {
                        autoNextBulldog()
                      }
                    })
                }
              }
            }
          }
  
          // 判断是否为购买
          if (stepConfig['4'] || STEP == 4) {
            if (state.banners.find(item => item.nftId == state.nftId && item.isBuy == true)) {
              var bool = TIME != CONFIG.hasBiddingFindPeople3Mint.b
              if (bool) {
                TIME = CONFIG.hasBiddingFindPeople3Mint.b
                setTimeout(() => document.getElementById('TIME').click(), 1500)
              }
            }
          }
        }
      }
    }
  }


  // 轮播图片
  const banners = [...state.banners]


  // 自动切换: 拍卖的Bulldog
  const autoNextBulldog = () => {
    // if (state.nftId) {
    //   if (localStorage.nftId == state.nftId) {
    //     fetch('/dog/saveConfig', {
    //       method: 'POST',
    //       body: JSON.stringify({
    //         nftId: state.nftId + 1,
    //         startTime: dayjs.utc(CONFIG.notBiddingPublicMint.b).add(10, 'second').format('YYYY-MM-DD HH:mm:ss')
    //       })
    //     })
    //       .then(resp => resp.json())
    //       .then(resp => {
    //         getOffers('load')
    //       })
    //   } else {
    //     getOffers('load')
    //   }
    // }
  }


  // 自动切换：展示的Bulldog
  const autoShowBulldog = () => {
    if (
      state.nftId &&
      state.banners.length &&
      state.banners.find(item => item.nftId == state.nftId) &&
      window.swiper1?.length
    ) {
      window.swiper1[0]
        ?.slideTo(state.banners.findIndex(item => item.nftId == state.nftId) || 0)
      window.swiper1[1]
        ?.slideTo(state.banners.findIndex(item => item.nftId == state.nftId) || 0)
    } else {
      setTimeout(() => {
        autoShowBulldog()
      }, 1500)
    }
  }


  sessionStorage.STEP = STEP
  sessionStorage.stepConfig = JSON.stringify(stepConfig)
  // 引导-菜单
  const GuideMenu = [
    {current: 1, label: window.t({ zh: '背景 ', en: 'Background', ja: 'Background', pt: 'Fundo' })},
    {current: 2, label: window.t({ zh: '準備工作', en: 'Before Using', ja: 'Before Using', pt: 'Antes de usar' })},
    {current: 3, label: window.t({ zh: '參與競價', en: 'Participate in bidding', ja: 'Participate in bidding', pt: 'Participar da licitação' })},
    {current: 4, label: window.t({ zh: '神秘購買', en: 'Mystery Mint', ja: 'Mystery Mint', pt: 'Mint Misterioso' })},
    {current: 5, label: window.t({ zh: '一般購買', en: 'Public Mint', ja: 'Public Mint', pt: 'Mint Público' })},
    {current: 6, label: window.t({ zh: '拯救行動結束', en: 'Rescue Action Ended', ja: 'Rescue Action Ended', pt: 'Ação de Resgate Termina:' })},
    {current: 7, label: window.t({ zh: '拯救行動失敗', en: 'Rescue Action Failed', ja: 'Rescue Action Failed', pt: 'Plano de Resgate Falha:' })},
    {current: 8, label: window.t({ zh: '查看獲救的bulldog和捐贈金額', en: 'Check the rescued bulldogs and donation amounts:', ja: 'Check the rescued bulldogs and donation amounts:', pt: 'Verifique os Bulldogs resgatados e os valores das doações:' })}
  ]
  // 引导-切换菜单
  const changeModelTab = (key) => {
    // state.guideStep=key*1;
    state.currModalTabKey = key
  }


  // 修复 - 网页被关闭
  useInterval(() => {
    fetch('/dog/getConfig?stepLoop')
      .then(resp => resp.json())
      .then(resp => {
        if (resp.data.nftId == state.nftId) {
          if (state.nftId && state.startTime) {
            var buyType
            if (stepConfig['2']) buyType = 'mystery'
            if (stepConfig['3']) buyType = 'public'
            if (localStorage.nftId == state.nftId && userContractV2 && buyType && dayjs.utc().valueOf() >= dayjs.utc(state.startTime).valueOf()) {
              console.log('网页被关闭', state.nftId, '未卖出')
              userContractV2.ownerOf(state.nftId).then(() => {
                console.log('网页被关闭', state.nftId, '已卖出')
                fetch('/dog/getOffers?stepLoop')
                  .then(resp => resp.json())
                  .then(resp => {
                    if (resp.data[state.nftId]?.step != 4) {
                      fetch(`/dog/saveOffers?stepLoop`, {
                        method: 'POST',
                        body: JSON.stringify({
                          nftId: state.nftId,
                          nftImage: S3_URL_V3 + state.nftId + '.png',
                          buyType: buyType,
                          isAuto: true,
                          isBuy: true,
                          step: 4,
                        })
                      })
                        .then(resp => resp.json())
                        .then(resp => {
                          getOffers()
                        })
                    }
                  })
              })
            }
          }
        }
      })
  }, 30000)


  return (
    <>
    <div id="TIME" onClick={() => setTargetDate(TIME)}></div>
    {!window.hasEnterHomepage && <Loading n2sss={n2sss} countdown={countdown} onEnterHomepage={() => window.hasEnterHomepage = state.hasEnterHomepage = true} />}
    <div className={classNames({'homeV2':true,'hide':!window.hasEnterHomepage})} lang={localStorage._lang}>
      <Header onUpdate={() => state.now++} />
      {state.hasOpenBoxAnimate && <OpenBoxAnimate />}
      {state.hasOpenBoxPreview && <OpenBoxPreview {...previewConfig} />}
      <div className='main'>
        <div className='main-top clear'>
          <p className='m-big-title'>{window.t({ zh: '流浪狗拯救行動', en: 'Bulldog Stray Dogs Rescue Action', ja: 'ブルドッグ野良犬救済アクション', pt: 'Plano de Proteção ao Cachorro da Rua' })}</p>
          <div className='left dog-img-part'>
            <div className="img-bg-block">
              <div className="img-block-inner">
                {/* pc */}
                <div className={classNames({"top-img-swiper-container pc-top-swiper":true,'single':banners.length==1})} style={{width:"520px",height:"260px"}}>      
                    <div className="swiper-wrapper">        
                      {banners.map((item, index) => {
                        return (
                          <div className="swiper-slide" key={index}>
                            <img src={item.nftImage + '?v=' + localStorage.version} alt="" />
                          </div>
                        )
                      })}
                    </div>
                    <div className={classNames({"swiper-button":true,'hide':!banners||banners.length==0})}>
                      <div className="swiper-button-prev">
                        <div className="inner-arr"></div>
                      </div>
                      <div className="swiper-button-next"></div>  
                    </div>     
                </div>
                {/* 移动端 */}
                <div className={classNames({"top-img-swiper-container m-top-swiper":true,'single':banners.length==1})} style={{width:"300px",height:"300px"}}>      
                    <div className="swiper-wrapper">        
                      {banners.map((item, index) => {
                        return (
                          <div className="swiper-slide" key={index}>
                            <img src={item.nftImage} alt="" />
                          </div>
                        )
                      })}
                    </div>
                    <div className={classNames({"swiper-button":true,'hide':!banners||banners.length==0})}>
                      <div className="swiper-button-prev">
                        <div className="inner-arr"></div>
                      </div>
                      <div className="swiper-button-next"></div>  
                    </div>     
                </div>
              </div>
            </div>
            <div className='dog-info clear'>
              <div className='info-item'>
                <p className='info-title'>{window.t({ zh: '已經拯救的Bulldog', en: 'Bulldog Rescued', ja: '救済されたブルドッグ', pt: 'Bulldog Resgatado' })}</p>
                <p className='info-content'>
                  <span className='big-text'>
                  {state.banners.filter(item => item.isBuy).length}
                  </span> /
                  {state.banners.length}
                </p>
              </div>
              <div className='info-item'>
                <p className='info-title title2'>{window.t({ zh: 'BulldogDao 將捐出', en: 'BulldogDAO will donate', ja: 'ブルドッグDAOより、以下の金額が寄付される予定', pt: 'A BulldogDAO fará a doação' })}</p>
                <p className='info-content'>
                  <span className='big-text'>
                    {+(state.banners.filter(item => item.isBuy).length * 0.01).toFixed(3) || 0}
                  </span> ETH
                </p>
              </div>
              <div className='my-storage'>
                <h5 className='title'>{window.t({ zh: '我的倉庫', en: 'My Items', ja: 'マイアイテム', pt: 'Meus itens' })}</h5>
                <div className='storage-tab'>
                  <span className={classNames({ 'tab-item': true, 'active': state.bulldogDataSourceTab === 'Pub' })} onClick={FIX_CLICK(() => state.bulldogDataSourceTab = 'Pub')}>{window.t({ zh: 'Bulldog Talk Club', en: 'Bulldog Talk Club', ja: 'Bulldog Talk Club', pt: 'Bulldog Talk Club' })} ({state.bulldogPubCount})</span>
                  <span className={classNames({ 'tab-item': true, 'active': state.bulldogDataSourceTab === 'OG' })} onClick={FIX_CLICK(() => state.bulldogDataSourceTab = 'OG')}>{window.t({ zh: 'Bulldog OG', en: 'Bulldog OG', ja: 'Bulldog OG', pt: 'Bulldog OG' })} ({state.bulldogOGCount})</span>
                </div>
                <div className={classNames({'my-storage-swiper-parent':true,'no-data':!(state.bulldogDataSource.length && state.bulldogDataSource.length>0)})}>
                  {/* 图片轮播模块 */}
                  <div className={classNames({"my-storage-swiper-container":true,'hide':!(state.bulldogDataSource.length && state.bulldogDataSource.length>0)})} style={{width:"",height:""}}>      
                      <div className="swiper-wrapper">        
                        {state.bulldogDataSource.map((item, index) => {
                          if (item.openSeaLink) {
                            return (
                              <div className="swiper-slide" key={index} onClick={() => state.hasOpenBoxPreview = item}>
                                <img src="/images/888.png" alt="" onLoad={(e) => {
                                  var img
                                  img = new Image()
                                  img.src = item.image
                                  img.onload = () => e.target.src = img.src
                                }} />
                                <p className="img-name">#{fnm(item.nftId)}</p>
                              </div>
                            )
                          } else {
                            return (
                              <div className="swiper-slide" key={index}>
                                <img src="/images/888.png" alt="" />
                                <p className="img-name">#{fnm(item.nftId)}</p>
                              </div>
                            )
                          }
                        })}
                      </div>
                      <div className="swiper-button">
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>  
                      </div>     
                    </div>
                  {
                    !(state.bulldogDataSource.length && state.bulldogDataSource.length>0)&&
                    <div className="_f d-flex align-items-center" style={{width:'100%'}}>
                      {
                        state.bulldogDataSourceLoading ?
                          <SkeletonTheme baseColor="rgba(255, 255, 255, .85)" highlightColor="#666">
                            <Skeleton width={200} count={2} />
                          </SkeletonTheme> :
                          window.t({ zh: '快去購買Bulldog吧...', en: 'Buy a bulldog now!', ja: '早速ブルドッグを買いましょう！', pt: 'Vá e compre Bulldog agora!' })
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='right dog-stray-part' padding={{ paddingTop: 30 }}>
            <p className='title m-hide'>{window.t({ zh: '流浪狗拯救行動', en: 'Bulldog Stray Dogs Rescue Action', ja: 'ブルドッグ野良犬救済アクション', pt: 'Plano de Proteção ao Cachorro da Rua' })}</p>
            <div className='clear'>
              <div className="left mint-cost">
                <p className='small-title'>{window.t({ zh: '鑄造成本', en: 'Mint Cost', ja: 'ミントコスト', pt: 'Custo de Mint' })}</p>
                <div className='mint-cost-parent'>
                  <div className='mint-cost-item'>
                    <p>0.1</p>
                    <p className='desc'>ETH</p>
                  </div>
                  <div className='mint-cost-item'>
                    <p>+</p>
                    <p></p>
                  </div>
                  <div className='mint-cost-item'>
                    <p>X</p>
                    <p className='desc'>{window.t({ zh: 'Mystery Bulldog Hairs', en: 'Mystery Bulldog Hairs', ja: 'ミステリーブルドッグ犬の毛', pt: 'Mystery Bulldog Hairs' })}</p>
                  </div>
                </div>
              </div>
              <div className="right view-guide" onClick={()=>{state.guideShow=true;state.currModalTabKey='1';}}>
                {window.t({ zh: '操作指南', en: 'Guide', ja: 'ガイド', pt: 'Guia' })}
              </div>
              {/* <div className="left calendar-area">
                <Calendar
                  value={moment.utc(state.currDate)}
                  onSelect={dateMoment => {
                    state.currDate = dateMoment.utc().format('YYYY-MM-DD')
                    getOffers()
                    setSwiperImg()
                  }}
                />
              </div> */}
            </div>
            {<div className='count-down-time-area'>
                {window.t({ zh: '階段倒計時：', en: 'Stage Countdown：', ja: 'ステージカウントダウン', pt: 'Contagem regressiva da fase' })}
                <p className='go-new-link'></p>
                <span className='time-num'>{n2sss[1]}</span>
                <span className='time-num'>{n2sss[2]}</span>
                <span className='time-sep1'>{window.t({ zh: '天', en: 'Days', ja: ' : ', pt: 'dia' })}</span>
                <span className='time-num'>{n2sss[3]}</span>
                <span className='time-num'>{n2sss[4]}</span>
                <span className='time-sep2'> : </span>
                <span className='time-num'>{n2sss[5]}</span>
                <span className='time-num'>{n2sss[6]}</span>
                <span className='time-sep2'> : </span>
                <span className='time-num'>{n2sss[7]}</span>
                <span className='time-num'>{n2sss[8]}</span>
            </div>}
            <div className='auction-stage'>
              <p className='small-title'>{window.t({ zh: '拍賣階段', en: 'Auction Stage', ja: 'オークションステージ', pt: 'Etapa do Leilão' })}</p>
              <div className='stage-area'>
                <div className={classNames({'stage-item':true,'active': state.isOk && (stepConfig['1'] && STEP != 4) })}>
                  <Popover content={window.t({ zh: '價高者得, 速速出價', en: 'The highest bidder will win.', ja: 'ミステリーミント権利の奪い', pt: 'O maior licitante ganhará. Faça sua oferta já!'})} title="">
                    <div className='item-inner'>
                      {window.t({ zh: <>競價<br/>階段</>, en: 'Bidding Stage', ja: 'オークション段階', pt: 'Fase da Licitação' })}
                    </div>
                  </Popover>
                </div>
                <div className="seperate clear">
                  <img src={require('./images/stage-seperate-t.png').default} alt="" width={24} className="left"/>
                  <img src={require('./images/stage-seperate-b.png').default} alt="" width={24} className="left"/>
                </div>
                <div className={classNames({'stage-item':true,'active': state.isOk && (stepConfig['2'] && STEP != 4) })}>
                  <Popover content={window.t({ zh: '競價成功, 搶先鑄造', en: 'Winner only, preferred mint', ja: '優勝者だけ、優先ミント', pt: 'Licitação bem sucedida, vá mint primeiro!'})} title="">
                    <div className='item-inner'>
                      {window.t({ zh: <>神秘<br/>購買</>, en: 'Mystery Mint', ja: 'ミステリーミント', pt: 'Mint Misterioso' })}
                    </div>
                  </Popover>
                </div>
                <div className="seperate clear">
                  <img src={require('./images/stage-seperate-t.png').default} alt="" width={24} className="left"/>
                  <img src={require('./images/stage-seperate-b.png').default} alt="" width={24} className="left"/>
                </div>
                <div className={classNames({'stage-item':true,'active': state.isOk && (stepConfig['3'] && STEP != 4 )})}>
                  <Popover content={window.t({ zh: '最後機會, 帶它回家', en: 'Last chance, take this Bulldog home', ja: '最後のチャンス、このブルドッグを持ち帰ろう', pt: 'Última chance de levá-lo para casa' })} title="">
                    <div className='item-inner'>
                      {window.t({ zh: <>一般<br/>購買</>, en: 'Public Mint', ja: '一般ミント', pt: 'Mint Público' })}
                    </div>
                  </Popover>
                </div>
                <div className="seperate clear">
                  <img src={require('./images/stage-seperate-t.png').default} alt="" width={24} className="left"/>
                  <img src={require('./images/stage-seperate-b.png').default} alt="" width={24} className="left"/>
                </div>
                <div className={classNames({'stage-item':true,'active': state.isOk && (stepConfig['4'] || STEP == 4) })}>
                  <Popover content={window.t({ zh: '這只Bulldog的拯救行動已經結束', en: 'This bulldog rescue action is over', ja: 'このブルドッグの救済アクションがすでに終了しました。', pt: 'O resgate deste Bulldog acabou' })} title="">
                    <div className='item-inner'>
                      {window.t({ zh: <>拯救<br/>結束</>, en: 'End of Rescue', ja: '救済アクションの終了', pt: 'Fim de Resgate' })}
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            <div className='make-offer-area clear'>
              <div className='left' style={{ opacity: stepConfig['1'] ? 1 : .75 }}>
                <button onClick={() => state.buyCount--} disabled={state.buyCount <= 1} className="opt-num-btn">-</button>
                <InputNumber
                  maxLength={5}
                  bordered={false}
                  onChange={value => state.buyCount = value}
                  value={stepConfig['1'] ? state.buyCount : 1}
                  step={1}
                  max={state.buyCountLimit}
                  min={1}
                />
                <button onClick={() => state.buyCount++} disabled={state.buyCount >= state.buyCountLimit} className="opt-num-btn">+</button>
                <span>
                  {window.t({
                    zh: <>Mystery<br/>Bulldog Hairs</>,
                    en: <>Mystery<br/>Bulldog Hairs</>,
                    ja: 'ミステリーブルドッグ犬の毛',
                    pt: <>Mystery<br/>Bulldog Hairs</> 
                  })}
                </span>
              </div>
              {(!state.isOk || (!Object.keys(stepConfig).length && STEP != 4)) && (
                <button disabled={true} className="opt-btn right">
                  {window.t({ zh: '出價', en: 'Make Offer', ja: 'オファーする', pt: 'Minha oferta' })} {state.buyLoading && <>&nbsp;<LoaderIcon stroke="#000" /></>}
                </button>
              )}
              {state.isOk && (stepConfig['1'] && STEP != 4) && (
                <button onClick={maskOffer} disabled={state.buyLoading} className="opt-btn right">
                  {window.t({ zh: '出價', en: 'Make Offer', ja: 'オファーする', pt: 'Minha oferta' })} {state.buyLoading && <>&nbsp;<LoaderIcon stroke="#000" /></>}
                </button>
              )}
              {state.isOk && (stepConfig['2'] && STEP != 4) && (
                <button onClick={mysteryMint} disabled={state.buyLoading} className="opt-btn right">
                  {state.isApprove === false ? window.t({ zh: 'Approve', en: 'Approve', ja: 'Approve', pt: 'Aprovar' }) : window.t({ zh: '神秘購買', en: 'Mystery Mint', ja: 'ミステリーミント', pt: <>Mint<br/>Misterioso</> })}
                  {state.buyLoading && <>&nbsp;<LoaderIcon stroke="#000" /></>}
                </button>
              )}
              {state.isOk && (stepConfig['3'] && STEP != 4) && (
                <button onClick={publicMint} disabled={state.buyLoading} className="opt-btn right">
                  {window.t({ zh: '一般購買', en: 'Public Mint', ja: '一般ミント', pt: 'Mint Público' })} {state.buyLoading && <>&nbsp;<LoaderIcon stroke="#000" /></>}
                </button>
              )}
              {state.isOk && (stepConfig['4'] || STEP == 4) && (
                <button disabled={true} className="opt-btn right">
                  {window.t({ zh: '已結束', en: 'Ended', ja: '終了', pt: 'Terminado' })} {state.buyLoading && <>&nbsp;<LoaderIcon stroke="#000" /></>}
                </button>
              )}
            </div>
            <div className='text-tip-area clear'>
              <span className='left'>{window.t({ zh: '錢包余額:', en: 'Wallet Balance:', ja: 'ウォレット残高:', pt: 'Balanço de Carteira:' })} {state.dogHairCount}</span>
              <a href='https://opensea.io/assets/ethereum/0xad804109f0c2ba2ff81343a4d39581a00be0a8d2/0' target='_blank' className='go-reward-link right'>
                {window.t({ zh: 'Mystery Bulldog Hairs不夠了？立刻去購買', en: 'Need more Mystery Bulldog Hairs? Buy it now!', ja: 'ミステリーブルドッグ犬の毛が足りませんか？今すぐ買いましょう！', pt: 'Precisa de mais Mystery Bulldog Hairs? Compre agora!' })}
                <img src={require('./images/go-buy-arrow.png').default} alt="" alt="" style={{'marginLeft':'14px'}}/>
              </a>
              {/* <Popover content={()=>{
                return (<div style={{cursor: 'pointer'}} onClick={() => window.open('/#/reward')}><span className='tip-text'>狗毛不够了？去购买</span>
                <img src={require('./images/go-buy-arrow.png').default} alt="" alt="" /></div>)}} title="">
                <span className='tip-circle-ico'>i</span>
              </Popover> */}
            </div>
            <div className='offer-bidding-phase'>
              <p className='title'>{window.t({ zh: '競價階段訂單', en: 'Offers in Bidding Stage:', ja: 'オークション段階でのオファー', pt: 'Ofertas na Fase de Licitação' })}</p>
              <ul className='list-ul'>
                  <li className='list-item grey-row list-header'>
                    <div>{window.t({ zh: '時間', en: 'Time', ja: '時間', pt: 'Hora' })}</div>
                    <div>{window.t({ zh: '出價人', en: 'From', ja: 'オファーメイカー', pt: 'Licitante' })}</div>
                    <div>{window.t({ zh: '出價 ', en: 'Bid', ja: '価格', pt: 'Licitar' })}<p className='smll-header'>{window.t({ zh: '(Mystery Bulldog Hairs) ', en: '(Mystery Bulldog Hairs)', ja: '（ミステリーブルドッグ犬の毛）', pt: '(Mystery Bulldog Hairs)' })}</p></div>
                    <div>{window.t({ zh: '順位 ', en: 'Rank', ja: '順位', pt: 'Rank' })}</div>
                    <div>{window.t({ zh: '鑄造期限', en: 'Mint Period', ja: 'ミント期間', pt: 'Mint Período' })}<p className='smll-header'>{window.t({ zh: '(UTC) ', en: '(UTC)', ja: '(UTC)', pt: '(UTC)' })}</p></div>
                  </li>
                { 
                  state.offers&&state.offers.length>0?
                  <>
                  {state.offers.slice(0, 3).map((item, index) => {
                    return (
                      <li className={classNames({'list-item list-item-content':true,'current':item.address === account?.toLowerCase()})} key={index}>
                        <div>{dayjs.utc(item.time).fromNow()}</div>
                        <div>{item.address?.slice(0, 5)}...{item.address?.slice(-5)}</div>
                        <div>{item.hair}</div>
                        <div>{index + 1}</div>
                        <div>
                          {(() => {
                            if ((stepConfig['4'] || STEP == 4)) {
                              return (
                                <div>{window.t({ zh: '已結束', en: 'Ended', ja: '終了した', pt: 'Terminado às' })}</div>
                              )
                            }
                            else if (dayjs.utc().valueOf() < dayjs.utc(item.startTime).valueOf()) {
                              return (
                                <>
                                  <div>{window.t({ zh: '將開始於', en: 'Will start at', ja: '開始予定', pt: 'Começa às' })}</div>
                                  <div>{dayjs.utc(item.startTime).format('YYYY-MM-DD')}</div>
                                  <div>{dayjs.utc(item.startTime).format('HH:mm:ss')}</div>
                                </>
                              )
                            }
                            else if (dayjs.utc().valueOf() >= dayjs.utc(item.startTime).valueOf() && dayjs.utc().valueOf() <= dayjs.utc(item.endTime).valueOf()) {
                              return (
                                <CountDownComp targetDate={item.endTime}>
                                  {nnns => {
                                    return (
                                      <>
                                        <div>{window.t({ zh: '倒計時', en: 'Countdown', ja: 'カウントダウン', pt: 'Termina às' })}</div>
                                        <div>{`${nnns[3]}${nnns[4]}:${nnns[5]}${nnns[6]}:${nnns[7]}${nnns[8]}`}</div>
                                      </>
                                    )
                                  }}
                                </CountDownComp>
                              )
                            }
                            else if (dayjs.utc().valueOf() > dayjs.utc(item.endTime).valueOf()) {
                              return (
                                <>
                                  <div>{window.t({ zh: '已結束', en: 'Ended', ja: '終了した', pt: 'Terminado às' })}</div>
                                </>
                              )
                            }
                          })()}
                        </div>
                      </li>
                    )
                  })}
                  {(() => {
                    var selfOffer = state.offers.filter(item => item.address === account?.toLowerCase())
                    if (selfOffer.length) {
                      return <li className='list-item list-item-content grey-row my-offer'>
                        <div>{window.t({ zh: '我的出價', en: 'My Offer', ja: '私のオーダー', pt: 'Minha oferta' })}</div>
                        <div>{}</div>
                        <div>{selfOffer[0].hair}</div>
                        <div>{state.offers.findIndex(item=>item.address === account?.toLowerCase()) + 1}</div>
                      </li>
                    }
                  })()}
                  </>
                  :
                  <div className="_f d-flex align-items-center" style={{width:'100%'}}>
                    {
                      window.t({ zh: '暂无数据', en: 'no data', ja: 'no data', pt: 'no data' })
                    }
                  </div>
                }
              </ul>
            </div>
          </div>
        </div>
        <div className='main-bottom'>
          <div className='dot-img'><img width="208px" src={require('./images/point.png').default} alt="" /></div>
          <h5 className='title'>{window.t({ zh: 'Bulldog Town 施工聲明', en: 'Bulldog Town Construction Announcement', ja: 'ブルドッグタウン建設に関わるお知らせ', pt: 'Anúncio de Construção da Cidade de Bulldog' })}</h5>
          <div className='stray-background-area'>
            <div>
              {window.t({ 
                zh: `針對30只流浪狗的拯救行動已結束，Bulldog將進入升級階段。在下一階段，BulldogDAO將著重進行Bulldog Town的建設。Bulldog Town將成為所有bulldog聚會和交流的專屬場所。隨著建設工作的進行，Bulldog Town將逐步解鎖具有各種獨特功能的建築。比如說: 在下一階段，BulldogDAO將推出持有者們期待已久的拍照功能。持有者可以在小鎮的照相館用Mystery Bulldog Hair換取膠卷，用膠卷給自己的bulldog和BAYC6091拍照，照片將被Minted為NFT，並發送到持有人的地址。`,
                en: `The rescue of 30 stray dogs is over and Bulldog will enter the upgrade phase. In the next phase, Bulldog will carry out the construction of Bulldog Town. Bulldog Town will become an exclusive place for all Bulldogs to meet and communicate. With the construction work, Bulldog Town will gradually unlock buildings with various unique functions. For example: In the next phase, Bulldog will launch the long-awaited photo-taking function. Holders can exchange dog hair for film at the town's photo studio and have their bulldogs photographed with BAYC6091. The photo will be Minted as NFT and sent to the holder's address.`,
                ja: `30つのブルドッグの救済イベントがすでに終わり、BulldogDAOは次の段階を目指して、アップグレード中です。 次の段階で、BulldogDAOは、すべてのブルドッグのミーティング及びコミュニケーションの場となる「ブルドッグタウン」の建設に注力します。ブルドッグタウンでは、建設が進むにつれて、さまざまな機能を持った建物が徐々にアンロックされていきます。 例えば、 Bulldogホルダーが待望していた撮影機能をリリースする予定です。 ホルダーは、ブルドッグタウンの写真館で「Mystery Bulldog Hair」をフィルムと交換し、「BAYC6091」とご保有のブルドッグの写真を撮影してもらい、NFTとしてMintedし、ホルダーのイーサアドレスに送信されるようにします。`,
                pt: `O resgate de 30 cães vadios terminou e o Bulldog entrará na fase de atualização. Na próxima fase, Bulldog realizará a construção da Cidade de Bulldog. A Cidade de Bulldog se tornará um local exclusivo para todos os Bulldogs se encontrarem e se comunicarem. Com o trabalho de construção, a Cidade de Bulldog destravará gradualmente edifícios com várias funções únicas. Por exemplo: Na próxima fase, Bulldog lançará a função de tirar fotos. Os titulares de Bulldog podem trocar o Mystery Bulldog Hair pelo filme no estúdio fotográfico da cidade de Bulldog e ter seus bulldogs fotografados com BAYC6091. A foto será cunhada como NFT e enviada para o endereço do titular.`
              })}
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className='clear other-desc-info'>
          <div className="_ot left" style={{ position: 'relative', paddingTop: 120 }}>
            <div className="common-container">
              <div className="container">
                <div id="_h1" className="row">
                  <div className="col-lg-4 col-12">
                    <div className="img-bgbor">
                      <img className="img-fluid w-100" src={require('./images/1.png').default} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 col-12 offset-lg-1 d-flex">
                    <div>
                      <h2 className="common-title">{window.t({ zh: 'Bulldog 介紹', en: 'About Bulldog', ja: 'ブルドッグについて', pt: 'Sobre Bulldog' })}</h2>
                      <p className="common-p" style={{ marginTop: 48 }}>
                        {window.t({
                          zh: <>
                            Bulldog Club由10,000枚獨特NFT組成，我們是壹個跟隨APE DAO持續發展的Club，會去參與購買Bored Ape Yacht Club的各種生態項目如BAYC、MAYC、Otherdeed for Otherside等等然後產生的收益會分配給每個Bulldog會員<br />
                            您所持有的Bulldog將是您的會員卡，授予您的會員福利及收益。<br />
                            每個Bulldog都是獨一無二的，並以編程的方式從170多種可能的特徵中生成，包括表情、頭飾、服裝等。<br />
                            所有的Bulldog都是獨品，但有些比其他人更罕見。<br />
                            Bulldog作為ERC-721令牌存儲在以太坊區塊鏈上並託管在 <a onClick={goToIpfs} style={{ color: '#50FFF5' }}>IPFS</a> 上。<br />
                          </>,
                          en: <>
                            The Bulldog Club consists of 10,000 unique NFT items. As a club that grows with APE DAO, Bulldog will purchases various projects belonging to Bored Ape Yacht Club ecosystems, such as BAYC, MAYC, Otherdeed for Otherside and the proceeds from this will be divided among all Bulldog NFT holders.<br />
                            Your Bulldog NFTs will be your VIP card and will be granted its own privileges and earnings.<br />
                            Each Bulldog NFT is unique and is minted by the program with over 170 possible features, including facial expressions, headgear, and clothing. Each Bulldog is unique and some are even rare.<br />
                            Bulldogs are stored on the Ethereum blockchain as ERC-721 tokens and are also hosted on <a onClick={goToIpfs} style={{ color: '#50FFF5' }}>IPFS</a>.<br />
                          </>,
                          ja: <>
                            ブルドッグクラブは10,000件のユニークなNFTアイテムで構成されています。 APE DAOと共に成長していくクラブとして、BAYC、MAYC、Otherdeed for Othersideなど様々なBored Ape Yacht Clubエコシステムに属するプロジェクトを購入し、それによる収益をブルドッグの保有者に山分けします。<br />
                            ご保有のブルドッグアイテムがVIPカードとなり、それなりの特典や収益が付与されます。<br />
                            ブルドッグNFTはそれぞれにユニークで、表情、ヘッドギア、服装も含めて、170以上可能な特徴でプログラムよりミントされます。 各ブルドッグはユニークであり、中には珍しいものも存在してあります。<br />
                            ブルドッグはERC-721トークンとしてイーサリアムブロックチェーンに保存され、<a onClick={goToIpfs} style={{ color: '#50FFF5' }}>IPFS</a>にもホストされています。<br />
                          </>,
                          pt: <>
                            O Bulldog Club consiste em 10.000 NFT itens únicos. Como um clube que desenvolve junto com a DAO APE, o Bulldog comprará vários projetos do ecossistemas Bored Ape Yacht Club, tais como BAYC, MAYC, Otherdeed para Otherside e os lucros serão divididos entre todos os holders do Bulldog NFT.<br />
                            Seus Bulldog NFTs serão seu cartão VIP, com os quais consegue aproveitar seus privilégios e receber lucros. <br />
                            Cada Bulldog NFT é único e é mintado pelo programa com mais de 170 características possíveis, incluindo expressões faciais, adorno de cabeça, roupas, etc. <br />
                            Cada Bulldog é único e alguns são até mais raros. Os bulldogs são armazenados na Blockchain Ethereum como tokens ERC-721 e também são hospedados no <a onClick={goToIpfs} style={{ color: '#50FFF5' }}>IPFS</a>.<br />
                          </>
                        })}
                      </p>

                      <div className="_rbox">
                        <h2 className="common-title">{window.t({ zh: '公平分配', en: 'Fair Distribution', ja: '公正な立ち上げ', pt: 'Distribuição Justa' })}</h2>
                        <div>
                          <div>
                            <div>10,000</div>
                            <div>{window.t({ zh: '發售總量(Bulldog NFT)', en: 'Total volume sold(Bulldog NFT)', ja: '発売数(ブルドッグ NFT)', pt: 'Total de vendas(Bulldog NFT)' })}</div>
                          </div>
                          <div>
                            <div>{state.bulldogPrice}</div>
                            <div>{window.t({ zh: '發售單價(ETH)', en: 'Unit Price(ETH)', ja: '発売価格(ETH)', pt: 'Preço unitário(ETH)' })}</div>
                          </div>
                          <div>
                            <div><span>{window.t({ zh: 'OG輪發售', en: 'OG-Round Launch', ja: 'OGラウンド発売', pt: 'Rodada-OG Lançamento' })}</span> 05/10 0:00 - 05/11 24:00 {window.t({ zh: '(已結束)', en: '(Ended)', ja: '（完売）', pt: '(Terminou)' })} <br /><br /> <span>{window.t({ zh: '公開發售第1輪', en: 'Public-Round 1st', ja: '公開発売ラウンド一', pt: 'Primeira Rodada Pública ' })}</span> 05/25 12:00 - 05/31 24:00 {window.t({ zh: '(已結束)', en: '(Ended)', ja: '（完売）', pt: '(Terminou)' })}</div>
                            <div>{window.t({ zh: '發售時間(UTC)', en: 'Launch Time(UTC)', ja: '発売時間(UTC)', pt: 'Tempo de lançamento(UTC)' })}</div>
                          </div>
                        </div>
                        <div>
                          {window.t({
                            zh: <>
                              · 公開發售分多輪進行，每輪未售出的Bulldog NFT將自動銷毀。<br />
                              · 本次發售的90%收入將全部投入BulldogDAO，用於買入APE DAO的各種生態項目如BAYC、MAYC、Otherdeed for Otherside等賺取收益。<br />
                              · 剩餘 10% 收入將用於社區運維資金。<br />
                            </>,
                            en: <>
                              * The public launch will be divided into several rounds, and bulldog NFTs unsold at each round will be automatically burned.<br />
                              * 90% of the proceeds from the sale will be invested in the Bulldog DAO Vault to purchase various projects belonging to APE DAO ecosystems, such as BAYC, MAYC, Otherdeed for Otherside to generate revenue.<br />
                              * The remaining 10% will be used for community operations.<br />
                            </>,
                            ja: <>
                              * 公開発売は数回のラウンドに分けて行われる予定であり、毎回発売で売れ残ったブルドッグNFTが自動的にバーンされる予定です。<br />
                              * 今回の売却益の90%はブルドッグ DAOのVaultに投資され、BAYC、MAYC、Otherdeed for Othersideなど様々なAPE DAOエコシステムに属するプロジェクトの購入に利用され、収益取得する予定です。<br />
                              * 残りの10％はコミュニティの運営に利用される予定です。<br />
                            </>,
                            pt: <>
                              * A oferta pública será dividida em várias rodadas, e os Bulldog NFTs não vendidos em cada rodada serão automaticamente queimados.<br />
                              * 90% da receita da venda será investida no Bulldog DAO Vault para comprar NFTs de vários projetos pertencentes aos ecossistemas APE DAO, tais como BAYC, MAYC, Otherdeed da Otherside para gerar rendimentos.<br />
                              * Os demais 10% serão utilizados para operações da comunidade.<br />
                            </>,
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_temp-img text-left" style={{ marginTop: -220 }}>
                  <img width="208px" src={require('./images/point.png').default} alt="" />
                </div>
                <div id="_h2" className="row" style={{ marginTop: 188 }}>
                  <div className="col-lg-7 col-12 d-flex">
                    <div>
                      <h2 className="common-title">{window.t({ zh: 'Bulldog 願景', en: 'Bulldog Vision', ja: 'ブルドッグ ビジョン', pt: 'Visão da Bulldog' })}</h2>
                      <p className="common-p" style={{ marginTop: 48 }}>
                        {window.t({
                          zh: <>
                            Bulldog立誌成為一個專註於「猿宇宙」生態投資和建設的社區，每一位 Bulldog 不僅是「猿宇宙」 生態基金的憑證，更是參與「猿宇宙」的貢獻者。<br />
                            我們希望成為一個立體的生態建設組織，除了投資合適的「猿宇宙」資產外，還會在 「猿宇宙」中積累土地資產建立虛擬社區，成為遊戲的早期積極參與者…我們相信，把大家的力量聚集一起，比起個人持有，可以獲得更大的影響，更多的參與機會，並獲得 Yuga Labs 的官方支持。<br />
                          </>,
                          en: <>
                            Bulldog aims to become a community focusing on the eco-investment and construction of the "APE universe". Every Bulldog is not only a certificate of the "APE universe" eco fund, but also a contributor to the "APE universe".<br />
                            Bulldog hopes to become a three-dimensional ecosystem construction organization. In addition to investing in "APE universe" assets, we will also accumulate lands in Otherside to establish a virtual community and become an early active participant. We believe that gathering the community power can gain greater influence and participation opportunities, and obtain the official support of Yuga Labs.<br />
                          </>,
                          ja: <>
                            ブルドッグは、Apeユニバースへの投資及び構築に商店を当てたコミュニティの立ち上げることを目指します。いずれかのブルドッグはApeユニバースエコファンドの参加証明書だけでなく、Apeユニバースへの貢献者であります。<br />
                            ブルドッグは、全体的なエコシステム構築者となる予定です。適切なApeユニバースアセットを投資するだけでなく、Apeユニバースの土地を累積し、それに基づいてバーチャルコミュニティを立ち上げ、ゲームの早期かつ積極的な参加者となる予定です。応援の皆さんの力を集めることで、個人保有より大きなインパクト及びチャンスも得られ、その上、Yuga Labsから正式なサポートを得ることも可能と信じています。<br />
                          </>,
                          pt: <>
                            Bulldog visa a ser uma comunidade focada no investimento e construção do "universo APE". Cada Bulldog não é apenas um certificado do fundo do "universo APE", mas também um contribuinte para o "universo APE".<br />
                            Bulldog espera tornar-se uma organização de construção abrangente de ecossistema. Além de investir em ativos do "universo APE", também acumularemos terras no Otherside para estabelecer uma comunidade virtual e nos tornarmos um participante ativo desde cedo. Acreditamos que a reunião do poder da comunidade pode ganhar maior influência e oportunidades de participação, e obter o apoio oficial do Yuga Labs.<br />
                          </>
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 offset-lg-1">
                    <div className="img-bgbor">
                      <img className="img-fluid w-100" src={require('./images/gif.gif').default} alt="" />
                    </div>
                  </div>
                </div>
                <div className="_temp-img text-center" style={{ marginTop: 100 }}>
                  <img width="208px" src={require('./images/point.png').default} alt="" />
                </div>
                <div id="_h3" className="" style={{ marginTop: 50 }}>
                  <h2 className="common-title text-center">{window.t({ zh: '路線圖', en: 'Roadmap', ja: 'ロードマップ', pt: 'Mapa de rota' })}</h2>
                  <p className="text-center" style={{ marginTop: 20, fontSize: 20 }}>{window.t({ zh: 'Bulldog Club長期致力於此', en: 'In the future, the Bulldog Club will continue to promote these matters', ja: '今後、ブルドッグは持続的に、これらの件向けに推し進める', pt: 'Vá e compre Bulldog agora!' })}</p>
                  <div className="_tree" style={{ marginTop: 60 }}>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-10 ~ 2022-05-13</div>
                        <div>{window.t({ zh: 'OG輪發售，1,895個', en: 'OG-Round Launch 1,895 total', ja: 'OGラウンド発売、合計1,895個です', pt: 'Rodada-OG Lançamento 1,895 total' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-12</div>
                        <div>{window.t({ zh: '成立DAO, 完成多簽', en: 'Build DAO, finish multi-signature', ja: 'DAOを立ち上げ、マルチサインを完了', pt: 'Fundar DAO e criar carteira multi-assinatura' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-13</div>
                        <div>{window.t({ zh: '購買第一只BAYC ', en: 'Purchase a BAYC NFT', ja: 'つBAYC NFTを購入', pt: 'Adquirir um BAYC NFT' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-18</div>
                        <div>{window.t({ zh: '開啟Mystery Bulldog Hair獎勵 ', en: 'Mystery Bulldog Hair Launch', ja: 'ミステリーなブルドッグの毛 リリース', pt: 'Lançamento do Mystery Bulldog Hair' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-19</div>
                        <div>{window.t({ zh: '公示DAO金庫', en: 'Make DAO Vault Public', ja: 'DAO ボールトの開示', pt: 'Publicar o Cofre' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-20</div>
                        <div>{window.t({ zh: '上線DAO治理功能', en: 'DAO Governance Launch', ja: 'DAO ガバナンスのリリース', pt: 'Lançamento da Governança DAO' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-21</div>
                        <div>{window.t({ zh: 'OG輪開圖', en: 'OG-Round Open Box', ja: 'OGラウンド開封', pt: 'Rodada-OG Caixa Aberta' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-05-25 ~ 2022-06-01</div>
                        <div>{window.t({ zh: '公開發售第1輪，155個', en: 'Public-Round 1st, total of 155', ja: '公開発売ラウンド一　合計155個', pt: 'Primeira Rodada de Oferta Pública, 155 no Total' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-06-03</div>
                        <div>{window.t({ zh: '購買APEverse土地', en: 'Purchase land on Apeverse', ja: 'エイプバースの土地を購入', pt: 'Comprar terrenos APEverse' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-06-03</div>
                        <div>{window.t({ zh: '公開發售第1輪開圖', en: 'Public-Round 1st Open Box', ja: '公開発売ラウンド一開封', pt: 'Oferta Pública Rodada 1  Caixa Aberta' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-06-22 ~ 2022-09-03</div>
                        <div>{window.t({ zh: '流浪狗拯救行動', en: 'Bulldog Stray Dog Rescue Action', ja: 'ブルドッグ野良犬救済行動', pt: 'Resgate de cães de rua' })}</div>
                        {window.t({
                          zh: <img src="/images/launch/zh.png" />,
                          en: <img src="/images/launch/en.png" />,
                          ja: <img src="/images/launch/ja.png" />,
                          pt: <img src="/images/launch/pt.png" />,
                        })}
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div className="_tree-launch">
                        <div>2022-09-23</div>
                        <div>{window.t({ zh: 'Bulldog Town 施工', en: 'Bulldog Town Construction', ja: 'ブルドッグタウン建設', pt: 'Construção da Cidade de Bulldog' })}</div>
                      </div>
                    </div>
                    <div className="_tree-row">
                      <div>
                        <div>More</div>
                        <div>{window.t({ zh: '通過Dao治理生態發展及收益分配', en: 'Govern ecosystem growth with DAO & revenue sharing', ja: 'DAOでエコシステムの成長をガバナンスし、収益を山分けする', pt: 'Gerenciar o desenvolvimento do ecossistema e a distribuição de rendimento através da DAO' })}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_temp-img text-right" style={{ marginTop: 100, marginBottom: -30 }}>
                  <img width="208px" src={require('./images/point.png').default} alt="" />
                </div>
                <div id="_h4" className="">
                  <h2 className="common-title text-center">{window.t({ zh: 'FAQ', en: 'FAQ', ja: 'よくある質問', pt: 'Dúvidas Frequentes' })}</h2>
                  <div className="d-flex">
                    <div>
                      <img src={require('./images/0.png').default} alt="" />
                    </div>
                    <div className="faq">
                      <p className="faq-title">{window.t({ zh: 'Bulldog NFT發售詳細時間', en: 'What about the detailed time of Bulldog Launch?', ja: 'Bulldog NFT発売プランの詳しい内容について', pt: 'O horário do lançamento de Bulldog?' })}</p>
                      <p className="faq-content">
                        {window.t({
                          zh: <>
                            5.10-5.11 OG輪發售，發售數量1,895個。{/* <a style={{ color: '#50FFF5' }} onClick={handleOpenRefund}>申請退款</a> */}<br />
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 第一階段Front-Running：5月10日0:00-24:00（UTC），前1,000個Bulldog，每個白名單可不限量mint <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 第二階段Step-by-Step：5月10日22:30-5月11日18:00（UTC），1,001～1,895個NFT，每個白名單限定mint 255只<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;· 第三階段Last-Battle：5月11日18:00-24:00（UTC）如進入最後6小時仍有剩余，白名單不限量mint<br />
                            </span>
                            5.12-5.13 成立DAO ,完成多簽（OG輪退款截止5.13） <br />
                            5.12-5.13 Bulldog社區發起投票，購買第一只地板猴，實現APE DAO的第一步<br />
                            5.25-5.31 公開發售第1輪，發售數量1,000個（公售第1輪退款截止5.31）
                            {/* &nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(1)}>申請退款(Mystery購買) </a>
                            |&nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(2)}>申請退款(普通購買) </a>  */}
                            <br />
                          </>,
                          en: <>
                            May 10 – May 11, OG-Round Launch of 1,895 Bulldog NFTs. The WL release is divided into 3 stages:  {/* <a style={{ color: '#50FFF5' }} onClick={handleOpenRefund}>Refund request</a> */}<br />
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* The first stage Front-Running: 0:00-24:00 on May 10 (UTC), the first 1,000 Bulldogs, each whitelist can unlimited mint <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* The second stage Step-by-Step: 22:30 on May 10-18:00 on May 11 (UTC), 1,001 to 1,895 Bulldogs, each whitelist is limited to mint 255 Bulldog<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* The third stage Last-Battle: 18:00-24:00 on May 11 (UTC), if there are still some Bulldogs remaining in the last 6 hours, the whitelist can unlimited mint<br />
                            </span>
                            5.12-5.13 DAO is built, and multisignature is completed (deadline for OG-Round refund applications is May 13)<br />
                            5.12-5.13 With voting in community, Bulldog purchased one BAYC NFT at flor price. This marked the first step on the road to APE DAO.<br />
                            5.25-5.31 Public-Round 1st, with a total of 1,000　(deadline for Public-Round 1st refund applications is May 31)
                            {/* &nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(1)}>Refund request(Mystery Purchase)</a>
                            &nbsp;|&nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(2)}>Refund request(General Purchase)</a>  */}
                            <br />
                          </>,
                          ja: <>
                            5月10日～5月11日 合計1,895個のブルドッグがOGラウンドで発売されました。{/* <a style={{ color: '#50FFF5' }} onClick={handleOpenRefund}>返金申込</a> */}<br />
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 1段階目　フロントランニング（先着特典）：5月10日0：00～24：00（UTC時間）、最初の1,000個限定でホワイトリストのミント数が限定されません。 <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 2段階目　ステップバイステップ（一歩ずつ）：5月10日22：30～5月11日18：00（UTC時間）、1,001～1,895個で、ホワイトリストごとに255個のブルドッグしかミントできません。<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 3段階目　ラストバトル（終末の戦い）：5月11日18：00～24：00（UTC時間）、最後の6時間まで、売れ残った場合、ホワイトリストのミント可能数が再び限定されないようになります。<br />
                            </span>
                            5.12-5.13 DAOを立ち上げ、マルチサインを完了（OGラウンド向けの返金申込み受付の締め切りが5月13日となる）<br />
                            5.12-5.13 ブルドッグコミュニティ投票により、フローア価格で一つBAYC NFTを購入した。これにより、APE DAOへの道の第一歩を踏み出しました。<br />
                            2022-05-31 公開発売ラウンド一で、合計1,000個です（公開発売ラウンド一の返金申込み受付の締め切りが5月31日となる）
                            {/* &nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(1)}>返金申込み（ミステリー発売)</a>
                            &nbsp;|&nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(2)}>返金申込み（一般発売）</a>  */}
                            <br />
                          </>,
                          pt: <>
                            De 10 a 11 de maio, Rodada-OG Oferta de 1.975 Bulldog NFTs. A Rodada-OG Oferta é dividido em 3 etapas: {/* <a style={{ color: '#50FFF5' }} onClick={handleOpenRefund}>Solicitação de reembolso</a> */}<br />
                            <span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 1ª etapa Front-Running: 0:00-24:00 em 10 de maio (UTC), os primeiros 1.000 Bulldogs. Cada membro OG pode mint sem limite de quantidade<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 2ª etapa Step-by-step: 0:00-18:00 em 11 de maio (UTC), 1.001º a 2.000º Bulldogs. Cada membro OG pode mint até 255 Bulldog.<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* 3ª etapa Last-Battle: 18:00-24:00 de 11 de maio (UTC), se ainda houver alguns Bulldogs nas últimas 6 horas, membros OG pode continuar fazer mint sem limite de quantidade. <br />
                            </span>
                            De 12 a 13 de maio, fundação DAO e criação da carteira multi-signature (Reembolso de Rodada OG até 13/5)<br />
                            De 12 a 13 de maio, a comunidade Bulldog votou a compra do primeiro BAYC de preço mínimo, que será o primeiro passo do Bulldog para a APE DAO.<br />
                            De 25 a 31 de maio, Primeira Rodada de Oferta Pública com 1.000 Bulldog NFTs no total. (Reembolso de Venda Pública Rodada 1 até 31/5)
                            {/* &nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(1)}>Pedido de reembolso(Compra Mystery)</a>
                            &nbsp;|&nbsp;<a style={{ color: '#50FFF5' }} onClick={() => handleOpenRefund(2)}>Pedido de reembolso( Compra Geral)</a>  */}
                            <br />
                          </>
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img src={require('./images/0.png').default} alt="" />
                    </div>
                    <div className="faq">
                      <p className="faq-title">
                        {window.t({
                          zh: 'Bulldog NFT發售過程退款方案',
                          en: 'Refund Requests for Bulldog NFT on Sale Process',
                          ja: 'ブルドックNFT発売での返金申込みについて',
                          pt: 'Plano de reembolso Bulldog NFT no processo de venda'
                        })}</p>
                      <p className="faq-content">
                        {window.t({
                          zh: 'Bulldog NFT在每輪發售結束前，支持用戶對該輪已購買的Bulldog NFT申請退款。 退款過程自動發生在區塊鏈上，同時為了防止惡意退款的行為，每次退款將收取10%的退款手續費。 即一隻Bulldog購買時單價為0.1 ETH，退款後可收回0.09 ETH。',
                          en: "Bulldog NFT supports refunds to Bulldog NFT sold until the deadline of each round. Refund requests are handled automatically on the blockchain, and to prevent malicious refund requests, 10% of the amount in each refund will be cost as a refund fee. If Alice purchased a Bulldog with 0.1 ETH, he will receive 0.09 ETH in that's refund request.",
                          ja: 'ブルドッグNFTは、各ラウンドの締め切りまで発売されたBulldog NFTへの払い戻しをサポートします。返金申込みの対応はブロックチェーン上で自動的に行われ、悪意の返金申込みを防ぐために、毎回返金で金額の10％が返金手数料としてコストされます。即ち、一枚のブルドッグを0.1ETHで購入した場合、返金で0.09ETHが受け取れます。',
                          pt: 'Antes do final de cada rodada de venda, os usuários podem apresentar pedido de reembolso. O processo de reembolso acontece automaticamente no blockchain. Para evitar solicitações de reembolso maliciosas, 10% do valor em cada reembolso será cobrado como taxa de reembolso, quer dizer, um Bulldog é comprado a 0,1 ETH por unidade e irá recuperar 0,09 ETH após o reembolso.',
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img src={require('./images/0.png').default} alt="" />
                    </div>
                    <div className="faq">
                      <p className="faq-title">{window.t({ zh: 'Bulldog團隊背景是怎樣的？', en: 'What about the background of Bulldog team?', ja: 'ブルドッグのチームについて？', pt: 'O que é o background da equipe Bulldog?' })}</p>
                      <p className="faq-content">
                        {window.t({
                          zh: 'Bulldog Talk Club由一群加密愛好者組成。我們來自新加坡、澳大利亞，在鏈下的世界裏，我們身份各異：NFT收藏愛好者、智能合約工程師、數據分析師、DeFi老韭菜… 在WEB3.0世界裏，我們有共同的喜好：APE Universe，看好其長期發展潛力，也希望始終保持參與 NFT 市場，不錯過日新月異的加密世界中的下一趟高速列車。',
                          en: 'Bulldog Talk Club is composed of a group of crypto enthusiasts. We come from Singapore, Australia. In the off-chain world, we have different identities: NFT collectors, smart contract engineers, data analysts, DeFi users... In WEB3.0 world, we have a common interest: APE Universe. We are optimistic about its long-term development, and hope to participate in the NFT market and not miss the next high-speed train in the rapidly changing crypto world.',
                          ja: 'Bulldog Talk Clubは、暗号通貨及び暗号技術の愛好家たちによって構成されています。 これをきっかけとして、シンガポール、オーストラリアからの様々な方が集まっています。オフチェーンの世界では、NFTコレクター、スマートコントラクトエンジニア、データアナリスト、DeFi愛好家など、さまざまな身分を持っています。WEB3.0の世界では、APEユニバースという共通の好みを持ち、その持続的な成長を期待し、劇的な変化が生じている暗号界隈でのチャンスを見逃さないようにNFTへの投資意欲を保っていると考えています。',
                          pt: 'O Bulldog Talk Club é composto por um grupo de entusiastas do criptomoedda. Viemos de Singapura, Austrália. No mundo off-chain, temos identidades diferentes: Coletores NFT, engenheiros de contratos inteligentes, analistas de dados, usuários DeFi... No mundo WEB3.0, temos um interesse comum: Universo APE. Estamos otimistas quanto ao seu desenvolvimento a longo prazo e esperamos participar do mercado NFT e não perder o próximo trem de alta velocidade no mundo de criptomoeda que muda rapidamente.',
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img src={require('./images/0.png').default} alt="" />
                    </div>
                    <div className="faq">
                      <p className="faq-title">{window.t({ zh: 'Bulldog如何治理？', en: 'How will governance be achieved for Bulldog DAO?', ja: 'ブルドッグ DAOはガバナンスがどのように実現されますか？', pt: 'Como funciona a governança da Bulldog DAO?' })}</p>
                      <p className="faq-content">
                        {window.t({
                          zh: 'Bulldog DAO的治理權交給Bulldog DAO各個成員（Bulldog NFT持有者）持有者皆有權重，可發起提案及投票。',
                          en: 'Governance of the Bulldog DAO is left to each Bulldog DAO member, i.e., the Bulldog NFT holders. DAO members may submit proposals and vote.',
                          ja: 'ブルドッグ DAOのガバナンスは、各ブルドッグ DAOメンバー、すなわちブルドッグ NFTの保有者に委ねられ、DAOメンバーは提案を提出し、投票することができます。',
                          pt: 'A governança da Bulldog DAO é deixada para cada membro do Bulldog DAO, ou seja, os holders do Bulldog NFT. Os membros do DAO podem fazer propostas e votar.',
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img src={require('./images/0.png').default} alt="" />
                    </div>
                    <div className="faq">
                      <p className="faq-title">{window.t({ zh: 'Bulldog會被中止嗎？', en: 'What about the possibility of the Bulldog being cancelled?', ja: 'ブルドッグが中止される可能性については？', pt: 'Há possibilidade de que a Bulldog DAO seja cancelada?' })}</p>
                      <p className="faq-content">
                        {window.t({
                          zh: <>完全可以。如果Bulldog 持有數量投票過半同意終止，我們將會把持有的所有資產通過公開市場變現，所得資金按比例分配給每一位社區成員。</>,
                          en: <>It is possible. If a majority of Bulldog holders approve in a decision-making vote, all assets held will be sold on the market and the funds from the sale will be distributed according to holdings of Bulldog.</>,
                          ja: <>可能です。ブルドッグ保有者の過半が意思決定投票で認める場合、保有しているアセットを全てマーケットで売却し、それによる資金が保有状況に応じて配布されます。</>,
                          pt: <>Sim, é possível. Se a maioria dos titulares da Bulldog aprovar em uma votação decisiva, todos os ativos detidos serão vendidos no mercado e os fundos da venda serão distribuídos de acordo com as participações da Bulldog.</>,
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img src={require('./images/0.png').default} alt="" />
                    </div>
                    <div className="faq">
                      <p className="faq-title">{window.t({ zh: '如何把Bulldog的金庫做大？', en: `How to increase Bulldog's DAO vault assets?`, ja: 'ブルドッグのDAOヴォールト資産を増加させる方法について', pt: 'Como aumentar os ativos do vault da Bulldog DAO?' })}</p>
                      <p className="faq-content">
                        {window.t({
                          zh: '首先資金不會一次性使用完，其次重心會是通過APE DAO的影響獲得更多的合作機會，發售聯名衍生資產，通過優質生態項目的白名單積累鑄造收益，以及拓展我們自己的衍生資產。',
                          en: 'First of all, the funds raised will not be used up in one go. What Bulldog should think about is borrowing influence from APE DAO, seizing more partnership opportunities, and launching collaborative derivative assets. Utilizing a whitelist of good quality projects in its ecosystem, and accumulate mint revenues and enrich the derivative asset types of the bulldog.',
                          ja: 'まず、調達された資金が一回で使い切るわけではありません。ブルドッグとして、考えべきのはAPE DAOの影響力を借り、より多くの提携チャンスをつかみ、またはコラボレーションデリバティブ資産を発売することです。そのエコシステムで良質のプロジェクトのホワイトリストを利用して、ミント収益を累積し、ブルドッグ自体のデリバティブアセット種類を豊かにさせます。',
                          pt: 'Em primeiro lugar, os fundos financiados não serão utilizados de uma só vez. O que a Bulldog deve pensar é em usufruir da influência da APE DAO para ter mais oportunidades de parceria,  lançar ativos derivativos colaborativos, e utilizar uma Whitelist de projetos de boa qualidade em seu ecossistema para acumular rendimentos do mint e enriquecer os tipos de ativos derivados do Bulldog.',
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal title={window.t({ zh: 'Bulldog 流浪狗拯救行動指南', en: 'Bulldog Stray Dog Rescue Action Guide', ja: 'Bulldog Stray Dog Rescue Action Guide', pt: 'Guia de Bulldog Proteção ao Cachorro da Rua' })} visible={state.guideShow} wrapClassName="guide-modal-box"
        width="80%"
        style={{ maxWidth: 1200 }}
        centered={true}
        maskClosable={false}
        onCancel={()=>{handleCancel()}}
        footer={state.guideStep<=-2?[
          <Button key="submit" type="back" onClick={handleGoNext}>
            {window.t({ zh: 'Next ', en: 'Next', ja: 'Next', pt: 'Next' })}
          </Button>,
        ]:state.guideStep>=14?[
          <Button key="primary" type="back" onClick={handleGoPrev}>
            {window.t({ zh: 'Prev ', en: 'Prev', ja: 'Prev', pt: 'Prev' })}
          </Button>,
          <Button
            type="back"
            onClick={handleCancel}
          >
            {window.t({ zh: 'I Know ', en: 'I Know', ja: 'I Know', pt: 'I Know' })}
          </Button>,
        ]:[
          <Button key="primary" type="back" onClick={handleGoPrev}>
            {window.t({ zh: 'Prev ', en: 'Prev', ja: 'Prev', pt: 'Prev' })}
          </Button>,
          <Button key="submit" type="back" onClick={handleGoNext}>
            {window.t({ zh: 'Next ', en: 'Next', ja: 'Next', pt: 'Next' })}
          </Button>
        ]}
        >
        <div className="left guide-menu">
          <Tabs defaultActiveKey="1" tabPosition="left" style={{ height: '',width:'' }} onChange={(key)=>{changeModelTab(key)}} activeKey={state.currModalTabKey}>
            {
                GuideMenu.map((item,index) => (
                  <TabPane tab={item.label} key={item.current}>
                    <div className="right guide-content">
                      {
                        state.currModalTabKey==1 && 
                        <div className='step-block step-2'>
                          <h5>{window.t({ zh: '背景 ', en: 'Background', ja: 'Background', pt: 'Fundo' })}</h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '公開發售第一輪時未售出的Bulldog被迫流浪到另一個時空，現在它們以不同的面貌回到了Bulldog星球。為了拯救這些Bulldog，BulldogTalkClub發起了針對Bulldog的流浪狗拯救行動。', 
                                en: 'Unsold Bulldogs during the public launch round-1 were forced to wander into another time and space, and now they come back to the bulldog planet with a different appearance. To rescue these bulldogs, the Bulldog Talk Club launched OBulldog Stray Dogs Rescue Action.', 
                                ja: 'Unsold Bulldogs during the public launch round-1 were forced to wander into another time and space, and now they come back to the bulldog planet with a different appearance. To rescue these bulldogs, the Bulldog Talk Club launched OBulldog Stray Dogs Rescue Action.', 
                                pt: 'Os Bulldogs que não foram vendidos na primeira rodada da venda pública foram forçados a vaguear para um tempo e espaço diferentes, sua aparência mudou e agora estão de volta ao Planeta de Bulldog. Para resgatar estes bulldogs, o Bulldog Talk Club decidiu lançar um plano de resgate de cachorros da rua.' 
                              })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '該行動獲得的ETH的10%(原計劃用於項目運營), 將以BulldogDAO的名義捐給流浪狗救援組織。', 
                                en: '10% of the ETH received from the action will be donated to a stray dog rescue organization, which was originally planned to be used for project operations.', 
                                ja: '10% of the ETH received from the action will be donated to a stray dog rescue organization, which was originally planned to be used for project operations.', 
                                pt: '10% do ETH recebido da licitação será doado a uma organização de resgate de cachorros da rua, que foi planejado para ser usado em operações de projeto originalmente.' 
                              })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '拍賣方式:英式拍賣', 
                                en: 'Auction Method: English Auction', 
                                ja: 'Auction Method: English Auction', 
                                pt: 'Método de leilão: Leilão Inglês' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/-3.JPEG').default} alt="" />,
                            en: <img src={require('./images/guide/en/-3.JPEG').default} alt="" />,
                            ja: <img src={require('./images/guide/en/-3.JPEG').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/-3.JPEG').default} alt="" />,
                          })}
                        </div>
                      }
                      {
                        state.currModalTabKey==2 && 
                        <div className='step-block step-1'>
                          <h5>{window.t({ zh: '準備工作', en: 'Before Using', ja: 'Before Using', pt: 'Antes de usar' })}</h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '1. 由於你必須使用你的資產來參與拍賣過程，所以在所有操作之前，請先連接錢包。', 
                                en: '1. Since you have to use your assets to participate in the auction process, it is necessary to connect the wallet before all operations.', 
                                ja: '1. Since you have to use your assets to participate in the auction process, it is necessary to connect the wallet before all operations.', 
                                pt: '1. Como você tem que usar seus bens para participar do processo de leilão, é necessário conectar a carteira antes de todas as operações.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/-2.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/-2.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/-2.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/-2.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                                {window.t({ 
                                  zh: '2. 如果你想參與競拍，並獲得神秘購買的機會，你需要持有Mystery Bulldog Hair。', 
                                  en: '2. And you need to hold Mystery Bulldog Hairs, if you want to participate in bidding and get the chance to MysteryMint stray Bulldogs;', 
                                  ja: '2. And you need to hold Mystery Bulldog Hairs, if you want to participate in bidding and get the chance to MysteryMint stray Bulldogs;', 
                                  pt: '2. E você precisa segurar os Mystery Bulldog Hairs, se quiser participar da licitação e ter a oportunidade de comprar Bulldogs em forma de Mint Misterioso;' 
                                })}
                              </p>
                            </div>
                            {window.t({
                              zh: <img src={require('./images/guide/zh/-1.png').default} alt="" />,
                              en: <img src={require('./images/guide/en/-1.png').default} alt="" />,
                              ja: <img src={require('./images/guide/en/-1.png').default} alt="" />,
                              pt: <img src={require('./images/guide/pt/-1.png').default} alt="" />,
                            })}
                            <div>
                              <p>
                                {window.t({ 
                                  zh: '3. 你還需要點擊Approve按鈕，以確保智能合約可以在你的批準下使用它們。', 
                                  en: '3. Then, you need to click Approve button to make sure that smart contracts can using them with your approvement.', 
                                  ja: '3. Then, you need to click Approve button to make sure that smart contracts can using them with your approvement.', 
                                  pt: '3. Então, você precisa clicar no botão Aprovar para ter certeza de que contratos inteligentes podem usar os Mystery Bulldog Hairs com a sua aprovação.' 
                                })}
                              </p>
                            </div>
                            {window.t({
                              zh: <img src={require('./images/guide/zh/0.png').default} alt="" />,
                              en: <img src={require('./images/guide/en/0.png').default} alt="" />,
                              ja: <img src={require('./images/guide/en/0.png').default} alt="" />,
                              pt: <img src={require('./images/guide/pt/0.png').default} alt="" />,
                            })}
                        </div>
                      }
                      {
                        state.currModalTabKey==3 && 
                        <div className='step-block step2'>
                          <h5>{window.t({ zh: '參與競價', en: 'Participate in bidding', ja: 'Participate in bidding', pt: 'Participar da licitação' })}</h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '1. 你只能在競價階段出價。', 
                                en: '1. You will be able to bid only at the bidding stage.', 
                                ja: '1. You will be able to bid only at the bidding stage.', 
                                pt: '1. Você poderá licitar somente na fase de licitação.' 
                              })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '1）查看等待拯救的流浪狗。', 
                                en: '1) Check about stray dogs awaiting rescue; ', 
                                ja: '1) Check about stray dogs awaiting rescue; ', 
                                pt: '1) Verificar sobre Bulldog à espera de resgate;' 
                              })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '2）查看是否位於競價階段。', 
                                en: '2) Check if it is located in the Bidding stage; ', 
                                ja: '2) Check if it is located in the Bidding stage; ', 
                                pt: '2) Verificar se ele está localizado na fase de licitação; ' 
                              })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '3）查看階段倒計時。 ', 
                                en: '3) Check the stage countdown. ', 
                                ja: '3) Check the stage countdown.', 
                                pt: '3) Verificar a contagem regressiva da fase.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/1.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/1.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/1.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/1.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                              {window.t({ zh: '2. ', en: '2.', ja: '2. ', pt: '2. ' })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '1) 檢查Mystery Bulldog Hair的余額', 
                                en: '1) Check the balance of Mystery Bulldog Hairs', 
                                ja: '1) Check the balance of Mystery Bulldog Hairs', 
                                pt: '1) Verifique o saldo de Mystery Bulldog Hairs' 
                              })}
                            </p>
                            <p>
                              {window.t({ 
                                zh: '2) 輸入你想支付的Mystery Bulldog Hair的數量，並點擊出價。', 
                                en: '2) Enter the numbers of Mystery Bulldog Hairs you want to pay, and click Make Offer.', 
                                ja: '2) Enter the numbers of Mystery Bulldog Hairs you want to pay, and click Make Offer.', 
                                pt: '2) Digite os números de Mystery Bulldog Hairs que você quer pagar, e clique em Fazer Oferta.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/2.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/2.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/2.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/2.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                              {window.t({ 
                                zh: "3. 你可以在“競價階段訂單”中確認你的競價訂單，你也可以在下方查看你的競價。", 
                                en: "3. You can confirm your bid offer in 'Offers in Bidding Stage', and you can also check your offer", 
                                ja: "3. You can confirm your bid offer in 'Offers in Bidding Stage', and you can also check your offer", 
                                pt: "3. Você pode verificar sua oferta de licitação em 'Ofertas em Fase de Licitação', onde também pode confirmar sua oferta" 
                              })}
                            </p>
                            <p style={{paddingLeft:'15px'}}>
                              {window.t({ 
                                zh: "如果你可以看到競價訂單, 恭喜你! 你已經成功提交了競價訂單。", 
                                en: "Congratulations! You have completed the bid offer submission.", 
                                ja: "Congratulations! You have completed the bid offer submission.", 
                                pt: "Você completou a apresentação da oferta de compra." 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/3.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/3.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/3.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/3.png').default} alt="" />,
                          })}
                        </div>
                      }
                      {
                        state.currModalTabKey==4 && 
                        <div className='step-block step5'>
                          <h5>
                            {window.t({ zh: '神秘購買', en: 'Mystery Mint', ja: 'Mystery Mint', pt: 'Mint Misterioso' })}
                          </h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '1. 在使用神秘購買功能之前, 你需要檢查一下當前階段是否處於神秘購買階段以及自己的競價訂單是否處於榜單前三位. 如果在你連接錢包後神秘購買按鈕不可使用, 那麼你的地址將無法參與當前神秘購買.', 
                                en: '1. Before using Mystery Mint, you need to check that if the stage is located at the Mystery Mint stage and if your offer is in the top 3 of Rank. If the Mystery Mint button is not available after website connected to your wallet, your account will not be able to participate in the current Mystery Mint.', 
                                ja: '1. Before using Mystery Mint, you need to check that if the stage is located at the Mystery Mint stage and if your offer is in the top 3 of Rank. If the Mystery Mint button is not available after website connected to your wallet, your account will not be able to participate in the current Mystery Mint.', 
                                pt: '1. Antes de Mint Misterioso, você precisa verificar se está na fase de Mint Misterioso e se sua oferta está no top 3 do Rank. Se o botão de Mint Misterioso não estiver disponível após o site conectado à sua carteira, sua conta não poderá participar de Mint Misterioso atual.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/5.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/5.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/5.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/5.png').default} alt="" />,
                          })}
                          <div>
                            <p>{window.t({ zh: '2. 購買細則', en: '2. Mint Pass', ja: '2. Mint Pass', pt: '2. Passo de Mint' })}</p>
                            {window.t({
                              zh: <img src={require('./images/guide/zh/6-1.png').default} alt="" />,
                              en: <img src={require('./images/guide/en/6-1.png').default} alt="" />,
                              ja: <img src={require('./images/guide/en/6-1.png').default} alt="" />,
                              pt: <img src={require('./images/guide/pt/6-1.png').default} alt="" />,
                            })}
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/6-2.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/6-2.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/6-2.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/6-2.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                              {window.t({ 
                                zh: '3. 你需要點擊Approve按鈕, 授權智能合約使用你的 Mystery Bulldog Hair, 並在Metamask的彈窗中點擊確認, 接下來, 你需要點擊神秘購買按鈕, 並在Metamask中確認. 等區塊鏈確認交易後, 你就完成了神秘購買流程.', 
                                en: '3. You need to click Mystery Mint button to approve your Mystery Bulldog Hairs to smart contracts, and confirm it in metamask pop-up window, and then, you need to click Mystery Mint button again, and also confirm it in metamask. Then, after transcation confirmed by blockchain, you can complete the Mystery Mint progress.', 
                                ja: '3. You need to click Mystery Mint button to approve your Mystery Bulldog Hairs to smart contracts, and confirm it in metamask pop-up window, and then, you need to click Mystery Mint button again, and also confirm it in metamask. Then, after transcation confirmed by blockchain, you can complete the Mystery Mint progress.', 
                                pt: '3. Você precisa clicar no botão Mint Misterioso para aprovar seu Mystery Bulldog Hairs para contratos inteligentes, e confirmá-lo em metamask. Depois você precisa clicar no botão Mint Misterioso novamente, também confirmá-lo em metamask. Em seguida, após a transação for confirmado em blockchain, você pode completar o processo de Mint Misterioso.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/7.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/7.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/7.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/7.png').default} alt="" />,
                          })}
                        </div>
                      }
                      {
                        state.currModalTabKey==5 && 
                        <div className='step-block step8'>
                          <h5>
                            {window.t({ zh: '一般購買', en: 'Public Mint', ja: 'Public Mint', pt: 'Mint Público' })}
                          </h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '1. 在進行一般購買之前, 你需要檢查當前階段是否處於一般購買階段.', 
                                en: '1. Before using Public Mint, you need to check that if the stage is located at the Public Mint stage', 
                                ja: '1. Before using Public Mint, you need to check that if the stage is located at the Public Mint stage', 
                                pt: '1. Antes de Mint Público, você precisa verificar se está na fase de Mint Público.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/8.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/8.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/8.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/8.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                              {window.t({ 
                                zh: '2. 確認階段後，你可以點擊一般購買按鈕，並在Metamask中點擊確認. 這樣你就可以用0.1ETH Mint這只流浪Bulldog。', 
                                en: '2. After confirmed the stage, you can click the Public Mint button, and click confirm in Metamask, and you can mint this stray Bulldog with 0.1 ETH', 
                                ja: '2. After confirmed the stage, you can click the Public Mint button, and click confirm in Metamask, and you can mint this stray Bulldog with 0.1 ETH', 
                                pt: '2. Após confirmar a fase, você pode clicar no botão Mint Público. Clique em Confirmar em Metamask, e você pode mint este Bulldog da rua com 0,1 ETH.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/9.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/9.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/9.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/9.png').default} alt="" />,
                          })}
                        </div>
                      }
                      {
                        state.currModalTabKey==6 && 
                        <div className='step-block step10'>
                          <h5>
                            {window.t({ zh: '拯救行動結束', en: 'Rescue Action Ended', ja: 'Rescue Action Ended', pt: 'Ação de Resgate Termina:' })}
                          </h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '拯救中的Bulldog被Mint後，它將像以前一樣顯示，但不能再被其他人Mint。', 
                                en: 'After a Bulldog in Rescue is Minted, it will appear as before, but cannot be Minted by others anymore.', 
                                ja: 'After a Bulldog in Rescue is Minted, it will appear as before, but cannot be Minted by others anymore.', 
                                pt: 'Depois que um Bulldog em Resgate for Mint, ele aparecerá como antes, mas não pode mais ser Mint por outros.' 
                              })}
                            </p>
                            {window.t({
                              zh: <img src={require('./images/guide/zh/10-1.png').default} alt="" />,
                              en: <img src={require('./images/guide/en/10-1.png').default} alt="" />,
                              ja: <img src={require('./images/guide/en/10-1.png').default} alt="" />,
                              pt: <img src={require('./images/guide/pt/10-1.png').default} alt="" />,
                            })}
                            <p style={{marginTop:'20px'}}>
                              {window.t({ 
                                zh: '下一只bulldog的拯救行動行動將在倒計時結束後開始。', 
                                en: 'The next bulldog rescue action will begin after the countdown ends.', 
                                ja: 'The next bulldog rescue action will begin after the countdown ends.', 
                                pt: 'A próxima ação de resgate do Bulldog começará apó a contagem regressiva termina.' 
                              })}
                            </p>
                            {window.t({
                              zh: <img src={require('./images/guide/zh/10-2.png').default} alt="" />,
                              en: <img src={require('./images/guide/en/10-2.png').default} alt="" />,
                              ja: <img src={require('./images/guide/en/10-2.png').default} alt="" />,
                              pt: <img src={require('./images/guide/pt/10-2.png').default} alt="" />,
                            })}
                          </div>
                        </div>
                      }
                      {
                        state.currModalTabKey==7 && 
                        <div className='step-block step11'>
                          <h5>
                            {window.t({ zh: '拯救行動失敗', en: 'Rescue Action Failed', ja: 'Rescue Action Failed', pt: 'Plano de Resgate Falha:' })}
                          </h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '如果在當前Bulldog拯救行動開始後的0~72小時內沒有人Mint，則該Bulldog的拯救行動失敗，並被移至墓地。', 
                                en: 'If there is no one minted within 0~72 hours after the start of the current bulldog rescue action, this bulldog rescue action fails and is moved to the cemetery.', 
                                ja: 'If there is no one minted within 0~72 hours after the start of the current bulldog rescue action, this bulldog rescue action fails and is moved to the cemetery.', 
                                pt: 'Se não houver ninguém mint o Bulldog dentro de 0~72 horas após o início da ação de resgate do Bulldog, este Bulldog falha de ser resgatado e é movido para o cemitério.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/11.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/11.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/11.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/11.png').default} alt="" />,
                          })}
                        </div>
                      }
                      {
                        state.currModalTabKey==8 && 
                        <div className='step-block step12'>
                          <h5>
                            {window.t({ 
                                zh: '查看獲救的bulldog和捐贈金額', 
                                en: 'Check the rescued bulldogs and donation amounts:', 
                                ja: 'Check the rescued bulldogs and donation amounts:', 
                                pt: 'Verifique os Bulldogs resgatados e os valores das doações:' 
                              })}
                          </h5>
                          <div>
                            <p>
                              {window.t({ 
                                zh: '1. 點擊左邊的卡片，可以切換顯示的Bulldog 。你可以查看過去拯救行動結束/失敗的Bulldog 。', 
                                en: '1. Click on the left side of the card to switch to the bulldog displayed. You can check the bulldog that have ended/failed in rescue action in the past.', 
                                ja: '1. Click on the left side of the card to switch to the bulldog displayed. You can check the bulldog that have ended/failed in rescue action in the past.', 
                                pt: '1. Clique no lado esquerdo do cartão para ver o Bulldog exibido. Você pode verificar os Bulldogs que foram resgatado com sucesso ou não na ação de resgate no passado.' 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/12.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/12.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/12.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/12.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                              {window.t({ 
                                zh: `2. 在 "已經拯救的Bulldog "下方，你可以查看被成功拯救和開始拯救行動的Bulldog數量。`, 
                                en: "2. Displayed below the 'Bulldog Rescued', you can check the numbers of Bulldog successfully rescued and started rescue action.", 
                                ja: "2. Displayed below the 'Bulldog Rescued', you can check the numbers of Bulldog successfully rescued and started rescue action.", 
                                pt: "2. No 'Bulldog Rescued' abaixo, você pode verificar o número de Bulldogs que foram resgatados com sucesso e foram iniciados a resgatar." 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/13.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/13.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/13.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/13.png').default} alt="" />,
                          })}
                          <div>
                            <p>
                              {window.t({ 
                                zh: `3. 你也可以在 "BulldogDAO將捐出 "下面查看Bulldog將捐出的金額。`, 
                                en: "3. And you can also check the the amount Bulldog will donate below 'BulldogDAO will donate'.", 
                                ja: "3. And you can also check the the amount Bulldog will donate below 'BulldogDAO will donate'.", 
                                pt: `3. E você também pode verificar a quantidade que o Bulldog doará para ONG de proteção ao cachorro da rua abaixo no "A BulldogDAO doará".` 
                              })}
                            </p>
                          </div>
                          {window.t({
                            zh: <img src={require('./images/guide/zh/14.png').default} alt="" />,
                            en: <img src={require('./images/guide/en/14.png').default} alt="" />,
                            ja: <img src={require('./images/guide/en/14.png').default} alt="" />,
                            pt: <img src={require('./images/guide/pt/14.png').default} alt="" />,
                          })}
                        </div>
                      }
                      </div>
                  </TabPane>
                ))
            }
          </Tabs>
        </div>
        
      </Modal>
    </div>
    </>
  )
}