export default () => {
  var local = window.location.href.includes('//localhost')
  var test = window.location.href.includes('//test.bulldog.club')
  var preprod = window.location.href.includes('//preprod.bulldog.club')
  var prod = window.location.href.includes('//bulldog.club')

  if (local) {
    document.title = '本地开发'
  }
  if (test) {
    document.title = '测试环境'
  }

  if (local || test) {
    return {
      __NAME: 'local | test',
      REACT_APP_INFURA_KEY: 'ef7208c3850b4a1d80b89dd96eddaa89',
      REACT_APP_DEFAULT_CHAINID: 80001,
      CONTRACT_ADDRESS_OGCLAIM: '0x2cEd5f5C420eCD182f8e2Ff84c086600D47B9f8F',
      CONTRACT_ADDRESS_V2: '0xC5cF5b9c085FD0fa860E82648cD9C79692cA70c8',
      CONTRACT_ADDRESS: '0xcE0b986F21c47f7207Df70Ba9761FC51EA70751D',
      OPEN_SEA_API_KEY: '5bec8ae0372044cab1bef0d866c98618',
      OPEN_SEA_ACCOUNT: 'https://testnets.opensea.io/account',
      OPEN_SEA_API: 'https://testnets-api.opensea.io/api/v1',
      OPEN_SEA_URL: 'https://testnets.opensea.io/assets/',
      IPFS_API_PUB: 'https://bulldog.mypinata.cloud/ipfs/QmVFLKGZtq4EnnjWgdQSuasxCHvsZonxozeUYpPoeztu8f/',
      IPFS_API_V2: 'https://gateway.pinata.cloud/ipfs/QmcgVCJfnYGU6tBd63fqRFq138RbDhsyyunZ9HnU5873JQ/',
      IPFS_API: 'https://gateway.pinata.cloud/ipfs/QmcgVCJfnYGU6tBd63fqRFq138RbDhsyyunZ9HnU5873JQ/',
      S3_URL_PUB: 'https://bulldog-test-images.s3.ap-southeast-1.amazonaws.com/first-kobgh/',
      S3_URL_V3: 'https://bulldog-test-images.s3.ap-southeast-1.amazonaws.com/roam-dog/',
      S3_URL_V2: 'https://bulldog-test-images.s3.ap-southeast-1.amazonaws.com/assets/',
      S3_URL: 'https://bulldog-test-images.s3.ap-southeast-1.amazonaws.com/og-assets/',
      SECTION: 4,
      PRICE: 0.0001,
      DOGHAIR_CONTRACT_ADDRESS: '0xFceE5c681AFD29059694b9858a49108757dC6973',
      PROXY_CONTRACT_ADDRESS: '0x43b82EBA4D962c390898f24AE2c58a2D8894c1cf',
      REFUNDING_CLAIM_CONTRACT_ADDRESS: '0xa26908EF44a46d2B6E9A8165D7FEDE143e43Fe1D',
      WEB3_DATA_PROVIDER_RPC_URL: 'https://rpc.ankr.com/polygon', //eth
      // WEB3_DATA_PROVIDER_RPC_URL: 'https://rpc.ankr.com/eth', //eth
    }
  }

  if (prod || preprod) {
    return {
      __NAME: prod ? 'prod' : 'preprod',
      REACT_APP_INFURA_KEY: 'wNZOaTLE5KLA-Ddvic0Uwh2_JpSOfsLH',
      REACT_APP_DEFAULT_CHAINID: 1,
      CONTRACT_ADDRESS_OGCLAIM: prod ? '0x8fEbFcaB8982bAEF295c7ed6725e0ADf646e0C62' : '0x8fEbFcaB8982bAEF295c7ed6725e0ADf646e0C62',
      CONTRACT_ADDRESS_V2: prod ? '0xf93E8aA70a1E6BDEE4A3363A27cBDa0F81594ba4' : '0xf93E8aA70a1E6BDEE4A3363A27cBDa0F81594ba4',
      CONTRACT_ADDRESS: prod ? '0x43A3cDBB167E2bD9745BC122b913d3B306516E53' : '0x43A3cDBB167E2bD9745BC122b913d3B306516E53',
      OPEN_SEA_API_KEY: '86bf28dba4c54670b9974023a4cb39a0',
      OPEN_SEA_ACCOUNT: 'https://opensea.io/account',
      OPEN_SEA_API: 'https://api.opensea.io/api/v1',
      OPEN_SEA_URL: 'https://opensea.io/assets/',
      IPFS_API_PUB: 'https://bulldog.mypinata.cloud/ipfs/QmVFLKGZtq4EnnjWgdQSuasxCHvsZonxozeUYpPoeztu8f/',
      IPFS_API_V2: 'https://bulldog.mypinata.cloud/ipfs/QmWbQBhZ7DXj8tph7PB3Ejmz8soG6H8J3ui6bsQE9qdLMs/',
      IPFS_API: 'https://bulldog.mypinata.cloud/ipfs/QmWbQBhZ7DXj8tph7PB3Ejmz8soG6H8J3ui6bsQE9qdLMs/',
      S3_URL_PUB: 'https://bulldog-images2.s3.ap-southeast-1.amazonaws.com/first-okldg/',
      S3_URL_V3: 'https://bulldog-images2.s3.ap-southeast-1.amazonaws.com/roam-dog/',
      S3_URL_V2: 'https://bulldog-images2.s3.ap-southeast-1.amazonaws.com/assets/',
      S3_URL: 'https://bulldog-images2.s3.ap-southeast-1.amazonaws.com/og-assets/',
      SECTION: 3,
      PRICE: 0.1,
      DOGHAIR_CONTRACT_ADDRESS: '0xaD804109f0c2ba2Ff81343A4d39581a00be0a8d2',
      PROXY_CONTRACT_ADDRESS: '0x5e37542f2F5AEFA9437a5E3d6053E141519a68ff',
      REFUNDING_CLAIM_CONTRACT_ADDRESS: '0xa26908EF44a46d2B6E9A8165D7FEDE143e43Fe1D',
      WEB3_DATA_PROVIDER_RPC_URL: 'https://rpc.ankr.com/eth',
    }
  }
}

// preprod
// CONTRACT_ADDRESS: 0xeb8D676EFA4aD44E90A7351B33fdeeD351AE9631