// @ts-nocheck
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import MetamaskIcon from '../../assets/images/metamask.png'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { injected } from '../../connectors'
import { SUPPORTED_WALLETS } from '../../constants/wallet'
import usePrevious from '../../hooks/usePrevious'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks'
import AccountDetails from '../AccountDetails'
import Modal from '../Modal'
import Option from './Option'
import PendingView from './PendingView'
import { useAppDispatch } from 'state/hooks'
import { updateConnectWallet } from 'state/user/actions'
import { setupNetwork, switchNetwork } from '../../hooks/wallet'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 25px;
  font-weight: 500;
  font-size: 36px;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  @media screen and (max-width: 1000px) {
    font-size: 24px;
  }
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0 40px 40px 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`

const ContentWrapperText = styled.div`
  margin-top: 24px;
  font-size: 14px;
  color: #c3c5cb;
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
`

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
}

export default function WalletModal({
  pendingTransactions,
  confirmedTransactions,
}: {
  pendingTransactions: string[] // hashes of pending
  confirmedTransactions: string[] // hashes of confirmed
}) {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React()

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()

  const [pendingError, setPendingError] = useState<boolean>()

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()

  const previousAccount = usePrevious(account)
  const dispatch = useAppDispatch()

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false)
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [walletModalOpen])

  // close modal when a connection is successful
  const activePrevious = usePrevious(active)
  const connectorPrevious = usePrevious(connector)
  useEffect(() => {
    if (walletModalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [setWalletView, active, error, connector, walletModalOpen, activePrevious, connectorPrevious])

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = ''
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        // eslint-disable-next-line
        return (name = SUPPORTED_WALLETS[key].name)
      }
      return true
    })

    setPendingWallet(connector) // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING)

    connector &&
      activate(connector, undefined, true)
        .then(() => {
          dispatch(updateConnectWallet({ isConnectWallet: true }))
        })
        .catch(async (error) => {
          if (error instanceof UnsupportedChainIdError) {
            await switchNetwork() //先切换网络
            activate(connector)
            // const hasSetup = await setupNetwork()
            // if (hasSetup) {
            //   activate(connector)
            // }
          } else {
            setPendingError(true)
          }
        })
  }

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    const isBitKeep = window.ethereum && window.isBitKeep
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]
      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={<span>Install Metamask</span>}
                link={'https://metamask.io/'}
                icon={MetamaskIcon}
              />
            )
          } else if (option.name === 'BitKeep') {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                color={'#E8831D'}
                header={<span>Install BitKeep</span>}
                link={'https://bitkeep.com/download?type=2'}
                icon={option.iconURL}
              />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        // else if (option.name === 'MetaMask' && !isMetamask) {
        //   return null
        // }
        else if (option.name === 'MetaMask' && isBitKeep) {
          return null
        }
        else if (option.name === 'BitKeep' && !isBitKeep) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // return rest of options
      return (
        // !isMobile &&
        <Option
          id={`connect-${key}`}
          onClick={() => {
            option.connector === connector
              ? setWalletView(WALLET_VIEWS.ACCOUNT)
              : !option.href && tryActivation(option.connector)
          }}
          key={key}
          active={option.connector === connector}
          color={option.color}
          link={option.href}
          header={option.name}
          icon={option.iconURL}
        />
      )
    })
  }

  function getModalContent() {
    if (error) {
      return (
        <UpperSection>
          <CloseIcon onClick={toggleWalletModal}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>
            {/* {error instanceof UnsupportedChainIdError ? <span>Wrong Network</span> : <span>Error connecting</span>}
             */}
            {error instanceof UnsupportedChainIdError ? <span>{window.t({ zh: '網路錯誤', en: 'Wrong Network', ja: 'ネット環境エラー' })}</span> : <span>{window.t({ zh: '連接錯誤', en: 'Error connecting', ja: '接続エラー' })}</span>}
          </HeaderRow>
          <ContentWrapper>
            {error instanceof UnsupportedChainIdError ? (
              <h5>
                {/* <span>Please connect to the appropriate ETH network.</span> //{t('單價', 'unit price')} */}
                <span>{window.t({ zh: '請連接正確的ETH網路', en: 'Please connect to the appropriate ETH network', ja: 'ネットワーク環境エラー、イーサリアムに切り替えください' })}</span>
              </h5>
            ) : (
              <span>Error connecting. Try refreshing the page.</span>
            )}
          </ContentWrapper>
        </UpperSection>
      )
    }
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <AccountDetails
          toggleWalletModal={toggleWalletModal}
          pendingTransactions={pendingTransactions}
          confirmedTransactions={confirmedTransactions}
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
        />
      )
    }

    return (
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <CloseColor />
        </CloseIcon>
        {/* {walletView !== WALLET_VIEWS.ACCOUNT ? (
          <HeaderRow color="blue">
            <HoverText
              onClick={() => {
                setPendingError(false)
                setWalletView(WALLET_VIEWS.ACCOUNT)
              }}
            >
              <span>Back</span>
            </HoverText>
          </HeaderRow>
        ) : (
          <HeaderRow>
            <span>Connect to a wallet</span>
          </HeaderRow>
        )} */}
        <HeaderRow>
          <span>{window.t({ zh: '連接錢包', en: 'Connect Wallet', ja: 'ログイン', pt: 'Conectar Wallet' })}</span>
        </HeaderRow>

        <ContentWrapper>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
          <ContentWrapperText>
            <div>
              {window.t({
                zh: '白俄羅斯、中非共和國、剛果民主共和國、北韓、中華人民共和國、烏克蘭克裡米亞地區、古巴、伊朗、利比亞、索馬利亞、蘇丹、南蘇丹、敘利亞、美國、葉門， 以及辛巴威或任何其他禁止訪問或使用Bulldog網站的管轄區（“禁止管轄區”）。',
                en: 'The Bulldog website is not available to residents of Belarus, the Central African Republic, the Democratic Republic of Congo, the Democratic People’s Republic of Korea, the People’s Republic of China, the Crimea region of Ukraine, Cuba, Iran, Libya, Somalia, Sudan, South Sudan, Syria, the USA, Yemen, and Zimbabwe or any other jurisdiction in which accessing or using the Bulldog website is prohibited (“Prohibited Jurisdictions”). ',
                ja: 'ブルドッグウェブサイトは以下の国及び地域の居住者に対してサービスを提供しません：ベラルーシ、中央アフリカ共和国、コンゴ民主共和国、朝鮮民主主義人民共和国、中華人民共和国、ウクライナ・クリミア地域、キューバ、イラン、リビア、ソマリア、スーダン、南スーダン、シリア、米国、イエメン、ジンバブエ、その他ブルドックウェブサイトへのアクセスや利用が禁止されている法域（「禁止法域」）',
                pt: 'O site de Bulldog não está disponível para residentes de Belarus, República Centro-Africana, República Democrática do Congo, República Popular Democrática da Coréia, República Popular da China, região da Crimeia da Ucrânia, Cuba, Irã, Líbia, Somália, Sudão, Sudão do Sul, Síria, EUA, Iêmen e Zimbábue ou qualquer outra jurisdição na qual o acesso do site Bulldog é proibido ("Jurisdições Proibidas"). '
              })}
            </div>
            <br />
            <div>
              {window.t({
                zh: '連接錢包即表示您已閱讀並理解Bulldog網站的免責聲明。',
                en: "By connecting a wallet, you acknowledge that you have read and understand the Bulldog website's disclaimer.",
                ja: 'ウォレットと接続することにより、お客様はブルドッグウェブサイトの利用説明を読み、理解したことを認めるものと見なされます。',
                pt: 'Ao conectar uma carteira, você reconhece que leu e entendeu a isenção de responsabilidade do site de Bulldog.'
              })}
            </div>
          </ContentWrapperText>
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
