// @ts-nocheck
import RemintModal from './components/remintModal'
import PreviewModal from './components/previewModal'
import './index.scss';
import Header from 'components/HeadComp';
import Footer from 'components/Footer';
import pageConfig from 'pages/page.config';
import { useReactive } from 'ahooks'
import { getGasPrice } from 'utils/genGasOption';
import { ArrowRight } from 'react-feather'
import { useWeb3React } from '@web3-react/core';
import Loader from 'components/Loader';
import { useTransactionAdder } from 'state/transactions/hooks'
import StarSVG from './images/starts.svg'
import BotSVG from './images/robot.svg'
import Logo from './images/logo.svg'
import { ExternalLink } from 'react-feather'
import QuestionSvg from './images/question.svg'
import ArrowSvg from './images/arrow.svg'
import { useContract } from 'hooks/useContract';
import ABI_OGCLAIM from 'abi/ogclaim.json'
import ABI from 'abi/dog.json'
import genGasOption, { getChainGasprice } from 'utils/genGasOption'
import qs from 'qs'
import LoaderIcon from 'components/Loader'
import { useMount, useUpdateEffect } from 'ahooks'
const CONTRACT_ADDRESS_OGCLAIM = pageConfig().CONTRACT_ADDRESS_OGCLAIM.toLowerCase()
const CONTRACT_ADDRESS = pageConfig().CONTRACT_ADDRESS.toLowerCase()

export default function Remint() {
  const { account, chainId } = useWeb3React()
  const state = useReactive({
    now: 1,
    num: 0,
    ids: [],
    hasIds: false,
    hasIdsLoading: true,
    remintLoading: false,
    bulldogDataSource: [],
    hasRemintModal: false,
    hasPreviewModal: false,
  })

  console.log('ids', [...state.ids])
  const userContractOGClaim = useContract(CONTRACT_ADDRESS_OGCLAIM, ABI_OGCLAIM)
  const userContract = useContract(CONTRACT_ADDRESS, ABI)
  const addTransaction = useTransactionAdder()

  useMount(() => { loadNum() })
  useUpdateEffect(() => { loadNum() }, [userContract, userContractOGClaim, account])
  const loadNum = () => {
    state.num = 0
    state.ids = []
    state.hasIds = false
    state.hasIdsLoading = !!account
    state.remintLoading = false
    state.bulldogDataSource = []

    if (userContract && userContractOGClaim && account) {
      userContract.balanceOf(account).then(resp => {
        if (resp.toNumber()) {
          state.num = resp.toNumber()
        }
      })

      var ids = []
      fetchMyBulldog()
      function fetchMyBulldog(cursor = '') {
        let url = `${pageConfig().OPEN_SEA_API}/assets?`
        let queryString = qs.stringify({
          asset_contract_address: CONTRACT_ADDRESS,
          owner: account.toLowerCase(),
          limit: 50,
          cursor,
        })

        fetch(url + queryString, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-API-KEY': pageConfig().OPEN_SEA_API_KEY,
          },
        })
          .then(resp => resp.json())
          .then(resp => {
            resp.assets?.forEach(item => {
              ids.push(+item.token_id)
              state.bulldogDataSource.push(item)
            })
            if (resp.next) {
              fetchMyBulldog(resp.next)
            } else {
              (async () => {
                for (let i = 0; i < ids.length; i++) await userContractOGClaim.claimRecord(ids[i]).then(bool => !bool && state.ids.push(ids[i]))
                state.num = state.ids.length
                state.hasIds = !!state.ids.length
                state.hasIdsLoading = false
              })()
            }
          })
          .catch(() => {
            //
          })
      }
    }
  }

  const handleHasRemintModal = () => {
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
      return
    } else {
      state.hasRemintModal = true
      return
    }
  }

  const handleRemint = () => {
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
      return
    }
    if (!state.hasIds) {
      return
    }
    if (state.remintLoading) {
      return
    }

    state.remintLoading = true
    getChainGasprice().then(async gasPrice => {
      const gasLimit = await userContractOGClaim.estimateGas
        .claims(state.ids)
        .catch(() => userContractOGClaim.estimateGas.claims(state.ids))
      userContractOGClaim.claims(state.ids, { ...gasPrice, gasLimit }).then(claimCallback).catch(claimRejectCallback)
    })

    async function claimCallback(tx) {
      addTransaction(tx.hash)
      let result = await tx.wait()
      if (result) {
        state.remintLoading = false
        if (result.logs.length === 0) return alertify.error('Failed transaction.')
        alertify.success(window.t({ zh: '重鑄成功', en: 'Remint Success' }))
        state.hasIds = false
        state.hasPreviewModal = true
        fetch(`/dog/saveRecord`, {
          method: 'POST',
          body: JSON.stringify({
            nftIds: state.ids,
          }),
        })
      }
    }

    async function claimRejectCallback() {
      state.remintLoading = false
      alertify.error(window.t({ zh: '用戶拒絕了請求', en: 'User rejected the request', ja: 'リクエストがユーザに拒否されました' }))
    }
  }

  const handleOpen = () => {
    alertify.success(window.t({ zh: '即將開放', en: 'Coming Soon', ja: '近日リリース予定' }))
  }

  return (
    <div className="remint">
      <Header onUpdate={() => state.now++} />
      {state.hasRemintModal && <RemintModal {...state} onClose={() => state.hasRemintModal = state.hasPreviewModal = false} onRemint={() => handleRemint()} />}
      {state.hasPreviewModal && <PreviewModal {...state} onClose={() => state.hasRemintModal = state.hasPreviewModal = false} />}
      <div className="main">
        <div className="remint-bg1">
          <div className="common-container">
            <div className="container">
              <div className="row mint-item1">
                <div>
                  <img src={require('./images/1.png').default} alt="" />
                  <img src={require('./images/1.1.png').default} alt="" />
                  <div>{window.t({ zh: '為什麽要Remint?', en: 'Why I need Remint?', ja: '何故レミントが必要？', pt: 'Porque eu preciso Remint?' })}</div>
                  <div>
                    <img src={require('./images/5.png').default} alt="" />
                    <img src={require('./images/5.png').default} alt="" />
                    <img src={require('./images/5.png').default} alt="" />
                  </div>
                </div>
                <div>
                  <div>{window.t({ zh: 'Bulldog OG輪 重铸', en: 'Bulldog OG-Round Remint', ja: 'Bulldog OGラウンド レミント', pt: 'Bulldog Rodada de OG Remint'  })}</div>
                  <div>
                    <div>
                      <img src={require('./images/2.png').default} alt="" />
                      <div style={{ fontSize: 16 }}>
                        <div>Bulldog OG</div>
                        <div>{window.t({ zh: 'OG輪紀念', en: 'OG-Round Commemorate', ja: 'OGラウンド記念', pt: 'Comemorar da Rodada de OG' })}</div>
                      </div>
                    </div>
                    <div>
                      <img src={require('./images/3.png').default} alt="" />
                      <div style={{ fontSize: 16 }}>
                        <div>BulldogTalkClub</div>
                        <div>{window.t({ zh: 'BulldogTalkClub治理權益證明', en: 'Credential Proof of Bulldog Talk Club Governance Right', ja: 'ブルドッグトーククラブのガバナンス権益証明', pt: 'Prova de credencial do direito de governança no BulldogTalkClub ' })}</div>
                      </div>
                    </div>
                    <div>
                      <img src={require('./images/4.png').default} alt="" />
                      <div style={{ fontSize: 16 }}>
                        <div>MysteryBulldogHair</div>
                        <div>{window.t({ zh: 'Remint獎勵', en: 'Remint rewards.', ja: 'レミントリワード', pt: 'Recompensas de Remint' })}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {(() => {
                      if (state.hasIdsLoading) {
                        return (
                          <button style={{ opacity: .5 }}>Loading...</button>
                        )
                      }
                      if (state.remintLoading) {
                        return (
                          <button style={{ opacity: .5 }}>Pending...</button>
                        )
                      }
                      if (!state.hasIds && state.bulldogDataSource.length >= 1) {
                        return (
                          <button style={{ opacity: .5 }}>{window.t({ en: 'Reminted', ja: 'レミント済み' })}</button>
                        )
                      }
                      if (!state.hasIds && state.bulldogDataSource.length == 0) {
                        return (
                          <button style={{ opacity: .5 }}>{window.t({ en: 'Remint Now!', ja: '早速、レミントしよう！', pt: 'Remint Agora!' })}</button>
                        )
                      }
                      if (state.hasIds) {
                        return (
                          <button style={{ cursor: 'pointer' }} onClick={handleHasRemintModal}>{window.t({ en: 'Remint Now!', ja: '早速、レミントしよう！', pt: 'Remint Agora!' })}</button>
                        )
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="remint-bg2">
          <div className="common-container">
            <div className="container">
              <div className="row mint-item2">
                <div className='c-question'>
                  <div className='title'>
                    {window.t({ zh: '常見問題', en: 'FAQ', ja: 'FAQ', pt: 'FAQ' })}
                  </div>
                  <div className="ques-item">
                    <div><img src={QuestionSvg} alt="" className='q-img' /></div>
                    <div className='content'>
                      <div className='q-title'>
                        {window.t({
                          zh: '為什麼需要重鑄Bulldog OG？',
                          en: 'Why should I remint Bulldog OG?',
                          ja: '何故ブルドッグOGをレミントしないといけませんか？',
                          pt: 'Por que eu deveria remint o Bulldog OG?'
                        })}
                      </div>
                      <div className='q-info'>
                        {window.t({
                          zh: 'Bulldog DAO是為“人人有猴 ”而共建的去中心化自治社區，這一崇高目標不是一蹴而就的，而要分步驟的完成，囙此Bulldog將分為多輪進行公開發售，每一輪次也將各具主題和特色。 同時，為了保證全部的BULLDOG之間收益權和治理權的平等，需要在同一個BULLDOG智慧合約裏，支持不同的公開發售輪次。',
                          en: '"Bulldog DAO" is a decentralized autonomous community set up to practice "BAYC holding by everyone ". This noble goal is not something that can be achieved overnight, but is something that will be realized step by step.At the same time, in order to equalize the revenue and governance rights according to all BULLDOGs, it is necessary to support all Bulldogs to be launched in different rounds, furthermore, based on one smart contract.',
                          ja: '"Bulldog DAO"は、"BAYC全員保有"を実践するために立ち上げられた分散型自律コミュニティです。この崇高な目標は、一朝一夕に達成できるものではなく、一歩ずつ実現させていく物です。ですから、ブルドッグは数回のラウンドに分かれて、それぞれのテーマと特徴をもって発売される予定です。同時に、すべてのBULLDOGに応じる収益とガバナンスの権利を平等にするために、すべてのブルドッグが一つのスマートコントラクトに基づき、更に、異なるラウンドで発売することをサポートすることが必要です',
                          pt: '"Bulldog DAO" é uma comunidade autônoma descentralizada criada para a prática "BAYC para todos". Este nobre objetivo não é algo que pode ser alcançado rápido, mas é algo que será realizado passo a passo.Ao mesmo tempo, a fim de equalizar a receita e os direitos de governança de acordo com todos os titulares de Bulldog, é necessário apoiar todos os Bulldogs a serem lançados em diferentes rodadas com base em um "smart contract".'
                        })}<br />
                        {window.t({
                          zh: '而舊版本的Bulldog智慧合約，為了保持安全性和獨立性，無法進行二次陞級。 囙此Bulldog將會發佈新一版可擴展性高的智慧合約，來滿足Bulldog DAO成員們的需求。',
                          en: 'The original Bulldog smart contract was set up in such a way that it could not be upgraded twice in order to maintain security and independence. Therefore, Bulldog plans to release a new version of smart contract with flexibility to meet the needs of Bulldog DAO members. ',
                          ja: '元ブルドッグスマートコントラクトは、セキュリティと独立性を保つために、2度のバージョンアップができないようにセットされました。そのため、ブルドッグは、Bulldog DAOメンバーのニーズに応えるため、柔軟性が備えた新たなスマートコントラクトをリリースする予定です。',
                          pt: 'O "smart contract" original da Bulldog foi estabelecido de tal forma que não poderia ser atualizado duas vezes a fim de manter a segurança e a independência. Portanto, a Bulldog comunidade planeja lançar uma nova versão do "smart contract" com flexibilidade para atender às necessidades dos membros da Bulldog DAO. '
                        })}<br />
                        {window.t({
                          zh: '那麼對於參與了Bulldog OG輪的用戶來說，就需要通過參與重鑄的管道，將原有的Bulldog OG重鑄為新Bulldog（NFT ID及頭像圖片均和OG輪保持一致）。',
                          en: 'Those who participated in the Bulldog OG-Round will need to remint amd change their original Bulldog OG.(NFT ID and image will remain the same as the original).',
                          ja: 'ブルドッグOGラウンドに参加した方は、オリジナルのBulldog OGをチェンジするためのレミントに参加する必要があります。(NFT ID及び画像はオリジナルと同じままです）。',
                          pt: 'Aqueles que participaram do Bulldog Rodada-OG terão de remint e mudar seu Bulldog OG original. (NFT ID e imagem permanecerão os mesmos que o original).'
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="ques-item">
                    <div><img src={QuestionSvg} alt="" className='q-img' /></div>
                    <div className='content'>
                      <div className='q-title'>
                        {window.t({
                          zh: '重鑄有什麼花費嗎？',
                          en: 'Is there a cost for the remint?',
                          ja: 'レミントに費用はかかりますか？ ',
                          pt: 'Há algum custo para remint?'
                        })}
                      </div>
                      <div className='q-info'>
                        {window.t({
                          zh: '本次重鑄是Free-remint的管道，用戶只需要將持有的Bulldog OG，approve給本次Free-remint智慧合約即可。 即可等待接收到重鑄後的Bulldog。',
                          en: 'This remint is free. Users only need to authorize their Bulldog OG to the smart contract and they are good to go. You can then wait to receive your reminted bulldog. ',
                          ja: '今回のレミントはフリーです。ユーザーはご保有のブルドッグOGをスマートコントラクトに授権すれば、オッケーです。その後、レミントされたブルドッグの受け取りを待つことができます。',
                          pt: 'Para remint é grátis. Os usuários só precisam autorizar seu Bulldog OG para o "smart contract" e depois só esperar para receber seu Bulldog após remint. '
                        })}
                        <br />
                        {window.t({
                          zh: '另外，為了彌補用戶在這筆交易裏極微小的Gas Fee，重鑄成功後，用戶將按照重鑄Bulldog的數量，獲得對應根數的神秘狗毛作為補償獎勵。',
                          en: 'Otherwise, for cover the gas costs incurred in this transaction, the user will receive a reasonable amount of Mystery Bulldog Hair after the reminting. ',
                          ja: 'それ以外、この取引で発生したガス費用を補うため、ユーザーはレミントした後に、それなりのブルドッグ犬の毛を受け取ることができます',
                          pt: 'Além disso, para cobrir os custos de gás (gas fee) incorridos nesta transação, o usuário receberá uma quantidade razoável de Mystery Bulldog Hair após remint. '
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="ques-item">
                    <div><img src={QuestionSvg} alt="" className='q-img' /></div>
                    <div className='content'>
                      <div className='q-title'>
                        {window.t({ zh: '重鑄後我的Bulldog會有什麼變化？', en: 'What happens to the Bulldog after reminting?', ja: 'レミントした後、ブルドッグはどのようになるのでしょうか？', pt: 'O que acontece com o Bulldog após remint?' })}
                      </div>
                      <div className='q-info'>
                        {window.t({
                          zh: '重鑄前，用戶持有基於舊版本Bulldog智慧合約的 OG NFT。 重鑄後，用戶將持有基於新版本Bulldog智慧合約的 NFT，並且原Bulldog OG NFT將自動加蓋“OG”紀念印章，作為您參與了Bulldog OG發售的紀念。',
                          en: 'Before reminting, the bulldog is an OG NFT based on the original smart contract, and after reminting it becomes an NFT based on the new smart contract.Furthermore, Bulldog OG NFTs will automatically be stamped with an OG sticker to commemorate their participation.',
                          ja: 'レミント前は元スマートコントラクトに基づくOG NFTであり、レミントした後は新なスマートコントに基づくNFTとなります。更に、ブルドックOG NFTには、参加記念として「OG」記念シールが自動的に押されます。',
                          pt: 'Antes de remint, o Bulldog é um OG NFT baseado no "smart contract" original, e se torna um NFT baseado no novo "smart contract" após remint.Além disso, os Bulldog OG NFTs serão automaticamente carimbados com um adesivo OG para comemorar a sua participação.'
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}