// @ts-nocheck
import './index.scss';
import Header from 'components/HeadComp';
import Footer from 'components/Footer';
import BigNumber from 'bignumber.js'
import { TextField, Button, Typography, Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableCellProps, Input, Skeleton, Pagination, useMediaQuery, useTheme, CircularProgress } from '@mui/material'
import { useEffect, useState, useMemo, useRef } from "react";
import { useReactive, useMount, useUpdateEffect, useInterval, useLocalStorageState } from 'ahooks'
import axios, * as others from 'axios';
import { useWeb3React } from '@web3-react/core';
import RefundingABI from 'abi/refundingclaim.json'
import pageConfig from 'pages/page.config';
import { useContract } from 'hooks/useContract';
import { useTransactionAdder } from 'state/transactions/hooks'
import genGasOption, { getChainGasprice } from 'utils/genGasOption'
import Web3 from 'web3'
import { toBuffer, ecsign, keccak256, bufferToHex, addHexPrefix, keccakFromString, keccakFromHexString, toRpcSig } from 'ethereumjs-util'
import CustomTooltip from './components/CustomTooltip';
import HintTipIcon from './images/HintTipIcon.png'
import Tooltip from '@mui/material/Tooltip';
import textCenterEllipsis from './components/text-center-ellipsis'
import RefundingModal from './components/RefundingModal';
import { SignatureLike } from '@ethersproject/bytes'
import styled from 'styled-components/macro'
import { ExternalLink } from '../../theme'


const DEFAULT_PAGESIZE = 10

const getTotal = (length: number) => {
  return Math.ceil(length / DEFAULT_PAGESIZE)
}

const CONTRACT_ADDRESS_REFUNDING_CLAIM = pageConfig()?.REFUNDING_CLAIM_CONTRACT_ADDRESS.toLowerCase()
const DATA_PROVIDER = pageConfig()?.WEB3_DATA_PROVIDER_RPC_URL

const TableHeadCell: React.FC<TableCellProps> = ({ children, ...otherProps }) => {
  return (
    <TableCell
      sx={{
        color: '#B1B5C3',
        borderBottom: 'none',
      }}
      {...otherProps}
    >
      {children}
    </TableCell>
  )
}

const TableBodyCell: React.FC<TableCellProps> = ({ children, ...otherProps }) => {
  return (
    <TableCell
      sx={{
        color: '#141414',
        fontWeight: 500,
        borderBottom: 'none',
      }}
      {...otherProps}
    >
      {children}
    </TableCell>
  )
}

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.text8};
  margin-left: -0.3rem;
  font-size: 1.6rem;
  display: flex;
  align-items: left;
  text-decoration: none;
`

export interface BulldogClaimData {
  account: string
  holds: string
  amount: string
  claimed: boolean
}



export interface CommunitySignData {
  account: string
  sign: string
  holds: string
  amount: string
  claimed: boolean
}

function getEthereum() {
  const defed = (window as any)?.defed
  const ethereum = (window as any)?.ethereum != defed ? (window as any)?.ethereum : void 0
  if (localStorage['defed-connect']) return defed
  return ethereum
}

export default () => {
  const state = useReactive({
    now: 1,
    tab: 1,
    claimable: '0.0000',
    claimAddress: '',
    title: 'Claim',
    holds: '0',
  })
  const { account, chainId } = useWeb3React()
  const [disclosureData, setDisclosureData] = useState<BulldogClaimData[]>([])
  const [userData, setUserData] = useState<CommunitySignData>()
  const [loadingUser, setLoadingUser] = useState(false)
  const [loadingDisclosure, setLoadingDisclosure] = useState(false)
  const payData = useReactive({
    isRead: true,
    isAgree: false,
  })
  const [editClaimTo, setEditClaimTo] = useState('')
  const userImmediateClaim = useContract(CONTRACT_ADDRESS_REFUNDING_CLAIM, RefundingABI)
  const addTransaction = useTransactionAdder()
  const canClaim = useRef(true)
  const web3 = new Web3(DATA_PROVIDER)
  const [batchLoading, setBatchLoading] = useState(false)
  const [batchClaimStatus, setBatchClaimStatus] = useState(false)
  const [openClaimModal, setOpenClaimModal] = useState(false)
  const [page, setPage] = useState(1)
  //immediate claim loading
  const [immediateLoading, setImmediateLoading] = useState(false)
  //batch can claim status
  const batchCanClaim = useRef(true)

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('lg'));

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const ethers = require("ethers")
  const ethereum = getEthereum()
  let provider = new ethers.providers.JsonRpcProvider(DATA_PROVIDER)


  const handleClaim = async () => {
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
      return
    }
    //TODO 0812之前ban掉claim
    alertify.error(window.t({ zh: 'Claim will be started on August 12, 2023, 8:00 UTC.', en: 'Claim will be started on August 12, 2023, 8:00 UTC.' }))
    return
    //TODO 0812之后放开用户可以claim
    if (userData?.claimed || !userData?.account) { //用户已经claim过
      return
    }
    const verifyClaimTo = web3.utils.isAddress(state.claimAddress)
    if (verifyClaimTo) {
      setOpenClaimModal(true)
    } else {
      alertify.error(window.t({ zh: 'Claim to address is not valid', en: 'Claim to address is not valid' }))
      return
    }
  }

  function holdsArrSort(data) {
    var resultArray = data.sort(
      function compareFunction(p1, p2) {
       return p1.localeCompare(p2, undefined, { numeric: true })
      }
    )
    return resultArray
  }

  function holdsSort(data, field) {
    var holdsArr = []
    for (let index = 0; index < data.length; index++) {
      holdsArr.push(data[index]['holds'])
    }
    var array = holdsArrSort(holdsArr)
    console.log('array', array)
    return array
  }

  function claimDataSort(holdsArr, data) {
    var holdsDataArr = []
    for (var ob in holdsArr) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element['holds'] === holdsArr[ob]) {
          holdsDataArr.push(element)
        }
      }
    }
    for (let i = 0; i < holdsDataArr.length; i++) {
      for (let j = i+1; j < holdsDataArr.length; j++) {
        if (holdsDataArr[i]['account'] == holdsDataArr[j]['account']) {
          holdsDataArr.splice(j, 1);
          j--;
        }
      }
    }
    return holdsDataArr
  }

  const loadBatchClaimStatus = async () => {
    const config = {
      method: "post",
      url: 'https://bulldog.club/claim/getApprove',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
      data: {
        account: account,
      },
    }
    setBatchClaimStatus(true)
    axios
      .request(config)
      .then((response) => {
        setBatchClaimStatus(false)
        console.log('loadBatchClaimStatus+response', response)
        console.log('loadBatchClaimStatus数据', response.data)
        //0 不合法的用户，1 已经claimed, 2 已经approve , 3  没有approve
        switch (response.data) {
          case 0:
            canClaim.current = false
            batchCanClaim.current = false
            state.title = 'Claim'
            break;
          case 1:
            canClaim.current = false
            batchCanClaim.current = false
            state.title = 'Claimed'
            break;
          case 2:
            canClaim.current = false
            batchCanClaim.current = false
            state.title = 'Pending'
            break;
          case 3:
            canClaim.current = true
            batchCanClaim.current = true
            state.title = 'Claim'
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        setBatchClaimStatus(false)
        console.log('loadBatchClaimStatus数据+error', error.response, error.response?.data)
      });
  }

  const loadAllClaimLists = async () => {
    const config = {
      method: "post",
      url: 'https://bulldog.club/claim/list',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
    };
    setLoadingDisclosure(true)
    axios
      .request(config)
      .then((response) => {
        setLoadingDisclosure(false)
        var list = response.data
        console.log('loadAllClaimLists+list.length', response.data, response.data?.length)
        var originArr = holdsSort(list)
        var destArr = claimDataSort(originArr, list)
        console.log('claimDataSort', destArr)
        var reverseArr = destArr.reverse()
        setDisclosureData(reverseArr)
      })
      .catch((error) => {
        setLoadingDisclosure(false)
        console.log('loadAllClaimLists+error', error.response, error.response?.data)
      });
  }

  const loadCurrentUserClaimedSign = async () => {
    const config = {
      method: "post",
      url: 'https://bulldog.club/claim/getSign',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
      data: {
        account: account,
      },
    };
    setLoadingUser(true)
    axios
      .request(config)
      .then((response) => {
        setLoadingUser(false)
        console.log('loadCurrentUserClaimedSign+response.data', response.data)
        if (response.data === '') {
          canClaim.current = false
          setUserData(response.data)
          setEditClaimTo(response.data.account)
          state.claimAddress = response.data.account
          state.claimable = new BigNumber(response.data.amount).div(1e18).toFixed(4)
          state.claimAddress = '...'
          state.holds = '0'
          state.claimable = '0.0000'
          return
        } else {
          setUserData(response.data)
          setEditClaimTo(response.data.account)
          state.claimAddress = response.data.account
          state.claimable = new BigNumber(response.data.amount).div(1e18).toFixed(4)
          console.log('loadCurrentUserClaimedSign+amount', response.data.amount)
          if (response.data.claimed) {
            canClaim.current = false
          } else {
            canClaim.current = true
          }
          console.log('loadCurrentUserClaimedSign', response)
        }
      })
      .catch((error) => {
        setLoadingUser(false)
        console.log('loadCurrentUserClaimedSign+error', error.response, error.response?.data)
      });
  }

  const formatListBySymbol = useMemo(() => {
    if (!disclosureData) return
    return disclosureData
  }, [disclosureData])

  useEffect(() => {
    setPage(1)
    loadAllClaimLists()
    if (account) {
      //PROD TODO
      loadCurrentUserClaimedSign()
      loadBatchClaimStatus()
    }
  }, [account])

  const onChangeClaimTo = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEditClaimTo(e.target.value)
    state.claimAddress = e.target.value
    console.log('state.NewclaimAddress', state.claimAddress)
  };

  const handleClaimImmediate = async () => {
    setOpenClaimModal(false)
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
      return
    }
    if (userData?.claimed || !userData?.account) { //用户已经claim过
      return
    }
    if (payData.isRead === true && payData.isAgree === false) {
      console.log('handleClaimImmediate', state.claimAddress, userData.amount, userData.sign)
      setImmediateLoading(true)
      getChainGasprice().then(async gasPrice => {
        const gasLimit = await userImmediateClaim.estimateGas
          .claim1(state.claimAddress, userData.amount, userData.sign)
          .catch(() => userImmediateClaim.estimateGas.claim1(state.claimAddress, userData.amount, userData.sign))
        userImmediateClaim.claim1(state.claimAddress, userData.amount, userData.sign, { ...gasPrice, gasLimit }).then(claimCallback).catch(claimRejectCallback)
      })

      async function claimCallback(tx) {
        addTransaction(tx.hash)
        let result = await tx.wait()
        if (result) {
          setImmediateLoading(false)
          if (result.logs.length === 0) return alertify.error('Failed transaction.')
          alertify.success(window.t({ zh: 'Claim成功', en: 'Claim Success' }))
          canClaim.current = false
          state.title = 'Claimed'
        }
      }
      async function claimRejectCallback() {
        console.log('Immediate claim fail')
        setImmediateLoading(false)
        canClaim.current = true
        state.title = 'Claim'
        alertify.error(window.t({ zh: '用戶拒絕了請求', en: 'User rejected the request', ja: 'リクエストがユーザに拒否されました' }))
      }
    } else {
      const encodeABI = web3.eth.abi.encodeParameters(["address"], [CONTRACT_ADDRESS_REFUNDING_CLAIM]); //CONTRACT_ADDRESS_REFUNDING_CLAIM
      console.log("encodeABI "+encodeABI)
      const encodeClaimto = web3.eth.abi.encodeParameters(["address"], [state.claimAddress])
      console.log("encodeClaimto "+encodeClaimto)
      const newencodeClaimto = encodeClaimto.replace('0x', '')
      console.log("newencodeClaimto "+newencodeClaimto)
      const newUnsigned = encodeABI+newencodeClaimto
      console.log("newUnsigned "+newUnsigned)
      setBatchLoading(true)
      try {
        const msg = `0x${Buffer.from(encodeABI+newencodeClaimto, 'utf8').toString('hex')}`;
        console.log('unsigned+msg', msg)
        const signature = await ethereum.request({
          method: 'personal_sign',
          params: [msg, account, state.claimAddress],
        });
        console.log('account+signature', account)
        console.log('signature', signature)
        if (signature) {
          const config = {
            method: "post",
            url: 'https://bulldog.club/claim/approveBatch',
            headers: {
              'Content-Type': 'application/json',
              'Accept': '*/*',
            },
            data: {
              sign: signature,
              account: account,
              to: state.claimAddress,
            },
          };
          axios
            .request(config)
            .then((response) => {
              setBatchLoading(false)
              console.log('loadingBatchApprove数据', response, response.data)
              if (response.data === 5) {
                alertify.success(window.t({ zh: 'Batch Claim成功', en: 'Batch Claim Success' }))
                state.title = 'Pending'
                batchCanClaim.current = false
                canClaim.current = false
              } else {
                if (response.data === 1) {
                  alertify.error(window.t({ zh: 'Batch Claim失败', en: 'Batch Claim has claimed' }))
                }
                if (response.data === 2) {
                  alertify.error(window.t({ zh: 'Batch Claim失败', en: 'Batch Claim approved' }))
                }
                if (response.data === 3) {
                  canClaim.current = true
                  alertify.error(window.t({ zh: 'Batch Claim失败', en: 'Batch Claim not approve' }))
                }
                if (response.data === 4) {
                  alertify.error(window.t({ zh: 'Batch Claim失败', en: 'Batch Claim Sign error' }))
                  state.title = 'Claim'
                  batchCanClaim.current = true
                  canClaim.current = true
                }
              }
            })
            .catch((error) => {
              setBatchLoading(false)
            });
        }
      } catch (error) {
        setBatchLoading(false)
        alertify.error(window.t({ zh: 'User denied message signature.', en: 'User denied message signature.' }))
      }
    }
  }

  if (isMobile) {
    return (
      <div className="claim">
        <Header onUpdate={() => state.now++} />
        {openClaimModal && <RefundingModal {...state} onClose={() => setOpenClaimModal(false)} onRemint={() => handleClaimImmediate()} />}
        <div className="main">
          <div className="common-container">
            <div className="container">
              <div className="profile">
              </div>
              <div className="claimcontent">
                <Box sx={{ display: 'flex',
                  flexDirection: 'column', 
                  width: '100%',
                  backgroundColor: '#2A2D32', 
                  // height: '1700px',
                  height: 'fit-content', 
                  p: '40px 40px', 
                  borderRadius: '16px',
                }}>
                  <Box>
                    <Typography sx={{ fontSize: '24px', color: '#FFFFFF' }}>{'Refunding Claim'}</Typography>
                  </Box>
                  <Box sx={{display: 'flex', flexDirection: 'column', mt: '40px', width: '100%' }}>
                    <Box sx={{display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ display: 'flex', alignItems: 'left', mt: 2 }}>
                        <Box sx={{ mx: 1 }}>ADDRESS</Box>
                      </Box>
                      {account ? 
                        (loadingUser ? 
                          <Skeleton variant="rectangular" width={'100%'} height={'50px'} sx={{ mt: '10px' }} />
                          :
                          <Box sx={{
                            p: '15px 15px',
                            border: '1.5px solid #E6EBF0',
                            borderRadius: '12px',
                            display: 'flex',
                            fontSize: '14px',
                            width: '100%',
                            height: '56px',
                            mt: '10px',
                            color: '#B1B5C3'
                          }}>
                            {textCenterEllipsis(userData?.account || '', 6, 4)}
                          </Box>
                        )
                      :
                        <Box sx={{
                          p: '15px 15px',
                          border: '1.5px solid #E6EBF0',
                          borderRadius: '12px',
                          display: 'flex',
                          fontSize: '14px',
                          width: '100%',
                          height: '56px',
                          mt: '10px',
                          color: '#B1B5C3'
                        }}>
                          {'Please login first...'}
                        </Box>
                      }
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                      <Box sx={{ display: 'flex', alignItems: 'left', mt: 2 }}>
                        <Box sx={{ mx: 1 }}>CLAIM TO</Box>
                        <Tooltip placement="top"
                          title="The address shown here will be the address to which the funds will be sent by Claim. It is defaulted to the wallet address you are connected to, but you can change it to any other address."
                        >
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              cursor: 'pointer',
                              mt: '-2px',
                              ml: '5px',
                            }}
                            component={'img'}
                            src={'/images/hinttipicon.png'}
                          />
                        </Tooltip>
                      </Box>
                      {account ?
                        (loadingUser ?
                          <Skeleton variant="rectangular" width={'100%'} height={'50px'} sx={{ mt: '10px' }} />
                        :
                          <Input
                            fullWidth
                            disableUnderline={true}
                            value={textCenterEllipsis(editClaimTo || '', 6, 4)}
                            onChange={onChangeClaimTo}
                            sx={{
                              mt: '10px',
                              borderRadius: '12px',
                              fontSize: '14px',
                              p: '4px 12px',
                              border: '1.5px solid #E6EBF0',
                              width: '100%',
                              height: '56px',
                              color: '#FFFFFF'
                            }}
                          />
                        )
                      :
                        <Box sx={{
                          p: '15px 15px',
                          border: '1.5px solid #E6EBF0',
                          borderRadius: '12px',
                          display: 'flex',
                          fontSize: '14px',
                          width: '100%',
                          height: '56px',
                          mt: '10px',
                          color: '#B1B5C3'
                        }}>
                          {'Please login first...'}
                        </Box>
                      }
                    </Box>
                  </Box>
                  <Box sx={{
                    mt: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#191B1F', 
                    height: '134px', 
                    p: '24px 32px', 
                    borderRadius: '16px',
                  }}>
                    <Box sx={{ display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                      <Box sx={{ display: 'flex',
                        flexDirection: 'row',
                      }}>
                        <Typography sx={{ 
                          fontSize: '18px', 
                          color: '#FFFFFF',
                        }}>
                          {'Holdings'}
                        </Typography>
                        <Tooltip placement="top"
                          title="For the same TokenID, such as 0001, if both BulldogOG and Bulldog exist, then the claim right corresponding to BulldogOG will be invalidated. Only the claim corresponding to Bulldog is valid."
                        >
                          <Box
                            sx={{
                              width: '24px',
                              height: '24px',
                              cursor: 'pointer',
                              mt: '2px',
                              ml: '10px',
                            }}
                            component={'img'}
                            src={'/images/hinttipicon.png'}
                          />
                        </Tooltip>
                      </Box>
                      {account ?
                        (loadingUser ?
                          <Skeleton variant="rectangular" width={'100%'} height={'50px'} sx={{ mt: '-13px' }} />
                        :
                          <Typography sx={{ 
                            fontSize: '18px', 
                            color: '#FFFFFF'
                          }}>
                            {userData?.holds || state.holds}
                          </Typography>
                        )
                      :
                        <Typography sx={{ 
                          fontSize: '14px', 
                          color: '#FFFFFF'
                        }}>
                          {'Please login first...'}
                        </Typography>
                      }
                    </Box>
                    <Box sx={{ display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      mt: '24px',
                    }}>
                      <Typography sx={{ 
                        fontSize: '18px', 
                        color: '#FFFFFF'
                      }}>
                        {'Claimable'}
                      </Typography>
                      {account ?
                      (loadingUser ?
                        <Skeleton variant="rectangular" width={'100%'} height={'50px'} sx={{ mt: '-13px' }} />
                      :
                        <Typography sx={{ 
                          fontSize: '18px', 
                          color: '#FFFFFF'
                        }}>
                          {`${state.claimable} ETH`}
                        </Typography>
                      )
                      :
                        <Typography sx={{ 
                          fontSize: '14px', 
                          color: '#FFFFFF'
                        }}>
                          {'Please login first...'}
                        </Typography>
                      }
                    </Box>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: '40px',
                  }}>
                    <Typography sx={{ fontSize: '24px', color: '#FFFFFF' }}>{'Method'}</Typography>
                    <Tooltip placement="top"
                      title="The options here relate to the speed and cost of the Claim. If you choose 'Immediate', you will pay Gas and initiate the Claim transaction immediately, wait for the block confirmation, and the funds will be sent to the account immediately. If you choose 'Batch', no Gas is required, and after your signature is confirmed, BulldogDAO will periodically package the transactions, and then send the funds to the accounts."
                    >
                      <Box
                        sx={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                          mt: '5px',
                          ml: '10px',
                        }}
                        component={'img'}
                        src={'/images/hinttipicon.png'}
                      />
                    </Tooltip>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: '40px',
                    justifyContent: 'space-between',
                  }}>
                    <Typography sx={{ fontSize: '18px', color: '#FFFFFF', mt: '6px' }}>{'Immediate'}</Typography>
                    <Checkbox
                      checked={payData.isRead}
                      onChange={(e) => {
                        payData.isRead = e.target.checked
                        payData.isAgree = false
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        color: '#E6E9EC',
                        '&.Mui-checked': {
                          color: '#50FFF5',
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: '20px',
                    justifyContent: 'space-between',
                  }}>
                    <Typography sx={{ fontSize: '18px', color: '#FFFFFF', mt: '6px' }}>{'Batch'}</Typography>
                    <Checkbox
                      checked={payData.isAgree}
                      onChange={(e) => {
                        payData.isAgree = e.target.checked
                        payData.isRead = false
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        color: '#E6E9EC',
                        '&.Mui-checked': {
                          color: '#50FFF5',
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                    <Button 
                      onClick={handleClaim}
                      sx={{ 
                        display: 'flex',
                        mt: 4, 
                        height: '56px', 
                        width: '150px',
                        color: '#000000',
                        bgcolor: '#50FFF5',
                        border: '1px solid #50FFF5',
                        borderRadius: '90px',
                      }}
                      disabled={immediateLoading || batchLoading || !canClaim.current || !batchCanClaim.current}
                    >
                      {state.title || 'Claim'}
                      {(immediateLoading || batchLoading) && <CircularProgress color="inherit" size="16px" sx={{ ml: 2 }} />}
                    </Button>
                    {state.title === 'Pending' &&
                    <Box sx={{ display: 'flex', alignItems: 'left', mt: '45px', ml: '20px' }}>
                      <Typography 
                        sx={{
                          fontSize: '18px',
                          textDecoration: 'none',
                          color: '#FFFFFF',
                        }}
                      >
                        {'Batched Claims will be processed in 24 hours after request submitted.'}
                      </Typography>
                    </Box>
                    }
                  </Box>
                  <Box sx={{display: 'flex', flexDirection: 'column', mt: '45px'}}>
                      <AddressLink
                        href={'https://etherscan.io/block/17574706'}
                      >
                        <span style={{ marginLeft: '4px' }}>
                          <span style={{ color: '#00C8FA', fontWeight: 'normal' }}>{window.t({ zh: '在Etherscan查看', en: 'Disclosure', ja: 'イーサスキャンで確認' })}</span>
                        </span>
                      </AddressLink>
                      <TableContainer component={Box} sx={{mt: '40px'}}>
                        <Table>
                          <TableHead
                            sx={{
                              color: '#78828C',
                              fontSize: '14px',
                              fontWeight: 400,
                              border: '1px solid #E6E9EC',
                              borderLeft: 'none',
                              borderRight: 'none',
                              height: '72px',
                            }}
                          >
                            <TableRow>
                              <TableHeadCell >{'Ranking'}</TableHeadCell>
                              <TableHeadCell align="left">{'Holdings'}</TableHeadCell>
                              <TableHeadCell align="center">{'Claimable'}</TableHeadCell>
                              <TableHeadCell align="center">{'Address'}</TableHeadCell>
                            </TableRow>
                          </TableHead>
                            <TableBody>
                              {formatListBySymbol
                                .slice((page - 1) * DEFAULT_PAGESIZE, page * DEFAULT_PAGESIZE)
                                .map((row, index) => (
                                  <TableRow
                                    sx={{
                                      height: '72px',
                                      ['&:hover']: {
                                        backgroundColor: '#78828C',
                                      },
                                    }}
                                    key={index}
                                  >
                                    <TableBodyCell align="left">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            marginRight: '8px',
                                            color: '#FFFFFF'
                                          }}
                                        >
                                          {(page - 1) * DEFAULT_PAGESIZE + index+1}
                                        </Box>
                                      </Box>
                                    </TableBodyCell>
                                    <TableBodyCell align="center">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            marginRight: '8px',
                                            color: '#FFFFFF'
                                          }}
                                        >
                                          {row.holds}
                                        </Box>
                                      </Box>
                                    </TableBodyCell>
                                    <TableBodyCell align="center">
                                      <Box
                                        sx={{
                                          marginRight: '8px',
                                          color: '#FFFFFF'
                                        }}
                                      >
                                        {new BigNumber(row.amount).div(1e18).toFixed(4)}
                                        {' ETH'}
                                      </Box>
                                    </TableBodyCell>
                                    <TableBodyCell align="center">
                                      <Box
                                        sx={{
                                          marginRight: '8px',
                                          color: '#FFFFFF'
                                        }}
                                      >
                                        {textCenterEllipsis(row.account || '', 6, 4)}
                                      </Box>
                                    </TableBodyCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                      </TableContainer>
                      <Box
                        sx={{
                          pt: 3,
                          pb: 3,
                          position: { xs: 'unset', lg: 'relative' },
                          bottom: 0,
                          right: 30,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Pagination count={getTotal(formatListBySymbol.length)} 
                          variant="outlined" 
                          color="secondary" 
                          shape="rounded" 
                          page={page} 
                          onChange={handleChange} 
                        />
                      </Box>
                    </Box>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <div className="claim">
      <Header onUpdate={() => state.now++} />
      {openClaimModal && <RefundingModal {...state} onClose={() => setOpenClaimModal(false)} onRemint={() => handleClaimImmediate()} />}
      <div className="main">
        <div className="common-container">
          <div className="container">
            <div className="profile">
            </div>
            <div className="claimcontent">
              <Box sx={{ display: 'flex',
                flexDirection: 'column', 
                width: '100%',
                backgroundColor: '#2A2D32', 
                height: '1700px', 
                p: '40px 40px', 
                borderRadius: '16px'
              }}>
                <Box>
                  <Typography sx={{ fontSize: '24px', color: '#FFFFFF' }}>{'Refunding Claim'}</Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', mt: '40px', }}>
                  <Box sx={{display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', mt: 2 }}>
                      <Box sx={{ mx: 1 }}>ADDRESS</Box>
                    </Box>
                    {account ? 
                      (loadingUser ? 
                        <Skeleton variant="rectangular" width={'400px'} height={'50px'} sx={{ mt: '10px' }} />
                        :
                        <Box sx={{
                          p: '15px 15px',
                          border: '1.5px solid #E6EBF0',
                          borderRadius: '12px',
                          display: 'flex',
                          fontSize: '14px',
                          width: '494px',
                          height: '56px',
                          mt: '10px',
                          color: '#B1B5C3'
                        }}>
                          {textCenterEllipsis(userData?.account || '', 6, 4)}
                        </Box>
                      )
                    :
                      <Box sx={{
                        p: '15px 15px',
                        border: '1.5px solid #E6EBF0',
                        borderRadius: '12px',
                        display: 'flex',
                        fontSize: '14px',
                        width: '494px',
                        height: '56px',
                        mt: '10px',
                        color: '#B1B5C3'
                      }}>
                        {'Please login first...'}
                      </Box>
                    }
                  </Box>
                  <Box sx={{ width: '40px'}}></Box>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{ display: 'flex', alignItems: 'left', mt: 2 }}>
                      <Box sx={{ mx: 1 }}>CLAIM TO</Box>
                      <Tooltip placement="top"
                        title="The address shown here will be the address to which the funds will be sent by Claim. It is defaulted to the wallet address you are connected to, but you can change it to any other address."
                      >
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            cursor: 'pointer',
                            mt: '-2px',
                            ml: '5px',
                          }}
                          component={'img'}
                          src={'/images/hinttipicon.png'}
                        />
                      </Tooltip>
                    </Box>
                    {account ?
                      (loadingUser ?
                        <Skeleton variant="rectangular" width={'400px'} height={'50px'} sx={{ mt: '10px' }} />
                      :
                        <Input
                          fullWidth
                          disableUnderline={true}
                          value={textCenterEllipsis(editClaimTo || '', 6, 4)}
                          onChange={onChangeClaimTo}
                          sx={{
                            mt: '10px',
                            borderRadius: '12px',
                            fontSize: '14px',
                            p: '4px 12px',
                            border: '1.5px solid #E6EBF0',
                            width: '494px',
                            height: '56px',
                            color: '#FFFFFF'
                          }}
                        />
                      )
                    :
                      <Box sx={{
                        p: '15px 15px',
                        border: '1.5px solid #E6EBF0',
                        borderRadius: '12px',
                        display: 'flex',
                        fontSize: '14px',
                        width: '494px',
                        height: '56px',
                        mt: '10px',
                        color: '#B1B5C3'
                      }}>
                        {'Please login first...'}
                      </Box>
                    }
                  </Box>
                </Box>
                <Box sx={{
                  mt: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#191B1F', 
                  height: '134px', 
                  p: '24px 32px', 
                  borderRadius: '16px',
                }}>
                  <Box sx={{ display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                    <Box sx={{ display: 'flex',
                      flexDirection: 'row',
                    }}>
                      <Typography sx={{ 
                        fontSize: '18px', 
                        color: '#FFFFFF',
                        // mr: '10px',
                      }}>
                        {'Holdings'}
                      </Typography>
                      <Tooltip placement="top"
                        title="For the same TokenID, such as 0001, if both BulldogOG and Bulldog exist, then the claim right corresponding to BulldogOG will be invalidated. Only the claim corresponding to Bulldog is valid."
                      >
                        <Box
                          sx={{
                            width: '24px',
                            height: '24px',
                            cursor: 'pointer',
                            mt: '2px',
                            ml: '10px',
                          }}
                          component={'img'}
                          src={'/images/hinttipicon.png'}
                        />
                      </Tooltip>
                    </Box>
                    {account ?
                      (loadingUser ?
                        <Skeleton variant="rectangular" width={'400px'} height={'50px'} sx={{ mt: '-13px' }} />
                      :
                        <Typography sx={{ 
                          fontSize: '18px', 
                          color: '#FFFFFF'
                        }}>
                          {userData?.holds || state.holds}
                        </Typography>
                      )
                    :
                      <Typography sx={{ 
                        fontSize: '14px', 
                        color: '#FFFFFF'
                      }}>
                        {'Please login first...'}
                      </Typography>
                    }
                  </Box>
                  <Box sx={{ display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mt: '24px',
                  }}>
                    <Typography sx={{ 
                      fontSize: '18px', 
                      color: '#FFFFFF'
                    }}>
                      {'Claimable'}
                    </Typography>
                    {account ?
                    (loadingUser ?
                      <Skeleton variant="rectangular" width={'400px'} height={'50px'} sx={{ mt: '-13px' }} />
                    :
                      <Typography sx={{ 
                        fontSize: '18px', 
                        color: '#FFFFFF'
                      }}>
                        {`${state.claimable} ETH`}
                      </Typography>
                    )
                    :
                      <Typography sx={{ 
                        fontSize: '14px', 
                        color: '#FFFFFF'
                      }}>
                        {'Please login first...'}
                      </Typography>
                    }
                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '40px',
                }}>
                  <Typography sx={{ fontSize: '24px', color: '#FFFFFF' }}>{'Method'}</Typography>
                  <Tooltip placement="top"
                    title="The options here relate to the speed and cost of the Claim. If you choose 'Immediate', you will pay Gas and initiate the Claim transaction immediately, wait for the block confirmation, and the funds will be sent to the account immediately. If you choose 'Batch', no Gas is required, and after your signature is confirmed, BulldogDAO will periodically package the transactions, and then send the funds to the accounts."
                  >
                    <Box
                      sx={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                        mt: '5px',
                        ml: '10px',
                      }}
                      component={'img'}
                      src={'/images/hinttipicon.png'}
                    />
                  </Tooltip>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '40px',
                  justifyContent: 'space-between',
                }}>
                  <Typography sx={{ fontSize: '18px', color: '#FFFFFF', mt: '6px' }}>{'Immediate'}</Typography>
                  <Checkbox
                    checked={payData.isRead}
                    onChange={(e) => {
                      payData.isRead = e.target.checked
                      payData.isAgree = false
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      color: '#E6E9EC',
                      '&.Mui-checked': {
                        color: '#50FFF5',
                      },
                    }}
                  />
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '20px',
                  justifyContent: 'space-between',
                }}>
                  <Typography sx={{ fontSize: '18px', color: '#FFFFFF', mt: '6px' }}>{'Batch'}</Typography>
                  <Checkbox
                    checked={payData.isAgree}
                    onChange={(e) => {
                      payData.isAgree = e.target.checked
                      payData.isRead = false
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      color: '#E6E9EC',
                      '&.Mui-checked': {
                        color: '#50FFF5',
                      },
                    }}
                  />
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                  <Button 
                    onClick={handleClaim}
                    sx={{ 
                      display: 'flex',
                      mt: 4, 
                      height: '56px', 
                      width: '150px',
                      color: '#000000',
                      bgcolor: '#50FFF5',
                      border: '1px solid #50FFF5',
                      borderRadius: '90px',
                    }}
                    disabled={immediateLoading || batchLoading || !canClaim.current || !batchCanClaim.current}
                  >
                    {state.title || 'Claim'}
                    {(immediateLoading || batchLoading) && <CircularProgress color="inherit" size="16px" sx={{ ml: 2 }} />}
                  </Button>
                  {state.title === 'Pending' &&
                  <Box sx={{ display: 'flex', alignItems: 'left', mt: '45px', ml: '20px' }}>
                    <Typography 
                      sx={{
                        fontSize: '18px',
                        textDecoration: 'none',
                        color: '#FFFFFF',
                      }}
                    >
                      {'Batched Claims will be processed in 24 hours after request submitted.'}
                    </Typography>
                  </Box>
                  }
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', mt: '45px'}}>
                    <AddressLink
                      href={'https://etherscan.io/block/17574706'}
                    >
                      <span style={{ marginLeft: '4px' }}>
                        <span style={{ color: '#00C8FA', fontWeight: 'normal' }}>{window.t({ zh: '在Etherscan查看', en: 'Disclosure', ja: 'イーサスキャンで確認' })}</span>
                      </span>
                    </AddressLink>
                    <TableContainer component={Box} sx={{mt: '40px'}}>
                      <Table>
                        <TableHead
                          sx={{
                            color: '#78828C',
                            fontSize: '14px',
                            fontWeight: 400,
                            border: '1px solid #E6E9EC',
                            borderLeft: 'none',
                            borderRight: 'none',
                            height: '72px',
                          }}
                        >
                          <TableRow>
                            <TableHeadCell >{'Ranking'}</TableHeadCell>
                            <TableHeadCell align="left">{'Holdings'}</TableHeadCell>
                            <TableHeadCell align="center">{'Claimable'}</TableHeadCell>
                            <TableHeadCell align="center">{'Address'}</TableHeadCell>
                          </TableRow>
                        </TableHead>
                          <TableBody>
                            {formatListBySymbol
                              .slice((page - 1) * DEFAULT_PAGESIZE, page * DEFAULT_PAGESIZE)
                              .map((row, index) => (
                                <TableRow
                                  sx={{
                                    height: '72px',
                                    ['&:hover']: {
                                      backgroundColor: '#78828C',
                                    },
                                  }}
                                  key={index}
                                >
                                  <TableBodyCell align="left">
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          marginRight: '8px',
                                          color: '#FFFFFF'
                                        }}
                                      >
                                        {(page - 1) * DEFAULT_PAGESIZE + index+1}
                                      </Box>
                                    </Box>
                                  </TableBodyCell>
                                  <TableBodyCell align="center">
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          marginRight: '8px',
                                          color: '#FFFFFF'
                                        }}
                                      >
                                        {row.holds}
                                      </Box>
                                    </Box>
                                  </TableBodyCell>
                                  <TableBodyCell align="center">
                                    <Box
                                      sx={{
                                        marginRight: '8px',
                                        color: '#FFFFFF'
                                      }}
                                    >
                                      {new BigNumber(row.amount).div(1e18).toFixed(4)}
                                      {' ETH'}
                                    </Box>
                                  </TableBodyCell>
                                  <TableBodyCell align="center">
                                    <Box
                                      sx={{
                                        marginRight: '8px',
                                        color: '#FFFFFF'
                                      }}
                                    >
                                      {textCenterEllipsis(row.account || '', 6, 4)}
                                    </Box>
                                  </TableBodyCell>
                                </TableRow>
                              ))}
                          </TableBody>
                          
                      </Table>
                    </TableContainer>
                    <Box
                      sx={{
                        pt: 3,
                        pb: { xs: 10, lg: 1 },
                        position: { xs: 'unset', lg: 'relative' },
                        bottom: 0,
                        right: 30,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Pagination count={getTotal(formatListBySymbol.length)} variant="outlined" color="secondary" shape="rounded" page={page} onChange={handleChange} />
                    </Box>
                  </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
