// @ts-nocheck
import './index.scss'
import pageConfig from 'pages/page.config'

export default (props) => {
  const handleOpenEtherscan = (address) => {
    if (pageConfig()?.__NAME === 'prod') {
      window.open('https://etherscan.io/address/' + address)
    } else {
      window.open('https://rinkeby.etherscan.io/address/' + address)
    }
  }

  return (
    <div className="remintModal">
      <div className="remint-container">
        <div className="close" onClick={props.onClose}></div>
        <div className="title">Your items</div>
        <div className="image image2">
          <div className="r">
            <div>
              {props.num > 0 && <span>{props.num}x</span>}
              <img width="80" src={require('../images/12.png').default} alt="" />
              <div onClick={() => window.open('https://opensea.io/collection/bulldog-og')}>
                <div>Bulldog OG</div>
                <div>View on Opensea →</div>
              </div>
            </div>
            <div>
              {props.num > 0 && <span>{props.num}x</span>}
              <img width="80" src={require('../images/13.png').default} alt="" />
              <div onClick={() => window.open('https://opensea.io/collection/bulldogtalkclub')}>
                <div>BulldogTalkClub</div>
                <div>View on Opensea →</div>
              </div>
            </div>
            <div>
              {props.num > 0 && <span>{props.num}x</span>}
              <img width="80" src={require('../images/14.png').default} alt="" />
              <div onClick={() => window.open('https://opensea.io/collection/mystery-bulldog-hair')}>
                <div>MysteryBulldogHair</div>
                <div>View on Opensea →</div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          {window.t({
            zh: '恭喜您！您可以繼續參加BulldogTalkClub的治理活動了。',
            en: <>
                  Congratulations! You can continue to participate in <br />
                  Bulldog Talk Club govermance activities!
                </>,
            ja: 'おめでとうございます。今後とも、引き続きBulldogTalkClubのガバナンス活動に参加できるようになりました。',
            pt: 'Parabéns! Você pode continuar participando das atividades de governança do BulldogTalkClub!'
          })}
        </div>
      </div>
    </div>
  )
}