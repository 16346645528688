// @ts-nocheck
import './index.scss'
import textCenterEllipsis from './text-center-ellipsis'

export default (props) => {
  return (
    <div className="refundingModal">
      <div className="refunding-container">
        <div className="close" onClick={props.onClose}></div>
        <div className="title">{window.t({ zh: 'Confirm', en: 'Confirm', ja: 'Confirm', pt: 'Confirm' })}</div>
        <div className="texts">
          <div className="r">
            <div>{window.t({ zh: `You will receive ${props.claimable} ETH at ${textCenterEllipsis(props.claimAddress || '', 6, 4)}, If there are no problems, click the Next button to continue.`, en: `You will receive ${props.claimable} ETH at ${textCenterEllipsis(props.claimAddress || '', 6, 4)}, If there are no problems, click the Next button to continue.`, ja: `You will receive ${props.claimable} ETH at ${textCenterEllipsis(props.claimAddress || '', 6, 4)}, If there are no problems, click the Next button to continue.`, pt: `You will receive ${props.claimable} ETH at ${textCenterEllipsis(props.claimAddress || '', 6, 4)}, If there are no problems, click the Next button to continue.` })}</div>
          </div>
        </div>
        <div className="texts" style={{whiteSpace: 'pre-wrap'}}>
          <div className="r">
            <div>{window.t({ zh: "Please note: \nI. If you choose Batch method, the Refunding will not be processed immediately.\n2. Each Bulldog TokenID can only be claimed once.", en: "Please note: \nI. If you choose Batch method, the Refunding will not be processed immediately.\n2. Each Bulldog TokenID can only be claimed once.", ja: "Please note: \nI. If you choose Batch method, the Refunding will not be processed immediately.\n2. Each Bulldog TokenID can only be claimed once.", pt: "Please note: \nI. If you choose Batch method, the Refunding will not be processed immediately.\n2. Each Bulldog TokenID can only be claimed once." })}</div>
          </div>
        </div>
        <div className="butto">
          {(() => {
            if (props.claimable && props.claimAddress) {
              return (
                <button style={{ cursor: 'pointer' }} onClick={props.onRemint}>{window.t({ en: 'NEXT', ja: 'NEXT' })}</button>
              )
            }
          })()}
        </div>
      </div>
    </div>
  )
}