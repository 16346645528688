// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import TransactionUpdater from './state/transactions/updater'
import ApplicationUpdater from './state/application/updater'
import store from './state'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { NetworkContextName } from 'constants/index'
import getLibrary from 'utils/getLibrary'
import '@reach/dialog/styles.css';
import dayjs from 'dayjs'
import pageConfig from 'pages/page.config';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)
if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

console.log(pageConfig())
const _cache = pageConfig().CONTRACT_ADDRESS
if (localStorage._cache != _cache) {
  Object.keys(localStorage).forEach(key => key != '_lang' && delete localStorage[key])
  localStorage._cacheTime = dayjs().format('MM-DD HH:mm')
  localStorage._cache = _cache
}

if (!localStorage._console) {
  // window.console.log = () => {}
}

if (!localStorage._lang) {
  localStorage._lang = 'en'
  // if (window.navigator?.language?.includes('zh')) localStorage._lang = 'zh'
  // if (window.navigator?.language?.includes('ja')) localStorage._lang = 'ja'
  // if (window.navigator?.language?.includes('pt')) localStorage._lang = 'pt'
  // if (window.navigator?.language?.includes('en')) localStorage._lang = 'en'
}

window.t = ({zh, ja, pt, en}) => {
  if (localStorage._lang === 'zh') return zh || en;
  if (localStorage._lang === 'ja') return ja || en;
  if (localStorage._lang === 'pt') return pt || en;
  if (localStorage._lang === 'en') return en || en;
  return en
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <TransactionUpdater />
          <ApplicationUpdater />
          <App />
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
