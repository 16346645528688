// @ts-nocheck
import { ethers } from 'ethers';
import Web3 from 'web3'

export default (n = 1) => {
  const web3 = new Web3(window.ethereum)
  return new Promise(resolve => {
    web3.eth.estimateGas({}).then(gas => {
      web3.eth.getGasPrice().then(gasPrice => {
        resolve({
          gasPrice: ethers.utils.parseUnits(web3.utils.fromWei(gasPrice, 'gwei'), 'gwei'),
          gasLimit: parseInt(n * gas * web3.utils.fromWei(gasPrice, 'gwei'), 10),
        })
      })
    })
  })
}

export function getChainGasprice() {
  const web3 = new Web3(window.ethereum)
  return new Promise(resolve => {
    web3.eth.getGasPrice().then(gasPrice => {
      resolve({
        gasPrice: ethers.utils.parseUnits(web3.utils.fromWei(gasPrice, 'gwei'), 'gwei')
      })
    })
  })
}

export async function getGasPrice() {
  const web3 = new Web3(window.ethereum)
  const gasPrice = await web3.eth.getGasPrice()
  return gasPrice
}