export default {
    swiper1: {
        direction: 'horizontal', // 垂直切换选项
        // loop: true, // 循环模式选项
        effect: 'coverflow',
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 0,
        observer:true,
        observeParents:true,
        slidesOffsetBefore:5,
        // autoplay:true,
        // autoplay: {
        //   delay: 5000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // 如果需要分页器
        pagination: {
            el: '.top-img-swiper-pagination',
        },

        // 如果需要前进后退按钮
        navigation: {
            nextEl: '.top-img-swiper-container .swiper-button-next',
            prevEl: '.top-img-swiper-container .swiper-button-prev',
        },

        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
        on: {
            slideChangeTransitionStart: function(){
                console.log(this)
                console.log('当前展示卡片=='+this.activeIndex);
                console.log('当前展示卡片真实索引=='+this.realIndex);
            }
            
        },
    },

    swiper2: {
        direction: 'horizontal', // 垂直切换选项
        // loop: true, // 循环模式选项
        // effect: 'coverflow',
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 0,
        observer:true,
        observeParents:true,
        // autoplay:false,
        // autoplay: {
        //   delay: 5000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
        // 如果需要分页器
        pagination: {
          el: '.my-storage-swiper-pagination',
        },
    
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.my-storage-swiper-container .swiper-button-next',
          prevEl: '.my-storage-swiper-container .swiper-button-prev',
        },
    
        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
    }
}