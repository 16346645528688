// @ts-nocheck
import React from 'react'
import { default as useCountDown } from '../home/useCountDown'
import { n2s, png, getPubS3Url } from '../home/utils'
const TARGET_DATE = '2022-06-22 12:00:00'

export default React.memo((props) => {
  const [countdown, setTargetDate, { days: d, hours: h, minutes: m, seconds: s }] = useCountDown({ targetDate: props.targetDate || TARGET_DATE })
  const n2sss = [null, n2s(d, 1), n2s(d, 2), n2s(h, 1), n2s(h, 2), n2s(m, 1), n2s(m, 2), n2s(s, 1), n2s(s, 2)]
  return props?.children?.(n2sss)
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
})