// @ts-nocheck
import styles from './index.module.scss';
import { fnm } from '../../utils'
import { useReactive, useMount } from 'ahooks'
import React from 'react'

export default React.memo(props => {
  const state = useReactive({
    bulldogInfo: props.bulldogInfo,
    total: props.bulldogDataSource.length,
    index: props.bulldogDataSource.findIndex(item => item.nftId === props.bulldogInfo?.nftId),
  })

  const handleLBulldogInfo = () => {
    if (state.index <= 0) return
    state.index--
    state.bulldogInfo = props.bulldogDataSource[state.index]
    document.querySelector('#_img_').src = '/images/888.png'
    var img = new Image()
    img.src = state.bulldogInfo?.image
    img.onload = () => document.querySelector('#_img_').src = img.src
  }

  const handleRBulldogInfo = () => {
    if (state.index >= state.total - 1) return
    state.index++
    state.bulldogInfo = props.bulldogDataSource[state.index]
    document.querySelector('#_img_').src = '/images/888.png'
    var img = new Image()
    img.src = state.bulldogInfo?.image
    img.onload = () => document.querySelector('#_img_').src = img.src
  }

  useMount(() => {
    document.querySelector('#_img_').src = '/images/888.png'
    var img = new Image()
    img.src = state.bulldogInfo?.image
    img.onload = () => document.querySelector('#_img_').src = img.src
  })

  return (
    <div className={styles.component}>
      <div>
        <img id="_img_" alt="" />
        <span>
          {props.bulldogInfo?.bulldogDataSourceTab === 'OG' ? 'Bulldog OG' : 'Bulldog'}
        </span>
        <span onClick={props.onClose}>
          <img src="/images/close.png" alt="" />
        </span>
      </div>
      <div>
        <span>#{fnm(state.bulldogInfo?.nftId)}</span>
        <span onClick={() => window.open(state.bulldogInfo?.openSeaLink)}>View on Opensea →</span>
      </div>
      <div>
        <span onClick={handleLBulldogInfo} style={{ opacity: state.index <= 0 ? 0 : 1 }}>
          <img src={require('../../images/2.png').default} alt="" />
        </span>
        <span onClick={handleRBulldogInfo} style={{ opacity: state.index >= state.total - 1 ? 0 : 1 }}>
          <img src={require('../../images/2.png').default} alt="" />
        </span>
      </div>
    </div>
  )
}, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
})