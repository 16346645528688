// @ts-nocheck
import { AbstractConnector } from '@web3-react/abstract-connector'
// import { darken } from 'polished'
import styled from 'styled-components/macro'
// import { injected } from '../../connectors'
// import { SUPPORTED_WALLETS } from '../../constants/wallet'
import Loader from '../Loader'
// import Option from './Option'
import METAMASK_ICON_URL from 'assets/images/metamask.png'
import BITKEEP_ICON_URL from 'assets/images/bitkeep.svg';
const darken = (a, b) => {
  return b || a;
}

const PendingSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    width: 100%;
  }
`

const StyledLoader = styled(Loader)`
  margin-right: 1rem;
`

const LoadingMessage = styled.div<{ error?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap};
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  color: ${({ theme, error }) => (error ? theme.red1 : 'inherit')};
  border: 1px solid ${({ theme, error }) => (error ? theme.red1 : theme.text4)};

  & > * {
    padding: 1rem;
  }
`

const ErrorGroup = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  width: 100%;
  align-items: center;
  justify-content: center;
`

const ErrorButton = styled.div`
  border-radius: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg4};
  margin-left: 1rem;
  padding: 0.5rem;
  font-weight: 600;
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => darken(0.1, theme.text4)};
  }
`

const OptionCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const OptionCardLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '24px')};
    width: ${({ size }) => (size ? size + 'px' : '24px')};
  }
`

export default function PendingView({
  connector,
  error = false,
  setPendingError,
  tryActivation,
}: {
  connector?: AbstractConnector
  error?: boolean
  setPendingError: (error: boolean) => void
  tryActivation: (connector: AbstractConnector) => void
}) {
  // const isMetamask = window?.ethereum?.isMetaMask

  return (
    <PendingSection>
      <LoadingMessage error={error}>
        {error ? (
          <ErrorGroup>
            <div>
              <span>Error connecting</span>
            </div>
            <ErrorButton
              onClick={() => {
                setPendingError(false)
                connector && tryActivation(connector)
              }}
            >
              <span>Try Again</span>
            </ErrorButton>
          </ErrorGroup>
        ) : (
          <>
            <OptionCardLeft>
              <StyledLoader stroke="white" />
              <span>{window.t({ zh: '連接中', en: 'Initializing', ja: '接続中' })}...</span>
            </OptionCardLeft>
            <IconWrapper size={24}>
              <img src={window.isBitKeep ? BITKEEP_ICON_URL : METAMASK_ICON_URL} alt={'Icon'} />
            </IconWrapper>
          </>
        )}
      </LoadingMessage>
      {/* {Object.keys(SUPPORTED_WALLETS).map((key) => {
        const option = SUPPORTED_WALLETS[key]
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== 'MetaMask') {
              return null
            }
            if (!isMetamask && option.name === 'MetaMask') {
              return null
            }
          }
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              clickable={false}
              color={option.color}
              header={option.name}
              icon={option.iconURL}
            />
          )
        }
        return null
      })} */}
    </PendingSection>
  )
}
