// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import dayjsPluginUtc from 'dayjs/plugin/utc';
import dayjsPluginTimezone from 'dayjs/plugin/timezone';
dayjs.extend(dayjsPluginUtc);
dayjs.extend(dayjsPluginTimezone);

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var calcLeft = function calcLeft(t) {
  if (!t) {
    return 0;
  } // https://stackoverflow.com/questions/4310953/invalid-date-in-safari


  var left = dayjs.tz(t, 'Asia/Shanghai').valueOf() - dayjs.tz(dayjs.utc(), 'Asia/Shanghai').valueOf();
  var left = dayjs.utc(t).valueOf() - dayjs.utc().valueOf();

  if (left < 0) {
    return 0;
  }

  return left;
};

var parseMs = function parseMs(milliseconds) {
  return {
    days: Math.floor(milliseconds / 86400000),
    hours: Math.floor(milliseconds / 3600000) % 24,
    minutes: Math.floor(milliseconds / 60000) % 60,
    seconds: Math.floor(milliseconds / 1000) % 60,
    milliseconds: Math.floor(milliseconds) % 1000
  };
};

var useCountdown = function useCountdown(options) {
  var _a = options || {},
      targetDate = _a.targetDate,
      _b = _a.interval,
      interval = _b === void 0 ? 1000 : _b,
      onEnd = _a.onEnd;

  var _c = __read(useState(targetDate), 2),
      target = _c[0],
      setTargetDate = _c[1];

  var _d = __read(useState(function () {
    return calcLeft(target);
  }), 2),
      timeLeft = _d[0],
      setTimeLeft = _d[1];

  var onEndPersistFn = useMemoizedFn(function () {
    if (onEnd) {
      onEnd();
    }
  });
  useEffect(function () {
    if (!target) {
      // for stop
      setTimeLeft(0);
      return;
    } // 立即执行一次


    setTimeLeft(calcLeft(target));
    var timer = setInterval(function () {
      var targetLeft = calcLeft(target);
      setTimeLeft(targetLeft);

      if (targetLeft === 0) {
        clearInterval(timer);
        onEndPersistFn();
      }
    }, interval);
    return function () {
      return clearInterval(timer);
    };
  }, [target, interval]);
  var formattedRes = useMemo(function () {
    return parseMs(timeLeft);
  }, [timeLeft]);
  return [timeLeft, setTargetDate, formattedRes];
};

export default useCountdown;
