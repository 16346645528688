// @ts-nocheck
import './index.scss';
import classNames from 'classnames';
import Header from 'components/HeadComp';
import Footer from 'components/Footer';
import { useReactive, useMount, useInterval } from 'ahooks'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import copy from 'copy-to-clipboard'
import { CheckCircle } from 'react-feather'
import LooksSVG from './images/looks.svg'
import ETHSVG from './images/eth.svg'

export default () => {
  const state = useReactive({
    now: 1,
    tab: 1,
    balance: '0.00',
    hasCopyIcon: false,
    looksBalance: '0.00',
  })

  useMount(() => { loadBalance() })
  const loadBalance = () => {
    const address = '0xd57C83f543a7F914ebb89157173a937b3d7B4c32'
    const looksTokenAddress = '0xf4d2888d29D722226FafA5d9B24F9164c092421E'
    const apikey = '9NYWTTYHVSTGASRZPP6H2SG4TBBJI2S6ZV'
    fetch(`https://api.etherscan.io/api?module=account&action=balance&address=${address}&tag=latest&apikey=${apikey}`)
      .then(resp => resp.json())
      .then(resp => {
        if (resp.status == 1) {
          state.balance = new BigNumber(resp.result).div(1e18).toFixed(4)
        }
      })
    fetch(`https://api.etherscan.io/api?module=account&action=tokenbalance&contractaddress=${looksTokenAddress}&address=${address}&tag=latest&apikey=${apikey}`)
      .then(resp => resp.json())
      .then(resp => {
        if (resp.status == 1) {
          state.looksBalance = new BigNumber(resp.result).div(1e18).toFixed(4)
        }
      })
  }

  const handleOpenEtherscan = () => {
    window.open('https://etherscan.io/address/0xd57C83f543a7F914ebb89157173a937b3d7B4c32')
  }

  const handleOpenSea = (nftId) => {
    //because opensea changes, fix daovault assets address, update asset contract to include chain_id
    window.open('https://opensea.io/assets/ethereum/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/' + nftId)
  }

  const handleCopy = () => {
    state.hasCopyIcon = true
    setTimeout(() => state.hasCopyIcon = false, 500)
    copy('0xd57C83f543a7F914ebb89157173a937b3d7B4c32')
  }

  return (
    <div className="daovault">
      <Header onUpdate={() => state.now++} />
      <div className="main">
        <div className="common-container">
          <div className="container">
            <div className="profile">
              <div>
                <img src={require('./images/Opensealogo.png').default} alt="" />
              </div>
              <div>
                {window.t({ zh: 'Bulldog DAO 金庫', en: 'Bulldog DAO Treasury', ja: 'Bulldog DAO ボルート', pt: 'Cofre de Bulldog DAO' })}
              </div>
              <div>
                <span><img src={require('./images/1.png').default} alt="" />{dayjs(dayjs.utc()).diff(dayjs(dayjs.utc('2022-05-10 00:00:00')), 'days')} days</span>
                <span onClick={() => handleOpenEtherscan()}><img src={require('./images/2.png').default} alt="" /></span>
                <span onClick={() => handleCopy()}>{state.hasCopyIcon ? <CheckCircle /> : <img src={require('./images/3.png').default} alt="" />}</span>
              </div>
            </div>
            <div className="daotabs">
              <div>
                <span className={classNames({ 'daotabActive': state.tab === 1 })} onClick={() => state.tab = 1}>{window.t({ zh: '資產清單', en: 'The list of assets', ja: 'アセットリスト', pt: 'Lista de bens' })}</span>
                <span className={classNames({ 'daotabActive': state.tab === 2 })} onClick={() => alertify.success(window.t({ zh: '即將開放', en: 'Coming Soon', ja: '近日リリース予定' }))}>{window.t({ zh: '交易歷史', en: 'Transaction History', ja: '取引履歴', pt: 'Histórico de Transação' })}</span>
              </div>
            </div>
            <div className="daocontent">
              <div className="daocontent-1">
                <div className="l">
                  <div>
                    <div>{window.t({ zh: '資產餘額', en: 'Assets Balance', ja: 'アセット バランス', pt: 'Bens Balanço' })}</div>
                    <div>
                      <div>{state.balance}</div>
                      <div className='c-text'><img src={ETHSVG} alt="" /> ETH</div>
                    </div>
                    <div>
                      <div>{state.looksBalance}</div>
                      <div className='c-text'><img src={LooksSVG} alt="" /> LOOKS</div>
                    </div>
                  </div>
                </div>
                <div className="r">
                  <div>
                    <div>Bored Ape Yacht Club</div>
                    <div onClick={() => handleOpenSea('6091')}>
                      <img src={require('./images/001.png').default} alt="" />
                      <div>Bored Ape Yacht Club</div>
                      <div>
                        <span>#6091</span>
                        <span>
                          <img src={require('./images/5.png').default} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>Bored Ape Yacht Club</div>
                    <div onClick={() => handleOpenSea('5460')}>
                      <img src={require('./images/002.png').default} alt="" />
                      <div>Bored Ape Yacht Club</div>
                      <div>
                        <span>#5460</span>
                        <span>
                          <img src={require('./images/5.png').default} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>Otherside Koda</div>
                    <div onClick={() => window.open('https://opensea.io/assets/ethereum/0xe012baf811cf9c05c408e879c399960d1f305903/1804')}>
                      <img src={require('./images/003.png').default} alt="" />
                      <div>Otherside Koda</div>
                      <div>
                        <span>#1804</span>
                        <span>
                          <img src={require('./images/5.png').default} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>Otherdeed Expanded</div>
                    <div onClick={() => window.open('https://opensea.io/assets/ethereum/0x790b2cf29ed4f310bf7641f013c65d4560d28371/69959')}>
                      <img src={require('./images/004.png').default} alt="" />
                      <div>Otherdeed Expanded</div>
                      <div>
                        <span>#69959</span>
                        <span>
                          <img src={require('./images/5.png').default} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>Otherside Vessels</div>
                    <div onClick={() => window.open('https://opensea.io/assets/ethereum/0x5b1085136a811e55b2bb2ca1ea456ba82126a376/69959')}>
                      <img src={require('./images/005.png').default} alt="" />
                      <div>Otherside Vessels</div>
                      <div>
                        <span>#69959</span>
                        <span>
                          <img src={require('./images/5.png').default} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <div>STEPNNFT V2</div>
                    <div onClick={() => window.open('https://opensea.io/assets/ethereum/0x2a036569dbbe7730d69ed664b74412e49f43c2c0/92085683250')}>
                      <img src={require('./images/003.png').default} alt="" />
                      <div>STEPN Shoebox</div>
                      <div>
                        <span>#42530</span>
                        <span>
                          <img src={require('./images/5.png').default} alt="" />
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}