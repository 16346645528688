// @ts-nocheck
import pageConfig from 'pages/page.config'
import { InjectedConnector } from '@web3-react/injected-connector';
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from '../constants/chains';
// import getLibrary from '../utils/getLibrary'
import { NetworkConnector } from './NetworkConnector';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';

const INFURA_KEY = pageConfig().REACT_APP_INFURA_KEY;
const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
const DEFAULT_CHAINID = pageConfig().REACT_APP_DEFAULT_CHAINID;

// console.log('Infurakey:', INFURA_KEY);

if (typeof INFURA_KEY === 'undefined') {
  throw new Error(
    `REACT_APP_INFURA_KEY must be a defined environment variable`,
  );
}

const NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://eth-mainnet.alchemyapi.io/v2/${INFURA_KEY}`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.POLYGON]: `https://polygon-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
};

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: Number(DEFAULT_CHAINID),
});

// let networkLibrary: Web3Provider | undefined
// export function getNetworkLibrary(): Web3Provider {
//   return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
// }

// export const injected = new InjectedConnector({
//   supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
// })

export const injected = new InjectedConnector({
  supportedChainIds: [Number(DEFAULT_CHAINID)],
});

export const gnosisSafe = new SafeAppConnector();
