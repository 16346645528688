// @ts-nocheck
import pageConfig from 'pages/page.config'
import Web3 from 'web3'
import { useEffect, useState } from 'react'
import { ChainName, chainIds, SupportedChainId } from 'constants/chains'

const INFURA_KEY = pageConfig().REACT_APP_INFURA_KEY
const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY

export const useWalletBalance = (account: string | undefined | null, chainId: number | undefined) => {
  const [balance, setBalance] = useState('')

  useEffect(() => {
    async function updateBalance() {
      if (!account) return
      if (!chainId) return
      const provider = chainId === 4
        ? `https://${ChainName[chainId as chainIds]}.infura.io/v3/${INFURA_KEY}`
        // : `https://eth-mainnet.alchemyapi.io/v2/${INFURA_KEY}`
        : chainId === 80001
        ? 'https://polygon-mumbai.g.alchemy.com/v2/6czW0isxdQbkmToPkLASezIbPnU_CY6T'
        : `https://eth-mainnet.alchemyapi.io/v2/${INFURA_KEY}`
      const web3 = new Web3(provider)
      const mainBalance = await web3.eth.getBalance(account)
      setBalance(mainBalance)
    }

    updateBalance()
    const id = setInterval(updateBalance, 50 * 1000)
    return () => {
      clearInterval(id)
    }
  }, [account, chainId])
  return balance
}