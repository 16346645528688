// @ts-nocheck
import './index.scss';
import Header from 'components/HeadComp';
import Footer from 'components/Footer';
import dogHair from './images/dogFair.gif'
import GoldSvg from './images/1.png'
import GiftSvg from './images/gift.svg'
import { useReactive } from 'ahooks'
import { useContract } from 'hooks/useContract';
import dogHairABI from 'abi/dogHair.json'
import pageConfig from 'pages/page.config';
import { getGasPrice } from 'utils/genGasOption';
import { useEffect } from 'react';
import { ArrowRight } from 'react-feather'
import { useWeb3React } from '@web3-react/core';
import Loader from 'components/Loader';
import { useTransactionAdder } from 'state/transactions/hooks'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import ScanSvg from './images/bridge.svg'
import dayjs from 'dayjs'

const CONTRACT_ADDRESS = (pageConfig() as any).DOGHAIR_CONTRACT_ADDRESS.toLowerCase()

export default function Reward() {

  const state = useReactive({
    now: 1,
    amount: 0,
    balance: '0',
    message: '',
    sign: '',
    isLoading: false,
    historys: [],
  })

  const dogHairContract = useContract(CONTRACT_ADDRESS, dogHairABI)
  const { account, chainId } = useWeb3React()
  const addTransaction = useTransactionAdder()

  useEffect(() => {
    loadData()
  }, [account])

  const loadData = () => {
    if (dogHairContract && account) {
      state.historys = []
      // 0x10e030349db258f7322f286cf859ff0c10c35617
      var url
      if (pageConfig()?.__NAME === 'local | test') url = 'https://api.studio.thegraph.com/query/19931/doghair/v0.1.3'
      if (pageConfig()?.__NAME === 'prod') url = 'https://api.studio.thegraph.com/query/19931/doghairmain/v0.0.17'
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query: `{
            claimCountEntity(id: "${account.toLowerCase()}") {
              tx
              time
              count
            }
          }`,
        }),
      })
        .then(resp => resp.json())
        .then(resp => {
          if (resp.data && resp.data.claimCountEntity) {
            const {tx, time, count} = resp.data.claimCountEntity
            const txArr = tx.split('-')
            const timeArr = time.split('-')
            const countArr = count.split('-')
            txArr.forEach((item, index) => {
              state.historys.unshift({
                tx: txArr[index],
                time: timeArr[index],
                count: countArr[index],
              })
            })
          }
        })

      fetch(`/dog/getHairSign?adr=${account.toLowerCase()}`)
        .then(resp => resp.json())
        .then(resp => {
          dogHairContract.balanceOf(account, 0).then(res => {
            state.balance = res.toString()
          })
          if (resp.data) {
            state.message = resp.data.message
            state.sign = resp.data.sign
            const signature = resp.data.message.substring(2)
            const _index = signature.substring(64, 128)
            const idx = parseInt(_index, 16)
            // console.log(idx)
            //check the data is used or not, if not, update amount
            dogHairContract.signatureRecord(idx).then((flag) => {
              // console.log(flag)
              if (!flag) {
                state.amount = resp.data.amounts[0]
              } else {
                state.amount = 0
              }
            })
          }
        })
    }
  }

  const handleMint = async () => {
    if (state.isLoading) return
    if (!account) {
      document.getElementById('connect-wallet')?.click()
      document.getElementById('switch-network')?.click()
      return
    }
    if (Number(state.amount) > 0) {
      state.isLoading = true
      try {
        const gasLimit = await dogHairContract.estimateGas
          .claim(state.message, state.sign)
          .catch(() => dogHairContract.estimateGas.claim(state.message, state.sign))
        const gasPrice = await getGasPrice()
        // console.log(gasLimit, gasPrice)
        dogHairContract.claim(state.message, state.sign, {
          gasLimit,
          gasPrice
        })
          .then(async (res) => {
            addTransaction(res.hash)
            await res.wait()
            dogHairContract.balanceOf(account, 0).then(res => {
              state.isLoading = false
              state.amount = 0
              state.balance = res.toString()
              loadData()
            })
          })
          .catch(() => {
            state.isLoading = false
          })
      } catch (e) {
        console.log(e)
        state.isLoading = false
      }
    } else {
      alertify.error(window.t({ zh: '可領取獎勵為0', en: 'There are no rewards to be received.', ja: '受け取れるリワードがゼロです。', pt: 'Prêmio disponível: 0' }))
    }
  }

  // const handleOpen = () => {
  //   alertify.success(window.t({ zh: '即將開放', en: 'Coming soon', ja: '今後リリース', pt: 'Chegando em breve' }))
  // }

  return (
    <div className="reward">
      <Header onUpdate={() => state.now++} />
      <div className="main">
        <div className="reward-bg1">
          <div className="common-container">
            <div className="container">
              <div className="row item2">
                <div><img src={dogHair} alt="gold" /></div>
                <div className='my-reward'>
                  <div className="rewardTitle">
                    {window.t({ zh: '您可以領取的獎勵', en: 'The Rewards you can claim', ja: '受け取れるリワード', pt: '' })}
                  </div>
                  <div className="action">
                    <div className="action-left">
                      <div>
                        <span>{state.amount}</span>
                        <span>Mystery Bulldog Hair</span>
                      </div>
                      <div>{window.t({ zh: '錢包餘額', en: 'Wallet Balance', ja: 'ウォレット残高', pt: 'Balanço da Carteira' })}: {state.balance}</div>
                      <div>
                        <a href="https://opensea.io/collection/mystery-bulldog-hair" target="_blank">
                          {window.t({ zh: '前往Opensea查看', en: 'Check it on Opensea', ja: 'Openseaで照会', pt: 'Veja em Opensea' })}
                          &nbsp;&nbsp;&nbsp;<ArrowRight color='#50FFF5' />
                        </a>
                      </div>
                    </div>
                    <div className="action-right">
                      <img src={GiftSvg} alt="" />
                      <div className='mint' onClick={handleMint}>
                        {state.isLoading ? (
                          <span>
                            <Loader stroke={'#121212'} />
                            <span style={{ marginLeft: 4 }}>
                              {window.t({ zh: '領取', en: 'Claim', ja: '受け取り', pt: 'Recolher' })}
                            </span>
                          </span>
                        ) : (
                          <span>{window.t({ zh: '領取', en: 'Claim', ja: '受け取り', pt: 'Recolher' })}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='history'>
                    <div>
                      {window.t({ zh: '最近領取記錄', en: 'Claimed History', ja: '受け取り履歴', pt: 'Histórico de Recolher' })}
                    </div>
                    {account && (
                      <a
                        href={`${getExplorerLink(chainId, CONTRACT_ADDRESS, ExplorerDataType.TOKEN)}?a=${account}`}
                        target="_blank"
                        className='bridge'
                      >
                        {window.t({ zh: '查看全部', en: 'Check All', ja: '全部をチェック', pt: 'Ver Tudo' })}
                        <img src={ScanSvg} alt="scan" />
                      </a>
                    )}
                  </div>
                  <div className="recordlist">
                    <div className="recordlist-header">
                      <span>
                        {window.t({ zh: '时间', en: 'Time', ja: '時間', pt: 'Tempo' })} (UTC)
                      </span>
                      <span>
                        {window.t({ zh: '領取數量', en: 'Claimed Numbers', ja: '受け取りの数', pt: 'Número de Coleções' })}
                      </span>
                      <span>
                        {window.t({ zh: '交易記錄', en: 'Transaction Hsitory', ja: '取引履歴', pt: 'Histórico da Transação' })}
                      </span>
                    </div>
                    {!!state.historys.length ?
                      <div className="recordlist-content">
                        {state.historys.map((item, index) => (
                          <div key={index}>
                            <span>{dayjs.utc(item.time * 1000).format('MM/DD HH:mm')}</span>
                            <span>{item.count}</span>
                            <span style={{ cursor: 'pointer', color: '#3498db' }} onClick={() => window.open(getExplorerLink(chainId, item.tx, ExplorerDataType.TRANSACTION))}>{item.tx.slice(0, 10)}...</span>
                          </div>
                        ))}
                      </div>
                      :
                      <div className='open'>
                        {window.t({ zh: '您還沒有領取記錄', en: `You don't have claim history yet.`, ja: '今後リリース', pt: 'Chegando em breve' })}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reward-bg2">
          <div className="common-container">
            <div className="container">
              <div className="row item1">
                <div className="left">
                  <div className='title' style={{color: '#7828C8'}}>Probably… Nothing?</div>
                  <div className="desc" style={{color: '#7828C8'}}>
                    {window.t({ zh: ' 一種基於ERC1155標準的神秘通證獎勵，', en: 'Mestery token rewards based ERC-1155,', ja: 'ERC1155に従い、ミステリーなトークンリワード,', pt: 'Um prêmio de passe misterioso baseado na norma ERC1155,' })}
                    <br />
                    {window.t({ zh: '來自於一隻神秘Bulldog身上掉落的', en: 'From a mysterious Bulldog', ja: '一匹のミステリーなブルドッグからの', pt: 'Caiu de um Bulldog misterioso' })}
                  </div>
                  <div className="title" style={{color: '#C864FA'}}>{window.t({ zh: '神秘狗毛', en: 'Mystery Hair', ja: 'ミステリーな犬の毛', pt: 'Pêlos do cão misteriosos' })}</div>
                  <div className='more' style={{color: '#C864FA'}}>
                    {window.t({ zh: '效用：合* ?饰 * ？换 * ？稀 *  ？美 * ? 或者.......', en: 'Effect: S**? A**? S**? R**? B**? Or more', ja: '利用道：合＊？飾り＊？毛＊？珍しさ＊？美＊？或いは…', pt: 'Função:Co…? De…? Tr…? Re…? Se…? Co…? Ra…? Be…? ou....... ' })}
                  </div>
                  <div className="link">
                    <a href="https://opensea.io/collection/mystery-bulldog-hair" target="_blank">
                      {window.t({ zh: '前往Opensea查看', en: 'Check it on Opensea', ja: 'Openseaで照会', pt: 'Veja em Opensea' })}
                      &nbsp;&nbsp;&nbsp;<ArrowRight color='#50FFF5' />
                    </a>
                  </div>
                </div>
                <div className="right">
                  <img src={GoldSvg} alt="dogHair" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reward-bg3">
          <div className="common-container">
            <div className="container">
              <div className="row item3" style={{ marginTop: 50 }}>
                <div className="item3_title">
                  {window.t({ zh: '獎勵獲取管道', en: 'Channel of Rewards Getting', ja: 'リワードの受け取る方法', pt: 'Acesso a prêmios' })}
                </div>
                <div>
                  <a href="https://discord.com/invite/M5HxBYqwjt" className='a_link' target="_blank">
                    {window.t({ zh: '加入Discord參與更多', en: 'Join in the Discord and participate in more', ja: 'Discord参加でより多いものへ', pt: 'Junte-se à Discord e participe mais' })}
                  </a>
                </div>
                <div className="_card4">
                  <div>
                    <div>
                      <span>1</span>
                      <span>
                        <b style={{ fontSize: 32 }}>
                          {window.t({ zh: '官方互動', en: 'official Zither Interaction', ja: 'オフィシャルツィターインタラクション', pt: 'Interajir no Twitter' })}
                        </b><br />
                        <>
                          {window.t({ zh: '每日抽取10位', en: 'daily 10-bit drawing', ja: '毎日10人抽選', pt: '10 pessoas selecionadas diariamente' })}
                        </><br />
                        {window.t({
                          zh: 'Bulldog官方推特每日轉推互動，並@3人帶#BAYC6091#Bulldog DAO然後去open-a-ticket截圖開票打卡。',
                          en: 'Interact with the Bulldog Official Tweeter, @ 3 friends, then add #BAYC6091 and #Bulldog DAO, then submit in the Discord open-a-ticket channel.',
                          ja: 'ブルドッグオフィシャルツィターとインタラクションをして、3人の友人を＠して、そして、＃BAYC6091及び＃Bulldog DAOを追加してから、Discordのopen-a-ticketチャンネルで提出してください。',
                          pt: 'Retweet e interajir com o Bulldog oficial Twitter e @ 3 amigos com hashtag #BAYC6091 #Bulldog DAO , depois enviar a captura de tela para canal #open-a-ticket'
                        })}
                      </span>
                    </div>
                    <div>
                      <span>2</span>
                      <span>
                        <b style={{ fontSize: 32 }}>
                          {window.t({ zh: '表情包製作', en: 'creation of emojis', ja: 'エモじの作成', pt: 'Criar Meme de Bulldog' })}
                        </b><br />
                        <>
                          {window.t({ zh: '每日不限定抽取得主', en: 'daily drawing, unlimited number', ja: '毎日抽選、数の限定無し', pt: 'sorteios ilimitados por dia' })}
                        </><br />
                        {window.t({
                          zh: '發揮奇思妙想結合Bulldog做出表情包發到創作區。',
                          en: 'Please combine bulldogs to create an emoji back and post it in the creative area.',
                          ja: 'ブルドッグを組み合わせて絵文字バックを制作して、創作エリアに投稿してください。',
                          pt: 'Use sua imaginação para combinar Bulldog e meme, depois os enviar para canal #papo-geral'
                        })}
                      </span>
                    </div>
                    <div>
                      <span>3</span>
                      <span>
                        <b style={{ fontSize: 32 }}>
                          {window.t({ zh: '口號徵集', en: 'call for slogans', ja: 'スローガンの募集', pt: 'Criar slogan' })}
                        </b><br />
                        <>
                          {window.t({ zh: '每日不限定抽取得主', en: 'daily drawing, unlimited number', ja: '毎日抽選、数の限定無し', pt: 'sorteios ilimitados por dia' })}
                        </><br />
                        {window.t({
                          zh: '簡短的創意口號，讓大家記住Bulldog。 中英文均可。',
                          en: 'We are looking for short, easy-to-remember contents for the slogan of Bulldog.',
                          ja: '短く、覚えやすいコンテンツ募集中です。',
                          pt: 'Um slogan curto e criativo para lembrar Bulldog. É melhor usar inglês. Enviar para canal #papo-geral'
                        })}
                      </span>
                    </div>
                    <div>
                      <span>4</span>
                      <span>
                        <b style={{ fontSize: 32 }}>
                          {window.t({ zh: '萌寵大比拼', en: 'pet photo contest', ja: ' ペット写真インスタ映え', pt: 'Compartilhar seu animais de estimação' })}
                        </b><br />
                        <>
                          {window.t({ zh: '每日抽取1位', en: 'one winner drawn daily', ja: '毎日一人抽選', pt: 'sortear um vencedor por dia' })}
                        </><br />
                        {window.t({
                          zh: '推特/DC曬出自己的DOG，附上Bulldog口號，每日評選藝員犬。',
                          en: 'You can participate by posting a photo of your pet on Twitter or Discord and adding Bulldogs slogan. The winner will be chosen by the community.',
                          ja: 'ツィター或いはDiscordでペットの写真を映え、ブルドッグのスローガンも加えて参加できます。コミュニティで優勝者が選出されます。',
                          pt: 'Enviar foto do seu cachorro para #papo-geral'
                        })}
                      </span>
                    </div>
                    <div>
                      <span>5</span>
                      <span>
                        <b style={{ fontSize: 32 }}>
                          {window.t({ zh: '新聞頻道', en: 'News channel', ja: 'ニュースチャンネル', pt: ' Novo canal----#news-channel' })}
                        </b><br />
                        <>news channel</><br />
                        {window.t({
                          zh: ' Discord mod會每天發送行業相關資訊並注明來源，也歡迎社群參與者發送相關收集到的資訊，每日評選最有價值的資訊提供者送出神秘盲盒。',
                          en: 'Discord mods will post daily information related to the Crypto. Community members can also post information. Each day a mystery box will be sent to the most valuable information contributor.',
                          ja: 'DiscordのMODが毎日、界隈関連の情報を出典を明記して発信します。コミュニティ参加者も情報投稿ができます。毎日、最も価値のある情報提供者にミステリーボックスが送られる予定です。',
                          pt: 'O mod enviará diariamente informações sobre o setor com atribuição, e os participantes da comunidade também são bem-vindos para enviar informações relevantes coletadas, e o fornecedor de informações mais valioso será selecionado diariamente para enviar uma caixa cega misteriosa.'
                        })}
                      </span>
                    </div>
                    <div>
                      <span>6</span>
                      <span>
                        <b style={{ fontSize: 32 }}>
                          {window.t({ zh: '合作推廣活動', en: 'PR Reward Events', ja: 'PRリワードイベント', pt: ' Promoção de projeto' })}
                        </b><br />
                        <>
                          {window.t({ zh: '每日不限定抽取得主', en: 'daily drawing, unlimited number', ja: '毎日抽選、数の限定無し', pt: 'sorteios ilimitados por dia' })}
                        </><br />
                        {window.t({
                          zh: '社群參與者可推薦及介紹同業優秀KOL或社區，由項目方前去洽談，達成合作直接給予盲盒獎勵後續活動持續更新。每日會公佈中獎名單。',
                          en: 'Community memebers can recommend and introduce outstanding Kol and communities in the cryptocurrency area to the project management and receive mystery box rewards from the project management if the partnership is achieved. Winners will be announced daily.',
                          ja: 'コミュニティ参加者は、暗号通貨界隈の優れたインフルエンザやコミュニティをプロジェクト運営側に推薦・紹介し、提携に達成した場合には、プロジェクト運営側からミステリーボックスの報酬を受け取ることができます。当選者は毎日発表されます。',
                          pt: 'Os participantes da comunidade podem recomendar KOLs ou comunidades de destaque na mesma indústria ao Mod do projeto, e podem ser recompensados diretamente com uma caixa cega quando a parceria for alcançada. Os vencedores serão anunciados diariamente.'
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}