// @ts-nocheck
import { NetworkContextName } from 'constants/index'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { shortenAddress } from '../../utils'
import { useMemo, useState } from 'react'
import { darken } from 'polished'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { Activity } from 'react-feather'
import { useWalletModalToggle } from '../../state/application/hooks'
import styled, { css } from 'styled-components/macro'
import WalletModal from '../WalletModal'
import { injected } from '../../connectors'
import Identicon from '../Identicon'
import { switchNetwork } from 'hooks/wallet'
import { RowBetween } from '../Row'
import Loader from '../Loader'

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

export function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />
  }
  return null
}

function Web3StatusInner() {
  const { account, connector, error } = useWeb3React()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()
 
  if (account) {
    sessionStorage.metamask_address = account
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>
              <span>{pending?.length} Pending...</span>
            </Text>{' '}
            <Loader stroke="#000" />
          </RowBetween>
        ) : (
          <Text>{shortenAddress(account)}</Text>
        )}
        {!hasPendingTransactions && connector && <StatusIcon connector={connector} />}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError id="switch-network" onClick={async () => await switchNetwork()}>
        {/* <NetworkIcon /> */}
        <Text>{error instanceof UnsupportedChainIdError ? <span>{window.t({ zh: '網路錯誤', en: 'Wrong Network', ja: 'ネット環境エラー' })}</span> : <span>{window.t({ zh: '錯誤', en: 'Error', ja: 'エラー発生' })}</span>}</Text>
      </Web3StatusError>
    )
  } else {
    delete sessionStorage.metamask_address
    return (
      <Web3StatusConnect id="connect-wallet" onClick={toggleWalletModal} faded={!account}>
        <span>{window.t({ zh: '連接錢包', en: 'Connect Wallet', ja: 'ウォレットに接続', pt: 'Conectar Wallet' })}</span>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  // const { active } = useWeb3React()
  // const contextNetwork = useWeb3React(NetworkContextName)
  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  // if (!contextNetwork.active && !active) {
  //   return null
  // }

  return (
    <>
      <Web3StatusInner />
      <WalletModal
        pendingTransactions={pending}
        confirmedTransactions={confirmed}
      />
    </>
  )
}

const Web3StatusGeneric = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0 10px;
  margin-right: -11px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  margin-right: -5px;
`

const Web3StatusConnect = styled.div`
  color: #000;
`

const Web3StatusConnected = styled.div`
  background: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 2px 10px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: -20px;
  margin-right: -20px;
`

const Text = styled.p`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin-right: 10px;
  width: fit-content;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`